// Customizable Area Start
import React from 'react';
import {
    isValidPhoneNumber
  } from 'libphonenumber-js'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleSuccessResponse: (data: any) => void;
  handleGoBackClick: () => void;
}

interface S {
  formError: any;
  currentStep: number;
  firstName: string | null;
  lastName: string | null;
  businessWebsite: string | null;
  zipcode: any | null;
  mobileNumber: any | null;
  userToken: any;
  formGlobalError: any;
  EmailPasswordID: any;
  isPolicyDialogOpen: boolean
}

interface SS {
  id: any;
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  apimailSignUpDetailsCallId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      currentStep: 1,
      formError: {},
      formGlobalError: {},
      firstName: this.firstNameValue,
      lastName: this.lastNameValue,
      zipcode: this.zipcodeValue,
      mobileNumber: this.mobileNumberValue,
      businessWebsite: this.businessWebsiteValue,
      EmailPasswordID: 0,
      userToken: this.userTokenValue,
      isPolicyDialogOpen: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  firstNameValue = sessionStorage.getItem("valuesBackStep")
    ? sessionStorage.getItem("firstName")
    : "";
  lastNameValue = sessionStorage.getItem("valuesBackStep")
    ? sessionStorage.getItem("lastName")
    : "";
  zipcodeValue = sessionStorage.getItem("valuesBackStep")
    ? sessionStorage.getItem("zipcode")
    : "";
  mobileNumberValue = sessionStorage.getItem("valuesBackStep")
    ? sessionStorage.getItem("mobileNumber")
    : "";
  businessWebsiteValue = sessionStorage.getItem("valuesBackStep")
    ? sessionStorage.getItem("businessWebsite")
    : "";
  userTokenValue = sessionStorage.getItem("userToken");

  componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const { firstName, lastName, businessWebsite, mobileNumber, zipcode } =
      this.state;
    const isNeedToValid =
      (prevState.firstName !== firstName) ||
      (prevState.lastName !== lastName) ||
      (prevState.businessWebsite !== businessWebsite) ||
      (prevState.mobileNumber !== mobileNumber) ||
      (prevState.zipcode !== zipcode)
    if(isNeedToValid) {
      const errors = this.validateStep3()
      this.setState({ formError: errors })
    }
  }

  openPolicyDialog = () => {
    this.setState({ isPolicyDialogOpen: true })
  }
  
  closePolicyDialog = () => {
    this.setState({ isPolicyDialogOpen: false })
  }

  handleFieldChange = (e: any) => {
    const { name, value }: { name: string; value: string } = e.target;
    let filteredValue = value
    if(name === "firstName" || name === "lastName") {
      filteredValue = value.replace(/[^a-zA-Z]/g, '');
    }
    this.setState(({
      [name]: filteredValue,
    } as unknown) as Pick<S, keyof S>);
  };

  validateStep3 = () => {
    const errors: any = {};
    if (!this.state.firstName) {
      errors.firstName = "First Name field is required.";
    }
    if (!this.state.lastName) {
      errors.lastName = "Last Name field is required.";
    }
    if (!this.state.businessWebsite) {
      errors.businessWebsite = "Business Website field is required.";
    }
    if (!this.state.zipcode) {
      errors.zipcode = "Zip Code field is required.";
    }

    if (!this.state.mobileNumber) {
      errors.mobileNumber = "Mobile Number field is required.";
    } else if (!isValidPhoneNumber(`+${this.state.mobileNumber}`)) {
      errors.mobileNumber = "Mobile Number is not valid.";
    }

    if (this.state.zipcode && !this.state.zipcode.match(/^\d+$/)) {
      errors.zipcode = "Zipcode must be a numeric value.";
      
    }

    return errors;
  };

  handleNextClick = () => {
    const errors = this.validateStep3();
    
    this.setState({
      formError: errors,
    });
    if (Object.keys(errors).length === 0) {
      this.doEmailSignUpDetails();
    }
  };

  goToLogin = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage))
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Login")
    msg.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msg)
  }

  async doEmailSignUpDetails() {
    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      full_phone_number: String(this.state.mobileNumber),
      sign_up_step: this.state.currentStep,
      zip_code: this.state.zipcode,
      business_website: this.state.businessWebsite,
    };

    const httpBody = {
      data: attrs,
    };

    const headerData = {
      "Content-Type": configJSON.signupEmailApiContentType,
      token: this.state.userToken,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apimailSignUpDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.SignUpEmailEndpoint}/${this.state.EmailPasswordID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupAPiDetailMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handlGlobalApiError = (responseJson: any) => {
    if (responseJson && (responseJson.error || responseJson.errors)) {
      const errMsg = "Something went wrong!";
      this.setState({ formGlobalError: { [this.state.currentStep]: errMsg } });
      return true;
    }
  };

  apicallresponseData = (responseJson: any) => {
    this.handlGlobalApiError(responseJson);
    if (!(responseJson.error || responseJson.errors)) {
      this.state.firstName &&
        sessionStorage.setItem("firstName", this.state.firstName);
      this.state.lastName &&
        sessionStorage.setItem("lastName", this.state.lastName);
      this.state.zipcode &&
        sessionStorage.setItem("zipcode", this.state.zipcode);
      this.state.mobileNumber &&
        sessionStorage.setItem("mobileNumber", this.state.mobileNumber);
      this.state.businessWebsite &&
        sessionStorage.setItem("businessWebsite", this.state.businessWebsite);
      this.props.handleSuccessResponse(responseJson);
    }
  };

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        apiRequestCallId != null &&
        apiRequestCallId === this.apimailSignUpDetailsCallId
      ) {
        this.apicallresponseData(responseJson);
      }
    }
  }
}
// Customizable Area End
