// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  Theme,
  createStyles,
  withStyles,
  styled,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Sidebar from "./Sidebar.web";
import NavigationMenu from "./NavigationMenu.web";
import { HomeIcon, ChatBubbleIcon, ContactsIcon, SettingsIcon } from "./assets";
import AppLayoutController, { Props } from "./AppLayoutController.web";
import { routes } from "../../../components/src/constansts";
import BaseModal from "./BaseModal";
import { AppLayoutModals } from "./enums";
import AfterSignup from "./AfterSignup.web";
import InviteFriends from "./InviteFriends.web";
import { PausedAccount } from "./PausedAccount.web";
import MobileNavMenu from "./MobileNavMenu.web";
import clsx from "clsx"
import NavigationMenuMobile from "./NavigationMenuMobile.web";
import { EverybrandLibrary } from "../../postcreation/src/EverybrandLibrary.web";
import { Dialog, DialogContent } from "@material-ui/core";


let theme = createTheme({
  palette: {
    primary: {
      main: "#FFC629",
      contrastText: "#000",
    },
    background: {
      default: "#F1F5F9",
    },
  },
});

theme = responsiveFontSizes(theme);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      '@media(min-width: 960px)': {
        display: 'flex'
      }
      // height: "100%"
    },
    //necessary for content to be below app bar
    offset: theme.mixins.toolbar,
    content: {
      flex: 1,
    },
    componentWrapper: {
      "@media (max-width: 1149px)": {
        marginBottom: "65px"
      },
      "@media (min-width: 1149px)": {
        margin: "0 24px 65px",
        marginLeft: "0px",
        marginRight: '0px',
        marginBottom: "0px"
      }
    },
    disabled: {
      position: "relative",
    
     height:"100%",
      opacity: 0.5,
      pointerEvents: "none",
      
    },

  });

const CustomDiloag = styled(Dialog)(({theme}) => ({
  "& .MuiDialog-paperFullWidth":{
    borderRadius: "8px 8px 32px 8px"
  },
  [theme.breakpoints.down(650)]: {
    "& .MuiDialog-paper": {
      margin: "0px",
    },
    "& .MuiDialog-paperFullWidth": {
      width: "calc(100% - 24px)",
    },
  },
  "& .MuiDialogContent-root":{
    padding: "20px 12px 20px"
  }
}))

const AfterSignupDialog = styled(Dialog)((({theme}) => ({
  "& .MuiDialogContent-root":{
    padding: "20px 12px 20px"
  },
  "& .MuiDialog-paperScrollPaper":{
    borderRadius: "8px 8px 32px 8px",
    [theme.breakpoints.down(768)]: {
      maxHeight: "calc(100% - 24px)",
    },
  },
  "& .MuiDialog-paper":{
    maxWidth: "90%",
    [theme.breakpoints.down(768)]: {
      margin: "12px",
    },
  },
  "& .MuiDialog-paperWidthSm":{
    maxWidth: "900px"
  }
})));

const navigationMenus = [
  { name: "home", icon: HomeIcon, path: routes.dashboard },
  { name: "Messages", icon: ChatBubbleIcon, path: routes.messages },
  { name: "Contacts", icon: ContactsIcon, path: routes.contacts },
  { name: "Automations", icon: SettingsIcon, path: routes.automations },
];

class AppLayout extends AppLayoutController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { location } = this.props;
    const pathname: string = location?.pathname || "";
    const subscriptionStatus = localStorage.getItem("subscriptionStatus") === "true";
  
   const isSettingsRoute = pathname !== '/settings/true';

    const {
      selectedWebsite,
      websitesList,
      isMobileDrawerOpen,
      currentUserInfo,
      showModal,
      openLogoutModal,
     
    } = this.state;
  
    const { children, classes,...otherprops } = this.props;
    const socialPhoneNumber = currentUserInfo?.attributes?.social_phone_number || "";
    return (
      <ThemeProvider theme={theme} >
        <div className={classes.root}>
          <div className={subscriptionStatus ? classes.disabled : ""}>
          <CssBaseline />
          {!((pathname.includes("/settings") || pathname.includes("/VisualAnalytics") || pathname.includes("/messages")) && window.innerWidth < 950) ? <NavigationMenu
            navigationMenus={navigationMenus}
            selectedWebsite={selectedWebsite}
            websitesList={websitesList}
            currentUserInfo = {currentUserInfo}
            onWebsiteSelect={this.handleWebsiteSelection}
            onDrawerToggle={this.handleDrawerToggle}
          /> : <NavigationMenuMobile 
                  backButton={this.state.backArrowFunction}
                  name={this.state.mobileNavigationName}
                  icon={this.state.mobileNavigationIcon}
                  dropdownButton={this.state.dropdownFunction}>
          </NavigationMenuMobile>}
        {this.state.showSidebar &&
        <Sidebar
            businessUserInfo={currentUserInfo}
            isMobileDrawerOpen={isMobileDrawerOpen}
            onCloseDrawer={this.handleDrawerToggle}
            onLogout={this.logoutAndRedirectToLogin}
            logoutModal={this.openLogoutModal}
            logoutModalState={openLogoutModal}
            openModal={this.openModal}
        />}
      </div>
        <main className={classes.content}>
          <div className={classes.offset} />
          {React.Children.map(children, (child:any) => {
            return (
              <>           
                {subscriptionStatus && isSettingsRoute && (<PausedAccount classes={{}} navigation={undefined} id={""} banUserApiCall={()=>{} } postCommentApiCall={()=>{} }/>)}
                <div className={clsx(classes.componentWrapper, { [classes.disabled]: subscriptionStatus && isSettingsRoute })}>
                  {React.cloneElement(child, { ...otherprops })}
                </div>
              </>
            );
          })}

        </main>
          {showModal && showModal === AppLayoutModals.INVITE_FRIENDS && (
            <BaseModal closeModal={this.closeModal} modalData={{ socialPhoneNumber }}>
              {showModal === AppLayoutModals.INVITE_FRIENDS &&
                <InviteFriends closeModal={this.closeModal} />
              }
            </BaseModal>
          )}
          {
            <>
              {showModal === AppLayoutModals.AFTER_SIGNUP_WELCOME &&
                <AfterSignupDialog open onClose={this.closeModal}>
                  <AfterSignup closeModal={this.closeModal} socialPhoneNumber={socialPhoneNumber} />
                </AfterSignupDialog>
              }
            </>
          }
          {showModal && showModal === AppLayoutModals.EVERYREEL_LIBRARY && ( 
            <>
              <CssBaseline />
              <CustomDiloag open fullWidth maxWidth="md" onClose={this.closeModal}>
                <DialogContent>
                  <EverybrandLibrary goBack={() => {this.closeModal()}} setFiles={() => {}}/>
                </DialogContent>
              </CustomDiloag>
            </>
            )
          }
        </div>
        <MobileNavMenu navigation={this.props.navigation} />
      </ThemeProvider>
    );
  }
}

export { AppLayout };
export default withStyles(styles)(withRouter(AppLayout));
// Customizable Area End
