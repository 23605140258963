import {Avatar, styled} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface ImageButtonProps {
  imageUrl: string;
  accountId: number;
  handlePostProfileClick: (accountId: number) => void
}

const ProfileIcon: React.FC<ImageButtonProps> = ({ imageUrl, accountId, handlePostProfileClick }) => {
  return (
    <CustomAvatar
      data-test-id="preview-post-profile-wrapper"
      onClick={(event) => { event.stopPropagation(); handlePostProfileClick(accountId) }}
    >
      <Link to={`/profile/${accountId}`}>
        <img
          src={imageUrl}
          className="fallbackAvatar"
        />
      </Link>
    </CustomAvatar>
  );
};

const CustomAvatar = styled(Avatar)(({theme}) => ({
  width: "30px",
  height: "30px",
  backgroundColor: "#FFF",
  border: "2px solid #F0E5FF",
  [theme.breakpoints.down(950)]: { 
    width: "30px",
    height: "30px",
    "& img": {
      height: "30px",
      width: "30px"
    }
  },
  "& .fallbackAvatar": {
      width: "56px",
      height: "56px",
      objectFit: "cover",
    }, 
}))

export default ProfileIcon;
