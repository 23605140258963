// Customizable Area Start
import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Avatar, Box } from '@material-ui/core';
import { nextSliderArrow, smileYellow } from './assets';
import TopActiveUsersController from './TopActiveUsersController.web';
import { Swiper, SwiperSlide } from 'swiper/react';
const configJSON = require("./config.js")

const MainPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2,2),
  width: '100%',
  '@media (max-width: 768px)': {
    width: 'unset'
  },
  [theme.breakpoints.down(950)]: {
    padding: 0
  },
}))

const MainHeading = styled(Typography)({
  color: "#0F172A",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
  '@media (max-width: 960px)': {
    flexWrap: 'wrap'
   },
})

const SubHeading = styled(Typography)({
  color: "#000",
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
})

const ContentContainer = styled(Grid)(({
  theme
}) => ({
  padding: theme.spacing(2),
  display: "flex",
   flexWrap: 'wrap',
    width: '100%'
}))

const ProfileContainer = styled(Grid)({
  display:"grid",
  placeItems:"center",
  overflow:"hidden",
  cursor: "pointer"
})

const BrandAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  border: '4px solid #FFC629',
  marginBottom: theme.spacing(1),
  backgroundColor: '#FFFFFF',
  cursor: "pointer",
  [theme.breakpoints.down(950)]: {
    marginTop:"28px"
  },
}))

const BrandImage = styled('img')({
  width: '56px',
  height: '56px',
  objectFit:'cover'
})

const BrandName = styled(Typography)({
  color:"#0F172A",
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: "100%",
  fontFamily: "Inter",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "center",
})

const EmptyWrapper = styled(Box)({
  height: "150px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: 'center',
  width: "100%",
  fontSize: '14px',
  color: "#999",
})

const MobileFollowersView = styled(Box)({
  padding: "0",
  "@media (min-width: 950px)": {
    display: "none",
  }
})

const DesktopFollowersView = styled(Box)({
  "@media (max-width: 950px)": {
    display: "none",
  }
})

const FollowersSliderBox = styled(Box)(({theme}) => ({
  width: "100%",
  position: "relative",
  padding: "0 40px",
  "& .followersText":{
    position: "absolute",
    left: "12px",
    top: "8px",
    zIndex: 2,
    fontSize: "12px",
    fontWeight: 700,
  },
  "& .swiper-pagination":{
    "& .swiper-button-disabled": {
      opacity: 0.1
    },
  },
  "& .swiperLeftButton":{
    position: "absolute",
    top: "58px",
    left: "12px",
    zIndex: 2,
  },
  "& .swiperRightBtn":{
    position: "absolute",
    top: "58px",
    right: "12px",
    "& .swiper-button-disabled": {
      opacity: 0
    },
  },
  "& .swiperSlide":{
    padding: '8px 0',
    width: "128px",
    [theme.breakpoints.down(950)]: {
      width: "80px"
    },
  },
}))

class TopActiveUsers extends TopActiveUsersController {

  renderTop6ForMobile(){
    const {top6} = this.props;
    return (
        <FollowersSliderBox>
          <Typography className='followersText'>Followers{" "}{this.state.trendingProfilesBU.length}</Typography>
          <div className="swiperLeftButton"
              id="swiper-button-prev">
              <img src={nextSliderArrow} style={{ height: "20px", width: "20px", color: "#334155", zIndex: 100, cursor: "pointer", transform: "rotate(180deg)" }} />
          </div>
          <Swiper
            spaceBetween={8}
            slidesPerView={"auto"}
            navigation={{
                prevEl: '#swiper-button-prev',
                nextEl: '#swiper-button-next',
            }}
          >
            {this.state.trendingProfilesBU.map((user, index) => {
              const { id, attributes: { profile_pic, first_name, last_name } } = user;
              const fullName = `${first_name} ${last_name}`;
              return <SwiperSlide key={index} className="swiperSlide">
                <BrandAvatar data-test-id="brand-avatar" onClick={() => this.handleProfileRedirect(id)}>
                  <BrandImage
                    src={profile_pic ? profile_pic.url : smileYellow}
                    alt="Brand Logo"
                  />
                </BrandAvatar>
                <BrandName title={fullName}>{fullName}</BrandName>
              </SwiperSlide>
            })}
          </Swiper>
          <div id="swiper-button-next" className="swiperRightBtn">
            <img src={nextSliderArrow} style={{ height: "20px", width: "20px", color: "#334155", zIndex: 100, cursor: "pointer" }} />
          </div>
        </FollowersSliderBox>)
  }
  
  render() {
    const { top6 } = this.props

    return (
      <MainPaper square elevation={0}>
        <MobileFollowersView>
          {this.renderTop6ForMobile()}
        </MobileFollowersView>

        <DesktopFollowersView>
          <MainHeading data-test-id="main-heading" gutterBottom variant="subtitle2" style={{ textAlign: "center", fontSize: "18px" }}>
            Your top 6
          </MainHeading>
          <SubHeading data-test-id="sub-heading" gutterBottom style={{ textAlign: "center", fontSize: "14px", color: "#666" }}>
            The most active users in your platform
          </SubHeading>
          <ContentContainer container spacing={2} style={{ margin: 0, width: "100%" }}>
            {this.state.trendingProfilesBU.length > 0 ? (
              this.state.trendingProfilesBU.map((brand) => {
                const {
                  id,
                  attributes: { profile_pic, first_name, last_name },
                } = brand;
                const fullName = `${first_name} ${last_name}`;

                return (
                  <ProfileContainer
                    key={id}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    data-test-id="profile-wrapper"
                    onClick={() => this.handleProfileRedirect(id)}
                  >
                    <BrandAvatar data-test-id="brand-avatar">
                      <BrandImage
                        src={profile_pic ? profile_pic.url : smileYellow}
                        alt="Brand Logo"
                      />
                    </BrandAvatar>
                    <BrandName title={fullName}>{fullName}</BrandName>
                  </ProfileContainer>
                );
              })
            ) : (
              <EmptyWrapper style={{ width: "100%", textAlign: "center" }}>
                <Typography data-test-id="empty-list-message" align="center" style={{ color: "#999", fontSize: "14px" }}>
                  {configJSON.noDataAvailableText}
                </Typography>
              </EmptyWrapper>
            )}
          </ContentContainer>
        </DesktopFollowersView>
      </MainPaper>
    );
}
}

export default TopActiveUsers;
// Customizable Area End
	