import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import BusinessEmailController, {
  configJSON,
} from "./BusinessEmailController.web";
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import EmailAccountLoginErrorBlock from "./EmailAccountRegistrationErrorBlock.web";
import { groupStarbar } from "./assets";
import { everybrandLogo } from "../../email-account-login/src/assets";

const styles = (theme: Theme) => (
  createStyles({
    container: {
      height: "100%",
      width: "100%",
      paddingLeft: "0",
      [theme.breakpoints.down('md')]: {
        height: "auto"
      }
    },
    nextHomePage: {
      backgroundColor: "#FFC629",
      marginTop: '37px',
      width: "100%",
      textTransform: "capitalize" as "capitalize",
      borderRadius: theme.spacing(1),
      height: "56",
      fontSize: "16px",
      fontWeight: 700,
      '&:hover': {
        backgroundColor: "#FFC629",
        color: "black",
      },
    },
    EmailSubtitle1:
    {
      color: "#334155",
      fontWeight: 700,
      fontSize: "16px",
    },
    input: {
      "& .MuiOutlinedInput-root":{
        borderRadius: "8px"
      },
      '& > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#FFC629' },
    },
    BackHomePage:
    {
      color: "#fff",
      marginTop: '40px',
      backgroundColor: "#0F172A",
      fontSize: "16px",
      fontWeight: 700,
      width: "100%",
      textTransform: "capitalize" as "capitalize",
      borderRadius: theme.spacing(1),
      height: "56px",
      [theme.breakpoints.down(768)]: {
        marginTop: "32px"
      },
      '&:hover': {
        backgroundColor: "#0F172A",
        color: "#fff",
      },

      "& *": {
        width: "100%",
        height: "100%"
      },

      "& a": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        opacity: 1,
        fontSize: "16px",
        color: "#fff",
        textDecoration: "none",
      },
    },
    CommunityText: {
      marginRight: "5px",
      paddingTop: "3%"
    },
    headerText: {
      marginBottom: "25px",
      fontSize: "24px",
      fontWeight: 700,
      [theme.breakpoints.down(768)]: {
        fontSize: "20px"
      },

    },
    formContainers:
    {
      display: "flex",
      maxWidth: "360px",
      marginTop: "23%",
      [theme.breakpoints.down('md')]: {
        marginTop: "10%",
      },
      "& *": {
        fontFamily: "Inter, 'sans-serif'",
      },
      [theme.breakpoints.down(769)]: {
        padding: "24px",
        flexDirection: 'column'
      },
    },
    mobileImages:{
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: 'center',
      gap: "12px",
      margin: "0 0 24px 0",
      [theme.breakpoints.up(768)]: {
        display: "none"
      },
    },
    protectCommunity: {
      lineHeight: "25px"
    },
    BelowCommunityFlex: {
      display: "flex",
      marginTop: "20px",
    }
  })
);

class BusinessEmail extends BusinessEmailController {

  render() {
    const { classes }: any = this.props;
    const {
      emailAddress,
      formError,
      formGlobalError,
      currentStep
    } = this.state;
    return (
      // Required for all blocks

      <Box className={classes.formContainers}>

        <Box className={classes.mobileImages}>                
          <img src={everybrandLogo} alt="everybrand logo" />
          <img src={groupStarbar}/>
        </Box>

        <Box sx={classes.container}>
          {formGlobalError[currentStep] && (
            <EmailAccountLoginErrorBlock
              errorMsg={formGlobalError[currentStep]}
            />  
          )}
          <Typography variant="h5" gutterBottom className={classes.headerText} >
            {configJSON.EnterBusiness}
          </Typography>

          < Typography variant="subtitle2" className={classes.EmailSubtitle1}
            gutterBottom >
            {configJSON.Email}
          </Typography>
          <TextField
            fullWidth
            placeholder="Your email"
            className={classes.input}
            variant="outlined"
            size="small"
            name="emailAddress"
            onChange={(e) => this.handleFieldChange(e)}
            value={emailAddress}
            error={Boolean(formError.emailAddress)}
            helperText={formError.emailAddress}
            data-test-id="emailInput"
            inputProps={{
              style: {
                height: "35px",
              },
            }}
          />
          <Box>
            <Button variant="contained" onClick={this.handleNextClick} data-test-id="nextButton" className={classes.nextHomePage} disableElevation>{configJSON.next}</Button>
            <Button variant="contained" data-test-id="BackButton" className={classes.BackHomePage} disableElevation>
              <Link to="/login">{configJSON.back}</Link>
            </Button>
          </Box>

          <Box className={classes.BelowCommunityFlex}>
            <LockOutlinedIcon className={classes.CommunityText}></LockOutlinedIcon>
            <Typography variant="body2" gutterBottom className={classes.protectCommunity}>
              {configJSON.communty}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }
}
export { BusinessEmail };
export default withStyles(styles)(BusinessEmail);
// Customizable Area End
