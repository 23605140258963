// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  withStyles,
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  Hidden
} from "@material-ui/core";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import { AnalyticsPages } from "./enums";
import AnalyticsMainStatsController from "./AnalyticsMainStatsController.web";
import AnalyticsChart from "./AnalyticsChart.web";

export class AnalyticsMainStats extends AnalyticsMainStatsController {
  render() {
    const {
      classes,
      currentPage,
      engagements,
      chartData,
      dataRange,
      changeEngagementCheck,
      setCurrentPage,
      setDataRange,
    } = this.props;

    const { dateMenuAnchorEl } = this.state;

    const options = ["Overview", "Leads", "Engagement", "Waitlist", "EveryReel", "Reviews"];
    const values = [AnalyticsPages.OVERVIEW, AnalyticsPages.LEADS, AnalyticsPages.ENGAGEMENT, AnalyticsPages.WAITLIST, AnalyticsPages.EVERYREEL, AnalyticsPages.REVIEWS]

    const timeOptions = ["Last 7 days", "Last 28 days", "Last 60 days", "Past Year", "Past 2 Years", "All time"];
    const timeValues = [7, 28, 60, 365, 720, 10000];

    const handleSelectChangeTime = (event: React.ChangeEvent<{ value: unknown }>) => {
      setDataRange(event.target.value as number);
    }

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setCurrentPage(event.target.value as AnalyticsPages);
    };

    return (
      <Box className={classes.mainContainer}>
        <Box className={classes.navigation}>
          <Button
            id="overviewButton"
            data-test-id="overviewButton"
            onClick={() => setCurrentPage(AnalyticsPages.OVERVIEW)}
            className={classes.navigationButton}
            style={{
              color: this.getStyleValue(
                currentPage === AnalyticsPages.OVERVIEW,
                "#000",
                "#94A3B8"
              ),
              borderBottom: this.getStyleValue(
                currentPage === AnalyticsPages.OVERVIEW,
                "3px solid #000",
                "3px solid transparent"
              )
            }}
          >
            Overview
          </Button>
          <Button
            data-test-id="leadsButton"
            onClick={() => setCurrentPage(AnalyticsPages.LEADS)}
            className={classes.navigationButton}
            style={{
              color: this.getStyleValue(
                currentPage === AnalyticsPages.LEADS,
                "#000",
                "#94A3B8"
              ),
              borderBottom: this.getStyleValue(
                currentPage === AnalyticsPages.LEADS,
                "3px solid #000",
                "3px solid transparent"
              )
            }}
          >
            Leads
          </Button>
          <Button
            data-test-id="engagementButton"
            onClick={() => setCurrentPage(AnalyticsPages.ENGAGEMENT)}
            className={classes.navigationButton}
            style={{
              color: this.getStyleValue(
                currentPage === AnalyticsPages.ENGAGEMENT,
                "#000",
                "#94A3B8"
              ),
              borderBottom: this.getStyleValue(
                currentPage === AnalyticsPages.ENGAGEMENT,
                "3px solid #000",
                "3px solid transparent"
              )
            }}
          >
            Engagement
          </Button>
          <Button
            data-test-id="waitlistButton"
            onClick={() => setCurrentPage(AnalyticsPages.WAITLIST)}
            className={classes.navigationButton}
            style={{
              color: this.getStyleValue(
                currentPage === AnalyticsPages.WAITLIST,
                "#000",
                "#94A3B8"
              ),
              borderBottom: this.getStyleValue(
                currentPage === AnalyticsPages.WAITLIST,
                "3px solid #000",
                "3px solid transparent"
              )
            }}
          >
            Waitlist
          </Button>
          <Button
            data-test-id="everyreelButton"
            onClick={() => setCurrentPage(AnalyticsPages.EVERYREEL)}
            className={classes.navigationButton}
            style={{
              color: this.getStyleValue(
                currentPage === AnalyticsPages.EVERYREEL,
                "#000",
                "#94A3B8"
              ),
              borderBottom: this.getStyleValue(
                currentPage === AnalyticsPages.EVERYREEL,
                "3px solid #000",
                "3px solid transparent"
              )
            }}
          >
            EveryReel
          </Button>
          <Button
            data-test-id="reviewsButton"
            id="reviewsButton"
            onClick={() => setCurrentPage(AnalyticsPages.REVIEWS)}
            className={classes.navigationButton}
            style={{
              color: this.getStyleValue(
                currentPage === AnalyticsPages.REVIEWS,
                "#000",
                "#94A3B8"
              ),
              borderBottom: this.getStyleValue(
                currentPage === AnalyticsPages.REVIEWS,
                "3px solid #000",
                "3px solid transparent"
              )
            }}
          >
            Reviews
          </Button>
        </Box>

        <div className={classes.navigationDropdown}>
          <select value={currentPage} onChange={handleSelectChange} className={classes.dropDownOfType}>
            {options.map((option, index) => (
              <option key={option} value={values[index]}>
                {option}
              </option>
            ))}
          </select>
          <span className={classes.dropDownArrow}>▼</span>
        </div>


        <Box className={classes.filtersContainer}>
          <Button
            data-test-id="dataRange7Button"
            className={classes.filterItem}
            style={{
              backgroundColor: this.getStyleValue(
                dataRange.endDate.diff(dataRange.startDate, "days") === 7,
                "#000",
                "#F1F5F9"
              ),
              color: this.getStyleValue(
                dataRange.endDate.diff(dataRange.startDate, "days") === 7,
                "#FFF",
                "#334155"
              ),
            }}
            onClick={() => setDataRange(7)}
          >
            Last 7 days
          </Button>
          <Button
            data-test-id="dataRange28Button"
            className={classes.filterItem}
            style={{
              backgroundColor: this.getStyleValue(
                dataRange.endDate.diff(dataRange.startDate, "days") === 28,
                "#000",
                "#F1F5F9"
              ),
              color: this.getStyleValue(
                dataRange.endDate.diff(dataRange.startDate, "days") === 28,
                "#FFF",
                "#334155"
              ),
            }}
            onClick={() => setDataRange(28)}
          >
            Last 28 days
          </Button>
          <Button
            data-test-id="dataRange60Button"
            className={classes.filterItem}
            style={{
              backgroundColor: this.getStyleValue(
                dataRange.endDate.diff(dataRange.startDate, "days") === 60,
                "#000",
                "#F1F5F9"
              ),
              color: this.getStyleValue(
                dataRange.endDate.diff(dataRange.startDate, "days") === 60,
                "#FFF",
                "#334155"
              ),
            }}
            onClick={() => setDataRange(60)}
          >
            Last 60 days
          </Button>
          <Button
            data-test-id="dataRangeAllTimeButton"
            className={classes.filterItem}
            style={{
              backgroundColor: this.getStyleValue(
                this.isAllTimeSelected(),
                "#000",
                "#F1F5F9"
              ),
              color: this.getStyleValue(
                this.isAllTimeSelected(),
                "#FFF",
                "#334155"
              ),
            }}
            onClick={() => setDataRange(10000)}
          >
            All time
          </Button>

          <Box>
            <Button
              data-test-id="dataRangeCustom"
              className={classes.filterItem}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={(e) => this.setMenuAnchor(e.target as Element)}
              endIcon={<ArrowDropDownRoundedIcon />}
            >
              Custom
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={dateMenuAnchorEl}
              keepMounted
              open={Boolean(dateMenuAnchorEl)}
              onClose={() => this.setMenuAnchor(null)}
              className={classes.dateMenu}
            >
              <MenuItem>Date Range</MenuItem>
              <MenuItem data-test-id="dataRangePastYear" onClick={() => setDataRange(365)}>Past Year</MenuItem>
              <MenuItem data-test-id="dataRangePast2Years" onClick={() => setDataRange(730)}>
                Past 2 Years
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        <Box className={classes.filtersContainerDropDown}>
          <select value={dataRange.endDate.diff(dataRange.startDate, "days")} onChange={handleSelectChangeTime} className={classes.dropDownOfTime}>
              {timeOptions.map((option, index) => (
                <option key={option} value={timeValues[index]}>
                  {option}
                </option>
              ))}
            </select>
            <span className={classes.dropDownArrow}>▼</span>
        </Box>

        <Box className={classes.engagementContainer}>
          <Typography className={classes.engagementTitle}>
            {currentPage !== AnalyticsPages.ENGAGEMENT
              ? `${currentPage.toLowerCase()} Engagement`
              : "Platform Engagement"}
          </Typography>

          <Box className={classes.engagementItemsContainer}>
            {engagements.map((engagement, i) => {
              const countDifference =
                engagement.currentCount - engagement.pastCount;
              const percentDifference = Math.round(
                (countDifference / (engagement.pastCount || 1)) * 100
              );
              const percentage = ` (${percentDifference}%)`

              return (
                <Box
                  key={`engagementBlock-${i}`}
                  data-test-id={`engagementBlock-${i}`}
                  onClick={() => changeEngagementCheck(engagement.id)}
                  className={classes.engagementItem}
                  style={{
                    backgroundColor: engagement.checked ? "#FFC629" : "#F1F5F9",
                  }}
                >
                  <Typography className={classes.engagementItemTitle}>
                    {engagement.type.split("_").join(" ")}
                  </Typography>

                  <Box>
                    <Typography className={classes.engagementCurrentCount}>
                      {engagement.currentCount.toLocaleString("en", {
                        useGrouping: true,
                      })}
                    </Typography>
                    {
                      currentPage === "REVIEWS" && engagement.type ==="overall_review_rating" ? 
                      <Box style={{
                        color:"#000000",
                        fontFamily:"Inter",
                        fontSize:"12px",
                        fontWeight:400,
                        lineHeight:"18px",
                        marginBottom:"3px"

                      }}>
                        out of 5
                      </Box> : ''
                    }         
                    <Typography
                      className={classes.engagementPrevCount}
                    >{`${countDifference}${this.isAllTimeSelected() ? "" : percentage}`}</Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>

        <AnalyticsChart
          chartData={chartData || []}
          chartTitle={this.getChartTitle()}
          startDate={dataRange.startDate}
          endDate={dataRange.endDate}
        />
      </Box>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      padding: "50px 20px 20px 40px",
      backgroundColor: "#FFF",
      borderRadius: "20px",

      "& .MuiMenu-list > li:hover": {
        backgroundColor: "#000",
      },

      "& .MuiList-padding": {
        padding: 0,
      },
      [theme.breakpoints.down(800)]: {
        padding: "10px"
      }
    },

    navigation: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "50px",
      paddingBottom: "40px",
      [theme.breakpoints.down(800)]: {
        display: "none"
      },
    },

    navigationButton: {
      padding: 0,
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "26px",
      color: "#94A3B8",
      cursor: "pointer",
      textTransform: "none",
      borderBottom: "3px solid transparent",
      borderRadius: "unset",
    },

    navigationDropdown: {
      position: "relative",
      display: "inline-block",
      margin: "20px 20px 0 20px",
      [theme.breakpoints.up(800)]: {
        display: "none",
      }
    },

    dropDownOfType: {
      background: "#fff",
      appearance: "none",
      border: "1px solid #d1d5db",
      borderRadius: "20px",
      padding: "5px 15px",
      fontSize: "16px",
      fontWeight: "bold",
      cursor: "pointer",
      outline: "none",
      width: "100%",
      marginBottom: "20px",
      
    },

    dropDownArrow: {
      position: "absolute",
      right: "10px",
      top: "35%",
      transform: "translateY(-50%)",
      pointerEvents: "none",
      fontSize: "12px",
    },

    filtersContainer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "8px",
      paddingBottom: "24px",
      [theme.breakpoints.down(800)]: {
        display: "none"
      }
    },

    filtersContainerDropDown: {
      position: "relative",
      display: "inline-block",
      margin: "0 20px",
      [theme.breakpoints.up(800)]: {
        display: "none"
      }
    },

    dropDownOfTime: {
      background: "#F1F5F9",
      appearance: "none",
      border: "none",
      borderRadius: "26px",
      padding: "5px 15px",
      fontSize: "14px",
      cursor: "pointer",
      outline: "none",
      width: "100%",
      marginBottom: "20px",
    },

    filterItem: {
      padding: "6px 12px 8px",
      borderRadius: "26px",
      backgroundColor: "#F1F5F9",
      textTransform: "none",
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "22px",
    },
    
    dateMenu:{
      "& .MuiList-padding":{
        padding: "0"
      },
      "& .MuiMenuItem-root:hover":{
        background: "#000",
        color: "#fff",
        borderRadius: "8px"
      },
    },

    engagementContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      paddingBottom: "40px",
    },

    engagementTitle: {
      paddingLeft: "10px",
      fontFamily: "Inter",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      color: "#000",
      textTransform: "capitalize",
      [theme.breakpoints.down(800)]: {
        fontSize: "16px"
      }
    },

    engagementItemsContainer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "21px",
      [theme.breakpoints.down(800)]: {
        flexWrap: "wrap",
      }
    },

    engagementItem: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      flexBasis: "198px",
      height: "147px",
      padding: "16px 19px",
      justifyContent: "space-between",
      borderRadius: "15px",
      cursor: "pointer",
      [theme.breakpoints.down(800)]: {
        // maxWidth: "128px"
      }
    },

    engagementItemTitle: {
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
      textTransform: "capitalize",
      [theme.breakpoints.down(576)]: {
        fontSize: "12px"
      }
    },

    engagementCurrentCount: {
      fontFamily: "Inter",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
    },

    engagementPrevCount: {
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "24px",
    },
  });

export default withStyles(styles)(AnalyticsMainStats);
// Customizable Area End
