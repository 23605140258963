import React from 'react';
import { Typography } from '@material-ui/core';

interface PostInfoProps {
  title: string;
  description: string;
}

const PostInfo: React.FC<PostInfoProps> = ({ title, description }) => {
  return (
    <div style={styles.infoContainer}>
      <Typography variant="h6" style={styles.title}>{title}</Typography>
      <Typography variant="body2" style={styles.description}>{description}</Typography>
    </div>
  );
};

const styles = {
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 350,
    height: "60px",
    position: 'absolute',
    bottom: 60,
    color: 'white',
    left: 25
  } as React.CSSProperties,
  title: {
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: 'Inter',
    lineHeight: '24px',
  } as React.CSSProperties,
  description: {
    marginTop: '8px',
  } as React.CSSProperties,
};

export default PostInfo;
