// Customizable Area Start
import React from "react";
import InviteFriendsController from "./InviteFriendsController.web";
import { InviteFriendsStepType } from "./enums";
import InviteFriendsModal from "./InviteFriendsModal.web";
import AddContactModal from "./AddContactModal.web";
import BulkInviteFriends from "./BulkInviteFriends.web";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

class InviteFriends extends InviteFriendsController {
  render() {
    const { closeModal } = this.props;
    const { normalizedContacts, inviteMessage, link, apiSuccessResponseMessage, apiErrorResponseMessage } = this.state;

    return (
      <>
        {this.state.stepType === InviteFriendsStepType.INVITE_FRIEND && (
          <InviteFriendsModal
            contacts={normalizedContacts}
            inviteMessage={inviteMessage}
            link={link}
            handleSubmitInvite={this.handleSubmitInvite}
            setStep={this.setStep}
            closeModal={closeModal}
            setInviteMessage={this.setInviteMessage}
            setLink={this.setLink}
            checkContact={this.checkContact}
          />
        )}
        {this.state.stepType === InviteFriendsStepType.ADD_CONTACT && (
          <AddContactModal
            addContact={this.addContact}
            setStep={() => this.setStep(InviteFriendsStepType.INVITE_FRIEND)}
            currentStep={this.state.stepType}
            hideTopNavigation={true}
          />
        )}
        {this.state.stepType === InviteFriendsStepType.BULK_INVITE && (
          <BulkInviteFriends
            contacts={normalizedContacts}
            addContact={this.addContact}
            checkBulkContact={this.checkBulkContact}
            setStep={this.setStep}
            submitInvites={() => this.setStep(InviteFriendsStepType.INVITE_FRIEND)}
            data-test-id="bulkinviteFriends"
          />
        )}

        <Snackbar
          open={Boolean(apiSuccessResponseMessage || apiErrorResponseMessage)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={this.onCloseSnackbar}
        >
          <MuiAlert elevation={6} variant="filled" severity={apiSuccessResponseMessage ? "success" : "error"}>
            {apiSuccessResponseMessage || apiErrorResponseMessage}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

export { InviteFriends };
export default InviteFriends;
// Customizable Area End
