// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from '../../../framework/src/BlockComponent';
export const configJSON = require('./config.js');
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import { thumbnail } from "./assets";
import SwiperCore from 'swiper';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton
} from "@material-ui/core";
import React from "react"
import clsx from "clsx"
import { getStorageData } from "../../../framework/src/Utilities";
import { CloseRounded } from "@material-ui/icons";
import { IBrandDetails } from "../../dashboard/src/interfaces";

const QUESTIONS = [
  "My Real-Life Superpower Is...",
  "After Work You Can Find Me...",
  "A Review from a Friend...",
  "What would you name your yacht if you had one...",
  "If I can only eat one meal for the rest of my life it would be...",
  "What is your most embarrassing moment?",
  "What is your weird talent or skill?",
  "What was your favorite TV show?",
  "What is the most cringe-inducing pick up line you have ever heard?",
  "What products have you purchased from an infomercial?",
  "If you could merge animals together, which two would you choose and why?",
  "What is the best dad joke you know?",
  "Which animal do you think would be the funniest if it could talk?",
  "What is the most useless superpower you can think of?",
  "What is your go to song?",
  "What are your thoughts on people watching?",
  "What word can you never remember how to spell?",
  "How do you think your last boss would describe you?",
  "Have you ever had a weird interaction with a celebrity?",
  "What are you more scared of? Bugs or snakes?",
  "What is the best worst movie you’ve ever seen?",
  "Would you rather be rich or famous?",
  "Would you rather go on your dream vacation for only two days or have a week off at home?",
  "Would you rather explore the ocean or space?",
  "Would you rather explore Willy Wonka’s Chocolate Factory or Jurassic Park?",
  "What game show do you really want to be a guest on?",
  "What is the funniest thing you’ve heard a kid say?",
  "If you could live in a TV show, which would you choose?",
  "Would you rather meet a ghost or an alien and why?",
  "What is your favorite smell?",
  "What scared you the most as a kid?",
  "Who is your hero in life?",
  "If you could live anywhere in the world, where would you choose?",
  "What are you most proud of in your life?",
  "What do you do in your downtime?",
  "What one word could people use to describe you accurately?",
  "Who would you haunt if you were a ghost?",
  "Who would you get to play you in a movie of your life?",
  "Which three guests would you have dinner with, alive or dead?",
  "What is your favorite book series?",
  "What has been your greatest success so far?",
  "What is your favorite sport?",
  "What three things would you take with you if you were stranded on a desert island?",
  "If you could go back in time, when would you travel to?"
]

export interface ISelectedPrompt {
  question: string
  answer: string
}

interface BrandAttributes {
  activated: boolean;
  country_code: string | null;
  email: string;
  full_name: string | null;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string | null;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  pin: number;
  sign_up_step: number;
  business_website: string;
  zip_code: string;
  social_phone_number: string;
  is_first_login: boolean;
  profile_pic: {
    url : string
  } | null;
  cover_photo: {
    url : string
  } | null;
  follows: {
      followers: string;
      followings: string;
  };
  subscription_paused: boolean;
}
export interface Brand {
  id: string;
  type: string;
  attributes: BrandAttributes;
}

type IFollowBrandApiResponse =
  { data: Brand } | {
    errors: string[]
  } | { error: { message: string }}


interface ProfileEU {
  id: number | null;
  country: string | null;
  city: string | null;
  postal_code: string | null;
  account_id: number | null;
  personality_prompt: {
    question: string
    answer: string
  }[];
  headline: string | null;
  name: string | null;
  email: string | null;
  reviews: number;
  average_rating: number;
  photo: string | null;
  followers: number | string | null;
  following: number | string | null;
}

interface WorkingHour {
  id: number;
  account_id: number;
  day: number;
  active: boolean;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  day_name: string;
}

interface AccountProfile {
  url: string;
  content_type: string;
}

interface PollOption {
  id: number;
  post_poll_id: number;
  name: string;
  vote_count: number;
  created_at: string;
  updated_at: string;
}

interface AccountAttributes {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: string | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  full_name: string;
  stripe_id: string | null;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  role_id: string | null;
  gender: string | null;
  date_of_birth: string | null;
  age: string | null;
  sign_up_step: number;
  business_website: string | null;
  zip_code: string | null;
  role_name: string;
  pin: number;
  valid_until: string | null;
  working_hours: WorkingHour[];
  holiday_hours: any[];
  holiday_hours_activated: boolean;
  is_accepted_privacy_policy: boolean;
  ip_address: string | null;
  privacy_policy_accepted_at: string | null;
  social_phone_number: string | null;
  is_first_login: boolean;
  widget_code: string;
  is_chat_clicked: string | null;
}

interface Account {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface CommentAttributes {
  id: number;
  account_id: number;
  commentable_id: number;
  commentable_type: string;
  comment: string;
  visibility: string;
  likes_count: number;
  comments_count: number;
  created_at: string;
  archived: boolean;
  account: { data: Account };
  replies: { data: any[] };
  already_liked: boolean | null;
  files: any;
}

interface Comment {
  id: string;
  type: string;
  attributes: CommentAttributes;
}

interface Comments {
  data: Comment[];
}

interface Poll {
  id: number;
  post_id: number;
  question: string;
  created_at: string;
  updated_at: string;
}

interface Setting {
  id: number;
  settable_type: string;
  settable_id: number;
  social_id: number | null;
  choices_arrival_time: string | null;
  multiple_selection: boolean;
  quality_upload: boolean;
  comment_allowed: boolean;
  created_at: string;
  updated_at: string;
}

interface FileType {
  link: string;
}

interface Attributes {
  body: string;
  description: string;
  account_id: number;
  likes_count: number;
  comments_count: number;
  is_direct_post: string | null;
  created_at: string;
  account: AccountAttributes;
  account_profile: AccountProfile;
  model_name: string;
  poll: Poll;
  setting: Setting;
  files: FileType[];
  thumbnail: string | null;
  poll_options: PollOption[];
  comments: Comments;
  reviews: any[];
  is_liked: boolean | null;
  views_count: number;
}

interface PostsEU {
  id: string;
  type: string;
  attributes: Attributes;
}

interface TrendingUser {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: number | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  full_name: string | null;
  stripe_id: string | null;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  role_id: number | null;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  sign_up_step: number;
  business_website: string | null;
  zip_code: string | null;
  role_name: string | null;
  pin: string | null;
  valid_until: string | null;
  working_hours: any[]; // Adjust type if needed
  holiday_hours: any[]; // Adjust type if needed
  holiday_hours_activated: boolean;
  is_accepted_privacy_policy: boolean;
  ip_address: string | null;
  privacy_policy_accepted_at: string | null;
  social_phone_number: string | null;
  is_first_login: boolean;
  widget_code: string;
  is_chat_clicked: boolean | null;
  time_zone: string | null;
}


export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}
interface S {
  personalities: string[];
  name:string;
  email:string;
  currentcity:string;
  currentstate:string;
  aboutprofile:string;
  isLoading:boolean;
  profileImage:any;
  userToken:string | null;
  hoverImg: boolean;
  isShowAlert: boolean;
  alertType: "success" | "error"
  showSucess:string;
  isProfileLoading:boolean;
  selectedFile: any,
  selectedProfileImage: any,
  reels: {
    id: number;
    thumbnailUrl: string;
    tag?: 'Trending' | 'Newest';
    views: number;
  }[];
  currentSlide: number;
  brandsIFollow: Brand[];
  suggestedBrands: Brand[];
  top6BrandProfile: IBrandDetails[]
  profileDataEU: ProfileEU;
  profileReview:any;
  followers:number;
  following:number;
  posts:any;
  profile:any;
  followersData:any,
  userDetails:any
  emailError: string
  isEndUser: boolean;
  postsDataEU: PostsEU[];
  userID: string | null;
  isFollowing: boolean;
  selfProfile: string
  isProfileDialog: boolean
  selectedImageToSet: File | null,
  circularLoader : boolean,
  circularLoaderEu : boolean
  swiperInstance: SwiperCore | null
  isPromptDialog: boolean
  promptQuestion: string
  promptAnswer: string
  promptAnswerError: boolean
  selectedPrompts: ISelectedPrompt[];
  trendingProfiles: Brand[];
}
interface SS {
  id: any;
}

export default class UserProfileController extends BlockComponent<Props, S, SS> {

  userProfileGetApiId: any = "";
  updateprofileapiId: any = "";
  updateImgProfileApiId : any = ""
  euProfileBrandsApiCallId: string ="";
  topTrendingProfilesEU: string = "";
  euProfileApiCallId: string = "";
  userDetailsApiCallId: any = ""
  followersApiCallId: any = ""
  trendingApiCallId: string = "";
  swiper: SwiperCore | null = null;
  followBrandProfileApiCallId: string = ""


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
  ];

    this.state = {
      personalities: this.shuffleQuestions(QUESTIONS),
      name:"",
      email:"",
      currentcity:"",
      currentstate:"",
      aboutprofile:"",
      userToken:this.userTokenVal,
      isLoading:true,
      profileImage:"",
      hoverImg: false,
      isShowAlert: false,
      alertType: "success",
      showSucess:"",
      isProfileLoading:false,
      selectedFile:null,
      emailError: "",
      selectedProfileImage: "",
      reels: [
        { id: 1, thumbnailUrl: thumbnail, tag: 'Trending', views: 1050 },
        { id: 2, thumbnailUrl: thumbnail, views: 5000 },
        { id: 3, thumbnailUrl: thumbnail, tag: 'Trending', views: 975 },
        { id: 4, thumbnailUrl: thumbnail, tag: 'Newest', views: 450 },
        { id: 5, thumbnailUrl: thumbnail, views: 50 },
        { id: 6, thumbnailUrl: thumbnail, views: 50 },
        { id: 7, thumbnailUrl: thumbnail, views: 456 },
        { id: 8, thumbnailUrl: thumbnail, views: 368 },
        { id: 9, thumbnailUrl: thumbnail, views: 368 },
      ],
      currentSlide: 0,
      brandsIFollow: [],
      suggestedBrands: [],
      top6BrandProfile: [],
      profileDataEU: {
        id: null,
        country: null,
        city: null,
        postal_code: null,
        account_id: null,
        personality_prompt: [],
        headline: null,
        name: "",
        email: null,
        reviews: 0,
        average_rating: 0,
        photo: null,
        followers: 0,
        following: 0
      },
      profileReview: {},
      followers:0,
      following:0,
      posts:[],
      profile:[],
      followersData: [],
      userDetails:[],
      isEndUser: false,
      postsDataEU: [],
      userID: null,
      isFollowing: false,
      selfProfile: "false",
      isProfileDialog: false,
      selectedImageToSet: null,
      circularLoader: false,
      circularLoaderEu: false,
      swiperInstance: null,
      isPromptDialog: false,
      promptQuestion: "",
      promptAnswer: "",
      promptAnswerError: false,
      selectedPrompts: [],
      trendingProfiles: []
    };
  
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    let euToken = await getStorageData("EUAuthToken");
    const id = this.props.navigation.getParam('navigationBarTitleText')!
    this.setState({userID: id})
    this.setState({userToken: euToken || this.state.userToken, isEndUser: !!euToken})
    this.user_profile_api()
    setTimeout(() => {
      this.setState({
        isLoading:false
      })
    }, 7000);
    this.getUserDetails(id);
    this.getFollowers(id)
    if(this.state.isEndUser || euToken){
      this.getSuggestedAndFollowBrand();
      this.getEUProfileData(id);
    }
    this.getTrendingUsers();
  }

  shuffleQuestions = (questions: string[]): string[] => {
    const seed = Date.now();
    
    return [...questions].sort((a, b) => {
        const hashA = [...a].reduce((acc, char, idx) => acc + (char.charCodeAt(0) * (idx + 1) * seed), 0);
        const hashB = [...b].reduce((acc, char, idx) => acc + (char.charCodeAt(0) * (idx + 1) * seed), 0);
        return (hashA % questions.length) - (hashB % questions.length);
    });
}

  componentDidUpdate(prevProps :Props, prevState: S) {
    if (prevState.userToken !== this.state.userToken) {
      this.user_profile_api()
    }
    const id = this.props.navigation.getParam('navigationBarTitleText')!
    if(prevState.userID && prevState.userID !== id ){
      this.setState({userID: id})
      if(this.state.isEndUser){
        this.getEUProfileData(id);
      }
      else{
        this.getUserDetails(id)
        this.getFollowers(id);
      }
    }

    if(prevState.email !== this.state.email) {
      this.setState({ emailError: this.validateEmail() })
    }
  }

  openPromptDialog = (promptQuestion: string) => {
    const { selectedPrompts } = this.state
    const addedPrompt = [...selectedPrompts].find((prompt) => prompt.question === promptQuestion)
    if(selectedPrompts.length > 2 && !addedPrompt) {
      this.setState({
        isShowAlert: true,
        showSucess: "You can't choose more than 3 personality prompt",
        alertType: "error"
      }, () => {
        this.isShowCell()
      })
      return
    }
    this.setState({
      isPromptDialog: true,
      promptQuestion,
      promptAnswer: addedPrompt ? addedPrompt.answer : ''
    })
  }

  closePromptDialog = () => {
    this.setState({
      isPromptDialog: false,
      promptQuestion: "",
      promptAnswer: ""
    })
  }

  isPromptAlreadyAdded = (promptQuestion: string) => {
    return this.state.selectedPrompts.some((prompt) => prompt.question === promptQuestion)
  }

  handleAddPrompt = () => {
    const { promptQuestion, promptAnswer, selectedPrompts } = this.state
    if(!promptAnswer) {
      this.setState({ promptAnswerError: true })
      return
    }
    const existingPromptIndex = selectedPrompts.findIndex(
      (prompt: ISelectedPrompt) => prompt.question === promptQuestion
    )
  
    if (existingPromptIndex !== -1) {
      const updatedPrompts = [...selectedPrompts]
      updatedPrompts[existingPromptIndex].answer = promptAnswer
  
      this.setState({ selectedPrompts: updatedPrompts })
    } else {
      const currentPrompt: ISelectedPrompt = {
        question: promptQuestion,
        answer: promptAnswer
      }
  
      const updatedSelections = [...selectedPrompts, currentPrompt]
      this.setState({ selectedPrompts: updatedSelections })
    }
  
    this.closePromptDialog()
  }

  handlePromptAnswerChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target
    this.setState({ promptAnswer: value, promptAnswerError: false })
  }

  removeSelectedPrompt = (event: React.MouseEvent<HTMLButtonElement>, question: string) => {
    event.stopPropagation()
    const updatedSelection = this.state.selectedPrompts.filter((prompt) => prompt.question !== question)
    this.setState({ selectedPrompts: updatedSelection })
  }

  handleSlideChange = (swiper: SwiperCore) => {
    this.setState({ swiperInstance: swiper });
  }

  renderPrompts = (prompts: any[]) => {
    const { classes } = this.props;
  
    return (
      <Grid container spacing={2}>
        {prompts.map((prompt) => {
          const isAdded = this.isPromptAlreadyAdded(prompt)
          return (<Grid key={prompt} item xs={12} sm={6} md={4}>
            <Card
              data-test-id="prompt-card"
              className={clsx(classes.card, {
                [classes.selectedCard]: isAdded,
              })}
              onClick={() => this.openPromptDialog(prompt)}
            >
              <IconButton
                size="small"
                title="Remove prompt"
                hidden={!isAdded}
                data-test-id="remove-prompt"
                className={classes.promptRemove}
                onClick={(event) => this.removeSelectedPrompt(event, prompt)}
              >
                <CloseRounded fontSize="small" />
              </IconButton>
              <CardContent>
                <Typography variant="h6" component="div" className={classes.text}>
                  {prompt}
                </Typography>
              </CardContent>
            </Card>
          </Grid>)
        })}
      </Grid>
    )
  }  

  handleProfileEditRedirection = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfile");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  closeProfileImageSetDialog = () => {
    this.setState({
      isProfileDialog: false,
      selectedImageToSet: null
    })
  }

  validateEmail = () => {
    const {
      email
    } = this.state
    let error = ""
    if(!email) {
      error = "Email is required."
    } else if (!email.match(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    )) {
      error = "Invalid email address.";
    }
    return error
  }

  getEUProfileData = async(id : string | undefined) => {
    const EuToken = await getStorageData("EUAuthToken")
    this.setState({circularLoaderEu: true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.euProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      id ? configJSON.user_profile_view + `/${id}` : configJSON.endPointApiGetEUProfile
    ); 
    const header = {
      "Content-Type": configJSON.contentTypeApiGetEUProfile,
      token: EuToken
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetEUProfile
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSuggestedAndFollowBrand = async () => {
    const EuToken = await getStorageData("EUAuthToken")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.euProfileBrandsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetEUProfileBrands
    );
    const header = {
      "Content-Type": configJSON.contentTypeApiGetEUProfileBrands,
      token: EuToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetEUProfileBrands
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNextSlide = () => {
    if (this.swiper) {
      this.swiper.slideNext();
    }
  };

  handlePrevSlide = () => {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
  };

  onSlideChange = (swiper: SwiperCore) => this.setState({ currentSlide: swiper.activeIndex });
  onSwipe=(swiper:SwiperCore) => (this.swiper = swiper);

  userTokenVal= localStorage.getItem('authToken');
  handleFieldChange = (e:any) => {
    const { name, value }: { name: string; value: string } = e.target;
    let filterValue = value
    if (
      name === "name" ||
      name === "currentcity" ||
      name === "currentstate"
    ) {
      filterValue = value.replace(/[^a-zA-Z\s]/g, "");
    }
    this.setState({
        [name]: filterValue,
    } as unknown as Pick<S, keyof S>)

};

showHoverImg = () =>{
  this.setState({
      hoverImg: true,
    });
}
hideHoverImg = () =>{
  this.setState({
      hoverImg: false,
    });
}

handleProfileSetButtonClick = (file: Blob) => {
  this.setState({
    selectedFile: file,
    selectedProfileImage: URL.createObjectURL(file)
  })
  this.closeProfileImageSetDialog()
}

handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const selectedFile = event.currentTarget.files && event.currentTarget.files[0];
  this.setState({
    selectedImageToSet: selectedFile,
    isProfileDialog: true
  });

}
handleSaveChanges=()=>{
  const emailError = this.validateEmail()
  if(emailError) {
    return
  }

  if (this.state.selectedFile) {
    // If a new image is selected, upload it and then call the update_profile_api function
    const formData = new FormData();
    formData.append('photo', this.state.selectedFile);
    this.upload_image_api(formData)
   
  }
  
    this.update_profile_api()
  
}
async update_profile_api() {
  this.setState({ isLoading: true })
  const {
      name,
      email,
      currentcity,
      currentstate,
      aboutprofile,
      selectedPrompts
  } = this.state
  const attrs = {
      name,
      email,
      profile_attributes: {
        country: currentstate,
        city: currentcity,
        headline: aboutprofile,
        personality_prompt: [...selectedPrompts]
      },
  };
 
  const httpBody = {
      profile: attrs,
  };
  
  const headerData={
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token":this.state.userToken
  }
  let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateprofileapiId =  requestMessage.messageId;
  requestMessage.addData (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(headerData)
);
requestMessage.addData (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.update_profile
);
       requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.update_profileMethod,
);
   requestMessage.addData(
       getName(MessageEnum.RestAPIRequestBodyMessage),
       JSON.stringify(httpBody)
    );
runEngine.sendMessage(requestMessage.id, requestMessage);
return requestMessage.messageId;

}

async user_profile_api() {
  const headerData={
      "token":this.state.userToken,
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
  }
  let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.userProfileGetApiId =  requestMessage.messageId;
  requestMessage.addData (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(headerData)
);

       requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.user_profileMethod,
);
requestMessage.addData (
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.user_profile
);
runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;

}

async upload_image_api(formData:any) { 
  this.setState({ isProfileLoading: true,hoverImg:false });
  const headerData={
      "token":this.state.userToken,
  }
  let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateImgProfileApiId =  requestMessage.messageId;
  requestMessage.addData (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(headerData)
);
requestMessage.addData (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upload_image
);
       requestMessage.addData(
   getName(MessageEnum.RestAPIRequestMethodMessage),
   configJSON.update_profileMethod,
);
   requestMessage.addData(
       getName(MessageEnum.RestAPIRequestBodyMessage),
       formData
    );
runEngine.sendMessage(requestMessage.id, requestMessage);
return requestMessage.messageId;

}

apicallresponseData = (responseJson: any, apiRequestCallId: string) => {
   if(!(responseJson.error || responseJson.errors)) {
    if(apiRequestCallId === this.updateImgProfileApiId){
      this.update_profile_api()
    }
    this.setState({
      isLoading: false,
      name:responseJson.data.attributes.name,
      email:responseJson.data.attributes.email,
      aboutprofile:responseJson.data.attributes.headline,
      currentcity:responseJson.data.attributes.city,
      currentstate:responseJson.data.attributes.country,
      profileImage:responseJson.data.attributes.photo !== null ? responseJson.data.attributes.photo : "",
      isProfileLoading:false,
      selectedPrompts: responseJson.data.attributes.personality_prompt || []
    })
  }
}

handleFollowBrandApiSuccessResponse = (responseJson: IFollowBrandApiResponse) => {
  this.setState(prevState => ({...prevState, isFollowing: !prevState.isFollowing}))
  if(typeof responseJson === "object" && "data" in responseJson) {
    this.getSuggestedAndFollowBrand()
  }
}

isShowCell = () => {
  setTimeout(() => this.setState({ isShowAlert: false, alertType: "success" }), 3000);
}

handleCancleChanges = () =>{
  this.user_profile_api()
  
  this.setState({ isShowAlert: true,showSucess:"Changes Reverted Successfully!",
  name: "",  // Reset name field
    email: "", // Reset email field
    currentcity: "", // Reset currentcity field
    currentstate: "", // Reset currentstate field
    aboutprofile: "", // Reset aboutprofile field
    selectedProfileImage:""
 }, () => {
    this.isShowCell()
  })
  if(this.state.isEndUser) this.handleNavigateToProfileBack()
};

sendInfoToMainLayout = (data: any) => {
  const msg = new Message(getName(MessageEnum.ConnectTOSidebar))
  msg.addData(getName(MessageEnum.ShouldUpdate), data)
  this.send(msg)
}

//added the new API call for userDetails

getUserDetails = (id : string | undefined) => {

  const headers = {
    "Content-Type": configJSON.dashboarContentType,
    token: window.localStorage.getItem('authToken') || '',
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.userDetailsApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    id ? configJSON.user_profile_view + `/${id}` : configJSON.endPointApiProfileDashboard
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodApiProfileDashboard
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  this.setState({circularLoader: true})
  runEngine.sendMessage(requestMessage.id, requestMessage);

};
isVideo = (file: any) => {
  return (
    file.link.toLowerCase().endsWith(".mp4") || file.mime_type === "video/mp4"
  );
};
getFollowers = (id : string | undefined)=> {
  const headers = {
    token: window.localStorage.getItem('authToken') || '',
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.followersApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    id ? configJSON.endPointApiGetFollowers + `?id=${id}` : configJSON.endPointApiGetFollowers
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiGetFollowers
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiGetFollowers
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage)
};

followBrandApiProfile = async (followBrandId: string | null, isEndUser: boolean = true, isFollowing: boolean = false) => {
  const token = await getStorageData(isEndUser ? configJSON.EU_AUTH_TOKEN : configJSON.AUTH_TOKEN)
  const httpBody = {
    data:{
        attributes:{
            followed_user_id: followBrandId
        }
    }
  }
  const headerData = {
    token,
    "Content-Type": configJSON.contentTypeApiGetUserProfile,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.followBrandProfileApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headerData)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    isFollowing ? configJSON.methodTypeApiUnfollowUser : configJSON.httpPostMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    isFollowing ? configJSON.unfollowBrandApiEndpoint + followBrandId : configJSON.followBrandApiEndpoint
  );
  !isFollowing && requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
 )
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}


async receive(from: string, message: Message) {
  const messageType = getName(MessageEnum.RestAPIResponceMessage);
  
  if (messageType === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId == this.updateprofileapiId) {
        if (responseJson && !responseJson.errors) {
          this.sendInfoToMainLayout({refresh:true})
          this.setState({ isShowAlert: true,showSucess:responseJson.meta.message }, () => {
            this.isShowCell()
          })
          this.handleNavigateToProfileBack()
        } else if(responseJson.errors.length > 0) {
          const firstError = responseJson.errors[0];
          const key = Object.keys(firstError)[0];
          const message = firstError[key]
          this.setState({
            isShowAlert: true,
            showSucess: message,
            alertType: "error",
            isLoading: false
          },
            () => {
              this.isShowCell()
          })
        }
      }else if (apiRequestCallId === this.euProfileBrandsApiCallId) {
        this.setState({
          brandsIFollow: responseJson.followings.data,
          suggestedBrands: responseJson.suggested_brands.data,
          top6BrandProfile: responseJson.top6.data
        })
      }else if (apiRequestCallId === this.euProfileApiCallId) {
        this.setState({circularLoaderEu: false})
        this.setState({profileDataEU: responseJson.data.profile.data.attributes, postsDataEU: responseJson.data.posts.data, isFollowing:responseJson.data?.following_status, selfProfile: responseJson.data?.self_profile})
      } else if (apiRequestCallId === this.userDetailsApiCallId) {
        this.setState({circularLoader: false})
        this.setState({userDetails:responseJson, isFollowing:responseJson.data?.following_status, selfProfile: responseJson.data?.self_profile});
      } else if (apiRequestCallId === this.followersApiCallId) {
        this.setState({ followersData:responseJson});
      } else if (apiRequestCallId === this.trendingApiCallId){
        this.setState({trendingProfiles: responseJson.trending_users.data})
      }

      const idToFunctionMapping = {
          [this.userProfileGetApiId]:this.apicallresponseData,
          [this.updateprofileapiId]:this.apicallresponseData,
          [this.updateImgProfileApiId]:this.apicallresponseData,
          [this.followBrandProfileApiCallId]: this.handleFollowBrandApiSuccessResponse
      };

      if (apiRequestCallId != null && idToFunctionMapping.hasOwnProperty(apiRequestCallId)) {
          const correspondingFunction = idToFunctionMapping[apiRequestCallId];
          correspondingFunction.call(this, responseJson, apiRequestCallId);
      }
  }

}

  handleNavigateEditProfile = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EndUserProfile");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.EndUserSignupMessage)
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  handleNavigateToProfileBack = () => {
    const { isEndUser } = this.state
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), isEndUser ? "EuProfile" : "UserProfileBu");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.EndUserSignupMessage)
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  getTrendingUsers = () => {
    const headers = {
      token: window.localStorage.getItem('authToken') || '',
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.trendingApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_analytics/trending_users_bu");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    runEngine.sendMessage(requestMessage.id, requestMessage)

  }
}
// Customizable Area End
