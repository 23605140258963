import React from 'react';
import NavigationMenuEUWeb from '../../../components/src/NavigationMenuEU.web';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import SuggestedBrands from '../../../components/src/SuggestedBrands';
import { Swiper, SwiperSlide } from 'swiper/react';
import MobileNavEndUser from '../../dashboard/src/MobileNavEndUser.web';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import {
  createStyles,
  styled,
  Theme,
  withStyles
} from "@material-ui/core/styles";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import UserProfileController from './UserProfileController.web';
import CustomLoader from '../../../components/src/CustomLoader.web';
import CustomButton from '../../../components/src/CustomButton.web';
import { nextSliderArrow } from '../../dashboard/src/assets';
import TopActiveUsersEU from '../../dashboard/src/TopActiveUsersEu.web';
import zIndex from '@material-ui/core/styles/zIndex';

export class EuuserProfile extends UserProfileController {
  render() {
    const { classes, navigation }: any = this.props;
    const { top6BrandProfile, profileDataEU } = this.state

    return (
      <RootContainer>
        <NavigationMenuEUWeb brands={this.state.brandsIFollow}/>
        {this.state.circularLoaderEu ? <CustomLoader loaderSize={24} isLoading={this.state.circularLoaderEu}/> : 
        <div className={classes.profileContentWrapper} >
          <Box paddingX={3} className='asdf'>
            <Grid  container spacing={3}>
              <Grid item xs={12} sm={7} md={6} lg={8} style={{padding: "0px"}}>
                <Box  className={classes.ebEuProfileContainer}>
                  <Box className={classes.imageOverlay}>
                    <Box className={classes.profileImageContainer}>
                      {this.state.profileDataEU.photo && <img 
                        className={classes.image} 
                        src={this.state.profileDataEU.photo} 
                        alt="Profile Slide 1"
                      />}                    
                    </Box>
                  </Box>
                  {!this.state.userID && <Box className={classes.editIconContainer} data-test-id={"eu-profile-edit-btn"} onClick={()=>this.handleNavigateEditProfile()}>
                      <EditOutlinedIcon />
                  </Box>}
                  <Box className={classes.detailsContainer}>
                    <Box className='residenceTextContainer'>
                      <Typography variant="body2" className='residenceText'>{`${this.state.profileDataEU.city || ""}${this.state.profileDataEU.city ? "," : ""} ${this.state.profileDataEU.country || ""}`}</Typography>
                    </Box>
                    <Typography className='nameText' variant="h3" style={{fontWeight: 600}}>{this.state.profileDataEU.name || ""}</Typography>
                    <Typography variant="body2" className='headline'>{this.state.profileDataEU.headline || ""}</Typography>
                    {this.state.userID && this.state.selfProfile === "false" && <Button variant="contained" data-test-id="user-follow-btn" color="primary" className={classes.followBtn} onClick={()=>this.followBrandApiProfile(this.state.userID, true, this.state.isFollowing)}>{this.state.isFollowing ? "Following" : "Follow"}</Button>}
                  </Box>
                  <Box className={classes.followContainer}>
                    <Box>
                      <Typography variant="h4" className="followersFollowingNumber">{this.state.profileDataEU.followers}</Typography>
                      <Typography variant="h6" className={classes.fontWeight}>Followers</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h4" className="followersFollowingNumber">{this.state.profileDataEU.following}</Typography>
                      <Typography variant="h6" className={classes.fontWeight}>Following</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                {profileDataEU.personality_prompt.length > 0 ?
                  <Box className={classes.ebSwiperBox} data-test-id="swiperContainer">
                    <div className={classes.swiperLeftBtn}>
                      <img src={nextSliderArrow} className="swiper-button-prev" style={{ height: "20px", width: "20px", color: "#334155", cursor: "pointer", transform: "rotate(180deg)" }} />
                    </div>
                  <Swiper
                      onSwiper={this.onSwipe}
                      onSlideChange={this.onSlideChange}
                      slidesPerView={1}
                      navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                      }}
                    >
                      {profileDataEU.personality_prompt.map((presonalityPompt, index) => (
                        <SwiperSlide className={classes.ebSwiperSlideContainer}>
                        <Box sx={{ position: 'relative' }}>
                          <Box className={classes.sliderBottomContainer} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                            <Box>
                            <Typography variant="h5" className={classes.subHeading1}>
                              {presonalityPompt.question}
                            </Typography>
                            <Typography variant="h5" className={classes.subHeading2}>
                              {presonalityPompt.answer}
                            </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className={classes.swiperRightBtn}>
                      <img src={nextSliderArrow} className="swiper-button-next" style={{ height: "20px", width: "20px", color: "#334155", cursor: "pointer" }} />
                    </div>
                  </Box>
                  :
                  <Box className={classes.addPersonalityWrapper}  style={ this.state.userID ? {display: "none"} : {padding: "15px 20px"}}>
                    <CustomButton onClick={this.handleNavigateEditProfile}>
                      Add Some Personality
                    </CustomButton>
                  </Box>
                }
                </Box>
                <Box>
                    <ul className={classes.slidesDotsUl}>
                    {this.state.profileDataEU.personality_prompt.map((presonalityPompt, index) => (<li className={index === this.state.currentSlide && classes.slidesDotsColor}>
                    </li>))}
                    </ul>
                  </Box>
                <Box className={classes.top6MobileView}>
                  <TopActiveUsersEU
                      navigation={navigation}
                      top6={top6BrandProfile}
                    />
                </Box>
                <Box style={{ padding: 2 }}>
                  <Typography variant='h5' className={classes.postsHeading}>Posts</Typography>
                  <Grid container spacing={2} key={"reelContainer"}>
                    {this.state.postsDataEU.map((reel, index) => (
                      <Grid item xs={4} key={reel.id} spacing={2} style={{padding: '5px 3px'}}>
                        <Box className={classes.postThumbnailBox}>
                          <img
                            src={reel.attributes.thumbnail || `https://picsum.photos/id/${index + 10}/200/300`}
                            alt={`Post ${reel.id}`}
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                          <Box
                            style={{
                              position: 'absolute',
                              bottom: 6,
                              left: 10,
                              color: '#fff',
                              borderRadius: "100%",
                              fontWeight: 700,
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                              fontSize: "10px"
                            }}
                          >
                            <p>&#9654;</p>
                            <Typography style={{paddingTop: "4px", fontSize: "10px", fontWeight: 700}}>{reel.attributes.views_count}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item sm={5} md={4} xs={12} className={classes.top6AndFollowContainer}>
                <div>
                  <TopActiveUsersEU
                    navigation={navigation}
                    top6={top6BrandProfile}
                  />
                  <SuggestedBrands
                    brands={this.state.suggestedBrands}
                    followBrand={this.followBrandApiProfile}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>}
        <div>
        <MobileNavEndUser navigation={navigation} />
      </div>
      </RootContainer>
    );
  }
}

const RootContainer = styled(Box)(({theme}) => ({
  background: "#f1f4f9",
  [theme.breakpoints.down(768)]: {
    background: "#fff"
  },
}))

const styles = (theme: Theme) => createStyles({
  profileContentWrapper: {
    margin: "15px auto",
    maxWidth: "1240px",
    width: "96%",
    paddingBottom: "80px",
    '@media (max-width: 768px)': {
      '& .asdf': {
        paddingInline: '0px !important'
      }
    }
  },
  top6AndFollowContainer: {
    paddingTop: "0px !important",
    [theme.breakpoints.down(601)]: {
      display: 'none'
    }
  },
  ebSwiperBox: {
    position: "relative",
    height: "auto",
    backgroundColor: "#fff",
    "& .swiper-wrapper": {
      height: "auto"
    },
    width: "auto",
  },
  textIssuesCheck:{
    maxWidth: '100% !important',
    padding: '0px',

  },
  ebEuProfileContainer: {
    position: "relative",
    height: "auto",
    minWidth: "100%",
    margin: "0 auto",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  addPersonalityWrapper: {
    backgroundColor: "#fff",
    padding: "10px",
    minWidth: '96%'
  },
  ebSwiperSlideContainer: {
    width: "auto",
    height: "auto",
    "& img": {
      width: "100%",
      height: "500px",
      objectFit: "cover"
    },
  },
  imageOverlay: {
    position: "relative",
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: '0px',
      minWidth: '100%'
    },
    "&::before": {
      content: '""',
      display: 'block',
      background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 29.36%, rgba(0, 0, 0, 0.8) 116.29%)",
      position:'absolute',
      top:0,
      left:0,
      right:0,
      bottom:0
    }
  },
  top6MobileView:{
    [theme.breakpoints.up(600)]: {
      display: "none"
    },
  },
  profileImageContainer:{
    width: "100%",
    height: "451px",
    borderRadius: "20px 20px 0px 0px",
    background: "#FFC629",
    "@media (max-width: 768px)":{
      height: "276px",
    }
  },
  image: {
    width: '100%',
    height: "100%",
    objectFit: "cover",
    padding: '0px',
    borderRadius: "20px 20px 0px 0px",
  },
  fontWeight: {
    fontWeight: 700,
    fontSize: '12px',
    // marginLeft: '-20px',
    "@media (max-width: 320px)":{
     
    }
  },
  detailsContainer: {
    position: 'absolute', bottom: 35, left: 50,
    color: "#fff",
    display: "flex", 
    flexDirection: "column",
    gap:"5px",
    "& .nameText":{
      fontSize: "30px",
      [theme.breakpoints.down(960)]: {
        fontSize: "20px",
      },
    },
    "& .headline":{
      width: "90%"
    },
    "& .residenceTextContainer":{
      background: "#00000080",
      paddingLeft: "12px",
      paddingRight: "12px",
      borderRadius: "20px",
      textAlign: "start",
      display: "inline-block",
      maxWidth: "max-content"
    },
    "@media (max-width: 768px)":{
        fontSize: '10px',
        '& .text-centerChange': {
          fontSize: 16
        }
    },
    [theme.breakpoints.down(768)]: {
      width: "50%",
    },
    [theme.breakpoints.down(960)]: {
      left: "20px"
    },
  },
  editIconContainer: {
    "& svg": {
      position: 'absolute',
      top: 10,
      right: 10,
      color: "#fff",
      width: "20px",
      height: "20px",
      background: "#0F172A",
      borderRadius: "8px",
      textAlign: "center",
      padding: "5px"
    }
  },
  followBtn: {
    width: "120px",
    background:'#FFC629',
    color:'#000',
    fontWeight:700,fontSize:'16px',
    borderRadius: "50px",
    textTransform: "capitalize",
    padding: "5px 35px 5px 35px",
    marginTop: "10px",
    "&:hover": {
      background:'#FFC629',
      color:'#000',
    },
    "& .MuiButton-label":{
      padding: "4px 0 0"
    }
  },
  followContainer:{
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(2),
    textAlign: "center",
    marginBottom: "18px",
    "& .followersFollowingNumber":{
      fontSize: "30px",
      fontWeight: 700,
    },
    position: 'absolute',
    bottom: 35, 
    right: 50,
    alignItems:'center',
    gap:'20px',
    color: "#fff",
    [theme.breakpoints.down(960)]: {
      right: 20
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      bottom: "14px"
    },
    '@media (max-width: 768px)': {
        display: 'flex',
        flexDirection: 'row',
        fontSize:'12px',
        marginRight: '-20px'
    },
    '@media (max-width: 320px)': {
      display: 'flex',
      flexDirection: 'row',
      fontSize:'10px',
      marginRight: '-60px'
  }
  },
  sliderBottomContainer: {
    padding: "20px 40px",
    [theme.breakpoints.down(650)]: {
      padding: "4px 20px"
    },
    "& .swiper-button-next ": {
      border: "2px solid",
      padding: "22px",
      zIndex: 4
    },
    "& .swiper-button-next::after": {
      fontSize:"24px",
      fontWeight: "600"
    },
    "& .swiper-button-prev": {
      border: "2px solid",
      padding: "22px",
      zIndex: 4
    },
    "& .swiper-button-prev::after": {
      fontSize:"24px",
      fontWeight: "600"
    }
  },
  subHeading1: {
    fontWeight: 600,
    color: "#FFC629",
    fontSize: "20px",
    [theme.breakpoints.down(950)]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down(650)]: {
      fontSize: "12px",
    },
  },
  subHeading2: {
    fontWeight: 600,
    color: "#000",
    [theme.breakpoints.down(950)]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down(650)]: {
      fontSize: "12px",
    },
  },
  slidesDotsUl: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px",
    backgroundColor: "#fff",
    "& li": {
      margin: "0px 15px",
      fontSize: "32px",
    },
  },
  slidesDotsColor: {
    color: "#FFC629 !important"
  },
  postsHeading: {
    fontFamily: "Inter",
    // fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    // textAlign: "left",
    margin: "20px",
    color: "#0F172A",
    [theme.breakpoints.down(650)]: {
      fontSize: "16px"
    },
  },
  postThumbnailBox: {
    position: 'relative',
    overflow: 'hidden',
    height: "330px",
    [theme.breakpoints.down(650)]: {
      height: "185px"
    },
  },
  swiperBtn: {
    border: "2px solid",
  padding: "22px",
  "&::after": {
    fontSize:"24px",
    fontWeight: "600"
  }
  },
  swiperLeftBtn: {
    position: "absolute",
    top: "50%",
    [theme.breakpoints.down(650)]: {
      display: "none"
    },
  },
  swiperRightBtn:{
    position: "absolute",
    top: "50%",
    right: "0",
    [theme.breakpoints.down(650)]: {
      display: "none"
    },
  }
});

export default withStyles(styles)(EuuserProfile);