import React from 'react';
import TopActiveUsersController from "./TopActiveUsersController.web";
import { Avatar, Box, Grid, Paper, styled, Typography } from "@material-ui/core";
import { smileYellow } from './assets';
import { NavLink, Link } from "react-router-dom";

class TopActiveUsersEU extends TopActiveUsersController {

  renderProfilePic(profilePic: any){
    if(profilePic){
      return <BrandImage src={profilePic} alt="Brand Logo" />;
    } else {
      return <BrandImage src={smileYellow} alt="Brand Logo" />
    }
  }

  render() {
    const { trendingProfiles } = this.state;

    return <MainPaper data-test-id="mainPaper">
        <DesktopFollowersView>
          <MainHeading data-test-id="main-heading" gutterBottom variant="subtitle2">
            Your top 6 brands
          </MainHeading>
          <ContentContainer container spacing={2} style={{ margin: 0, width: "100%" }}>
            {trendingProfiles.length > 0 ? (
              trendingProfiles.map((brand) => {
                const { id } = brand;
                const { first_name, last_name, profile_pic } = brand.attributes;
                const fullName = `${first_name} ${last_name}`;

                return (
                  <ProfileContainer key={id} item xs={4} sm={4} md={4} onClick={() => this.handleProfileRedirect(id.toString())} data-test-id="profile-wrapper">
                    <Link to={`/profile/${brand.id}`}>
                      <BrandAvatar>
                        {this.renderProfilePic(profile_pic?.url)}
                      </BrandAvatar>
                    </Link>
                    <BrandName title={fullName}>{fullName}</BrandName>
                  </ProfileContainer>
                );
              })
            ) : (
              <EmptyWrapper>
                <Typography className='noDataText'>No data available</Typography>
              </EmptyWrapper>
            )}
          </ContentContainer>
        </DesktopFollowersView>
    </MainPaper>
  }
}

const MainPaper = styled(Paper)(({theme}) => ({
  width: '100%',
  boxShadow: "none",
  '@media (max-width: 768px)': {
    width: 'unset'
  },
  [theme.breakpoints.down(950)]: {
    padding: 0
  },
}))

const DesktopFollowersView = styled(Box)({
  noDataText:{
    color: "#999",
    fontSize: "14px" ,
    textAlign: "center"
  }
})

const MainHeading = styled(Typography)(({theme}) => ({
  padding: "16px",
  color: "#0F172A",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "22px",
  textAlign: "start",
  fontSize: "18px",
  [theme.breakpoints.down(960)]: {
    padding: "8px 16px",
    flexWrap: 'wrap',
    fontSize: "12px"
  },
}))

const ContentContainer = styled(Grid)(({theme}) => ({
  padding: theme.spacing(2),
  paddingTop: "4px",
  display: "flex",
  flexWrap: 'wrap',
  width: '100%'
}))

const ProfileContainer = styled(Grid)({
  cursor: "pointer",
  placeItems:"center",
  overflow:"hidden",
  display:"grid",
})

const BrandAvatar = styled(Avatar)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  border: '4px solid #FFC629',
  cursor: "pointer",
  backgroundColor: '#FFFFFF',
  width: theme.spacing(7),
  height: theme.spacing(7),
  [theme.breakpoints.down(950)]: {
    marginTop:"12px"
  },
}))

const EmptyWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "150px",
  width: "100%",
  textAlign: 'center',
  color: "#999",
  fontSize: '14px',
})

const BrandName = styled(Typography)({
  textAlign: "center",
  fontStyle: "normal",
  overflow: 'hidden',
  width: "100%",
  lineHeight: "22px",
  fontFamily: "Inter",
  fontSize: "10px",
  whiteSpace: 'nowrap',
  color:"#0F172A",
  textOverflow: 'ellipsis',
  fontWeight: 700,
  textDecoration: "none"
})

const BrandImage = styled('img')({
  width: '56px',
  objectFit:'cover',
  height: '56px',
})




export default TopActiveUsersEU;