// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { allMessagesIcon } from "./assets";
import { conversationType } from "./constants";
import { ChatNavIcon } from "../../dashboard/src/assets";
import { createRef } from "react";

export const configJSON = require("./config");

const automationsData = [
  {
    name: "Contact First Name",
    type: " :contact_first_name: ",
  },
  {
    name: "Contact Full Name",
    type: " :contact_full_name: ",
  },
  {
    name: "Business Name",
    type: " :business_name: ",
  },
  {
    name: "Business Phone",
    type: " :business_phone: ",
  },
  {
    name: "Review Link",
    type: " :review_link: ",
  },
  {
    name: "Friend Invite Link",
    type: " :friend_invite_link: ",
  },
  {
    name: "Home Feed Highlight",
    type: " :new_post_highlight: ",
  },
  {
    name: "Invite Contact to Post",
    type: " :create_post_invite: ",
  },
];

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  chatType: string;
  chatCircle: any;
  text: any;
  chat: any;
  directPost: any;
  allMessages: any;
  allChatsArray: any;
  conversations: any;
  uniqueDates: any;
  userFolders: any;
  isNewMessage: boolean;
  userContacts: any;
  showContcatCard: boolean;
  selectedConversation: any;
  selectedContact: any;
  newMessage: string;
  response: any;
  anchorEll: any;
  userItem: any;
  teammates: any;
  teammatesModal: boolean;
  userDetails: any;
  automationModal: any;
  automationContent: any;
  newContactModal: boolean;
  showAlert: boolean;
  errorAlertMessage: boolean;
  showSuccess: any;
  messageOptionsPopover: any;
  conversationNewData: any;
  conversationDataMain: any;
  chatTypeAPI: string;
  autoamtionFilterData: any;
  automationSearchQuery: string;
  messageSearchQuery: string;
  chatSelectedContact: any;
  isLoading: boolean;
  openInfoModal: boolean;
  newOrOldUserSelected: boolean;
  tempAddingNewContact: any;
  isLeftSideVisibleForMobileview: boolean;
  isCenterSideVisibleForMobileView: boolean;
  isRightSideVisibleForMobileView: boolean;
  isDocCameraAutoVisible: boolean;
  addNewMessageMobile: boolean;
  createNewContactMobile: boolean;
  isLoaderShow: boolean;
  selectedConversationForPopover: any;
  filterAnchorEl: any;
  selectedFilterName: string;
}
interface SS {
  id: any;
}
export default class MessagesController extends BlockComponent<Props, S, SS> {
  getUserChatsApiId: any = "";
  getFoldersApiId: any = "";
  createFoldersApiId: any = "";
  getContactsApiId: any = "";
  getDirectPostApiId: any = "";
  getDirectPostDetailApiId: any = "";
  newMessageApiId: any = "";
  newConversationApiId: any = "";
  deleteFolderApiId: any = "";
  getTeamMatesApiId: any = "";
  updateFolderApiId: any = "";
  userDetailsApiCallId: any = "";
  addNewContactsApiCallId: any = "";
  addNewChatContactsApiCallId: string= "";
  filterMessagesApiCallId: any = "";
  cameraAttachmentsApiCallId: any = "";
  getConversationUserId: any = "";
  lastMessageRef: any = createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.handleClosePopOver = this.handleClosePopOver.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.receive = this.receive.bind(this);
    this.getUserChats = this.getUserChats.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMobileData),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];

    this.state = {
      chatType: "All Messages",
      chatTypeAPI: "All Messages",
      chatCircle: allMessagesIcon,
      text: {},
      uniqueDates: [],
      allMessages: {},
      chat: {},
      directPost: {},
      conversations: {},
      userFolders: [],
      isNewMessage: false,
      userContacts: [],
      showContcatCard: false,
      selectedConversation: {},
      selectedContact: {},
      allChatsArray: [],
      newMessage: "",
      response: [],
      anchorEll: null,
      userItem: {},
      teammates: [],
      teammatesModal: false,
      automationModal: null,
      automationContent: automationsData,
      newContactModal: false,
      showAlert: false,
      errorAlertMessage: false,
      showSuccess: "",
      userDetails: {
        attributes: {
          first_name: "",
          last_name: "",
        },
      },
      messageOptionsPopover: null,
      conversationNewData: [],
      conversationDataMain: [],
      autoamtionFilterData: automationsData,
      automationSearchQuery: "",
      messageSearchQuery: "",
      chatSelectedContact: [],
      isLoading: false,
      openInfoModal: false,
      newOrOldUserSelected: false,
      tempAddingNewContact: {},
      isLeftSideVisibleForMobileview: false,
      isCenterSideVisibleForMobileView: true,
      isRightSideVisibleForMobileView: false,
      isDocCameraAutoVisible: false,
      addNewMessageMobile: false,
      createNewContactMobile: false,
      isLoaderShow: false,
      selectedConversationForPopover: {},
      filterAnchorEl: null,
      selectedFilterName: "All Messages",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const messageType = getName(MessageEnum.RestAPIResponceMessage);

    if (messageType === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleReceive(apiRequestCallId, responseJson);
    }
  }

  handleReceive(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getUserChatsApiId) {
      this.handleChatResponse(responseJson);
    }
    if (apiRequestCallId === this.getFoldersApiId) {
      this.getTeammates();
      this.setState({ userFolders: responseJson.data });
    }
    if (apiRequestCallId === this.getTeamMatesApiId) {
      this.getUserDetails();
      this.setState({ teammates: responseJson.data });
    }
    if (apiRequestCallId === this.createFoldersApiId) {
      this.getFolders();
    }
    if (apiRequestCallId === this.getContactsApiId) {
      this.handleGetContactResponse(responseJson);
    }
    if (apiRequestCallId === this.newConversationApiId) {
      this.sendNewMessage();
    }
    if (apiRequestCallId === this.newMessageApiId) {
      this.setState({ newMessage: ""});
      this.getConversation(this.state.selectedConversation);
    }
    if (apiRequestCallId === this.deleteFolderApiId) {
      this.handleClosePopOver();
      this.getFolders();
    }
    if (apiRequestCallId === this.updateFolderApiId) {
      this.closeModal();
      this.handleClosePopOver();
      this.getFolders();
    }
    if (apiRequestCallId === this.userDetailsApiCallId) {
      this.setState({ userDetails: responseJson.account.data });
    }

    if (apiRequestCallId === this.addNewContactsApiCallId) {
      this.handleAddNewContactResponse(responseJson);
    }

    if (apiRequestCallId === this.filterMessagesApiCallId) {
      this.handleFilterApiResponse(responseJson);
    }

    if (apiRequestCallId === this.getConversationUserId) {
      this.setState({ chatSelectedContact: responseJson, isLoading: false});
    }
    if(apiRequestCallId === this.addNewChatContactsApiCallId){
      this.filterMessages('chat_contacts')
    }
  }
  
  handleFilterApiResponse = (responseJson: any) => {
    let data;
    if (this.state.chatTypeAPI === "direct_post_contacts") {
      data = responseJson.direct_post_contacts.data;
    } else {
      data = responseJson.data;
    }
    
    let pinSortedData =  data.sort((a: any, b: any) => {
      return b.attributes.pin_conversation - a.attributes.pin_conversation;
    });
    this.setState({ conversationNewData: this.groupAndSortByDateChat(data), conversationDataMain: data, isLoaderShow: false });

    if(this.state.isNewMessage && Object.keys(this.state.tempAddingNewContact).length != 0){
      this.setState({
        selectedConversation: data.find((item: any) => item.id === this.state.tempAddingNewContact.attributes.id),
        isNewMessage: false,
        newOrOldUserSelected: true})
    }
    
  };

  handleInfoModalChange = () => {
    this.setState(prevState => ({...prevState, openInfoModal: !prevState.openInfoModal}))
  }

  formatDateTime(dateStr: string | undefined): string {
    if(!dateStr){
      return "N/A"
    }
    const date = new Date(dateStr);
    
    // Get today's date and yesterday's date
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Function to format the time to AM/PM
    const formatTime = (date: Date): string => {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };

    // Check if the date is today or yesterday
    if (date.toDateString() === today.toDateString()) {
        return `Today at ${formatTime(date)}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
        return `Yesterday at ${formatTime(date)}`;
    } else {
        // Format the date as MM/DD/YYYY
        const formattedDate = date.toLocaleDateString('en-US');
        return `${formattedDate} at ${formatTime(date)}`;
    }
}

  handleMessageOptions = (e: any, value: any) => {
    e.stopPropagation();
    this.setState({
      messageOptionsPopover: e.currentTarget,
      selectedConversationForPopover: value,
    });
  };

  handleMessagePopoverReply = (e:any) => {
    this.changeTopNavigation(this.state.chatType);
    e.stopPropagation();
    this.setState({isCenterSideVisibleForMobileView:false, isRightSideVisibleForMobileView: true, messageOptionsPopover: null, selectedConversation: this.state.selectedConversationForPopover });
  }

  handleMessagePopoverClose = (e: any) => {
    e.stopPropagation()
    this.setState({ messageOptionsPopover: null, selectedConversationForPopover: null });
  };

  handleAddNewContactResponse(responseJson: any) {
    if (responseJson.data) {
      this.setState({
        newContactModal: false,
        errorAlertMessage: false,
        showAlert: true,
        showSuccess: "Contact Added Successfully! ",
        selectedContact: responseJson.data,
        isNewMessage: true,
        selectedConversation: responseJson.data
      });
    } else {
      this.setState({
        showAlert: true,
        errorAlertMessage: true,
        showSuccess: "Something went wrong!",
      });
    }
    setTimeout(() => this.setState({ showAlert: false }), 3000);
    this.getUserChats();
  }

  handleGetContactResponse(responseJson: any) {
    this.setState({ userContacts: responseJson.data, showContcatCard: true });
  }

  handleAutomationItemClick(item: any) {
    this.setState({
      newMessage: this.state.newMessage + item.type,
      automationModal: null,
    });
  }

  groupAndSortByDateChat = (contacts: any[]) => {
    const groupedContacts: Record<string, any[]> = { pinned: [] };
  
    contacts.forEach(contact => {
      const date = contact.attributes.last_interaction_date
        ? contact.attributes.last_interaction_date.split("T")[0]
        : "2012-12-12";
  
      if (contact.attributes.pin_conversation) {
        groupedContacts.pinned.push(contact);
      } else {
        if (!groupedContacts[date]) groupedContacts[date] = [];
        groupedContacts[date].push(contact);
      }
    });
  
    groupedContacts.pinned.sort((a, b) => new Date(b.attributes.last_interaction_date || "2012-12-12").getTime() - new Date(a.attributes.last_interaction_date || "2012-12-12").getTime());
  
    const sortedGroups = Object.entries(groupedContacts)
      .map(([date, group]) => ({
        date,
        contacts: group.sort((a, b) => new Date(b.attributes.last_interaction_date || "2012-12-12").getTime() - new Date(a.attributes.last_interaction_date || "2012-12-12").getTime()),
      }))
      .sort((a, b) => (b.date === "pinned" ? -1 : new Date(b.date).getTime() - new Date(a.date).getTime()));
  
    return sortedGroups;
  };
  

  handleChatResponse(responseJson: any) {
    const newData = [
      ...responseJson.text_contacts.data,
      ...responseJson?.direct_post_contacts,
    ];
    const sortedContacts = this.groupAndSortByDateChat(newData);

    this.setState({
      conversationNewData: sortedContacts,
      conversationDataMain: newData,
      isLoaderShow: false
    });
  }

  handleClickPopOver = (event: any, item: any) => {
    this.setState({ userItem: item });
    this.setState({ anchorEll: event.currentTarget });
  };

  closeModal() {
    this.setState({ teammatesModal: false });
  }

  closeNewContactModal = () => {
    this.setState({ newContactModal: false });
  };

  openNewContactModal = () => {
    this.changeTopNavigation("New Contact");
    this.setState({ newContactModal: true, createNewContactMobile: true, addNewMessageMobile: false});
  };

  openModal() {
    this.setState({ teammatesModal: true });
  }

  handleClosePopOver() {
    this.setState({ anchorEll: null });
  }

  handleAutomationPopOver = () => {
    this.setState({ automationModal: null });
  };

  getUserDetails = () => {
    const headers = {
      "Content-Type": configJSON.commentsContentType,
      Token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAutomationSearch = (event: any) => {
    const query = event.target.value;
    this.setState({ automationSearchQuery: query });
    if (query.length) {
      const results = automationsData.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      this.setState({
        autoamtionFilterData: results,
      });
    } else {
      this.setState({ autoamtionFilterData: automationsData });
    }
  };

  handleOptionsApiResponse = (res: any) => {
    if (res.message) {
      this.setState({
        showAlert: true,
        showSuccess: res.message,
      });
    } else {
      this.setState({
        showAlert: true,
        showSuccess: "Something went wrong!",
        errorAlertMessage: true,
      });
    }
    this.setState({
      messageOptionsPopover: false,
    });
    this.closeSnackBar();
  };

  handleClosePopover = (message: any) => {
    this.setState({
      messageOptionsPopover: false,
      selectedConversation: message,
    });
  };

  closeSnackBar = () => {
    setTimeout(() => {
      this.setState({
        showAlert: false,
        showSuccess: "",
        errorAlertMessage: false,
      });
    }, 3000);
  };

  handleSearch = (e: any) => {
    const query = e.target.value;
    this.setState({ messageSearchQuery: query });
    if (query.length) {
      const results = this.state.conversationDataMain.filter((item: any) =>
        item.attributes.first_name.toLowerCase().includes(query.toLowerCase())
      );
      this.setState({
        conversationNewData: results,
      });
    } else {
      this.setState({ conversationNewData: this.state.conversationDataMain });
    }
  };

  addChatInteraction(data: {contact: {first_name: string, last_name: string, email: string, full_phone_number: string}}){
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      Token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addNewChatContactsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.initChatEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({full_phone_number: data.contact.full_phone_number})
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  addContacts(values: any) {
    this.setState({createNewContactMobile: false, isCenterSideVisibleForMobileView: true});
    const data = {
      contact: {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        full_phone_number: values.phone,
      },
    };
    if(this.state.chatType === conversationType[2]){
      return this.addChatInteraction(data)
    }
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      Token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addNewContactsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userTextsEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleDeleteFolder() {
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      Token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let url = configJSON.delteFolderEndPoint + this.state.userItem.id;

    this.deleteFolderApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async componentDidMount(): Promise<void> {
    if(window.innerWidth < 950){
      this.topNavigationToDashboard();
    }
    this.getUserChats();
    this.getFolders();
    this.handleIsNewChat()
  }

  componentDidUpdate(prevProps: any, prevState: any){
    if (prevState.chatSelectedContact.length !== this.state.chatSelectedContact.length) {
      this.lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }

  topNavigationToDashboard = (name: string = "All Message") => {
    const msg = new Message(getName(MessageEnum.NavigationMobileData));
    msg.addData(getName(MessageEnum.NavigationMobileData), {backArrowFunction: this.handleRedirect, name: name, icon: ChatNavIcon, dropdownFunction: this.dropdownFunction});
    this.send(msg);
  }

  changeTopNavigation = (name: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMobileData));
    msg.addData(getName(MessageEnum.NavigationMobileData), {backArrowFunction: this.handleBackNavigation, name: name, icon: ChatNavIcon, dropdownFunction: this.dropdownFunction});
    this.send(msg);
  }

  handleBackNavigation = (name: string) => {
    if(name.includes('Text') || name.includes("Chat") || name.includes("All") || name.includes("Direct") || name.includes("New message")){
      if(this.state.isCenterSideVisibleForMobileView || this.state.isRightSideVisibleForMobileView || this.state.addNewMessageMobile){
        this.topNavigationToDashboard(this.state.chatType);
      }
      this.setState({isCenterSideVisibleForMobileView: true,
                     isRightSideVisibleForMobileView: false, 
                     isLeftSideVisibleForMobileview: false, 
                     addNewMessageMobile: false,
                     createNewContactMobile: false});
    } else if(name.includes('New Contact')){
      this.changeTopNavigation("New message");
      this.setState({isCenterSideVisibleForMobileView: false, createNewContactMobile: false, addNewMessageMobile: true});
    }
  }

  dropdownFunction = () => {
    this.setState({
      isLeftSideVisibleForMobileview: !this.state.isLeftSideVisibleForMobileview, 
      isCenterSideVisibleForMobileView: !this.state.isCenterSideVisibleForMobileView,
      isRightSideVisibleForMobileView: false
    })
  }

  handleRedirect = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  showMoreFeature = () => {
    this.setState({isDocCameraAutoVisible: !this.state.isDocCameraAutoVisible})
  }

  handleIsNewChat = () => {
    const params = new URLSearchParams(window.location.search)
    const isNewChat = params.get("new-chat")
    if(isNewChat) {
      this.handleNewMessage()
    }
  }

  assignFolderToTeamate(teammate: any) {
    const data = {
      chat_folder: {
        teammate_id: teammate.id,
      },
    };
    this.updateFolder(data);
  }

  getTeammates() {
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      Token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTeamMatesApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teamMatesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getUserChats() {
    this.setState({isLoaderShow: true});
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      Token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserChatsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userChatsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getFolders() {
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      Token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFoldersApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFoldersEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getContacts(searchTerm: string) {
    this.setState({ selectedContact: {} });
    let url = configJSON.contactsSearchEndPoint + searchTerm;
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      Token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  createUserFolder() {
    const data = {
      chat_folder: {
        name: "Folder " + (this.state.userFolders.length + 1),
      },
    };

    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createFoldersApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createFoldersEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  handleCameraClick = (e: any) => {
    console.log(e.target.files[0], e.target.files[0].name);
    const formData = new FormData();
    formData.append(
      "message[conversation_id]",
      this.state.selectedConversation.type === "ChatContact" ? this.state.selectedConversation.attributes.chat_conversation_id : this.state.selectedConversation.attributes.twilio_conversation_id,
    );
    formData.append("message[body]", e.target.files[0]);

    const headersData = {
      "Content-Type": configJSON.sendNewMessageContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cameraAttachmentsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.selectedConversation.type === "ChatContact" ? configJSON.sendChatMessageEndPoint : configJSON.sendMessageEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  updateFolder(data: any) {
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let url = configJSON.updateFolderEndPoint + this.state.userItem.id;

    this.updateFolderApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  sendNewConversation() {
    const data = {
      contact: {
        first_name: this.state.selectedConversation.attributes.first_name,
        last_name: this.state.selectedConversation.attributes.last_name,
        email: this.state.selectedConversation.attributes.email,
        full_phone_number:
          this.state.selectedConversation.attributes.full_phone_number,
      },
    };

    const headerData = {
      "Content-Type": configJSON.newMessageContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.newConversationApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createConversationEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  sendNewMessage() {
    this.setState({newOrOldUserSelected: false});
    if (this.state.newMessage.length > 0) {
      if (this.state.isNewMessage) {
        this.sendNewConversation();
      } else {
        const data = {
          "message": {
            "conversation_id": this.state.selectedConversation.type === "ChatContact" ? this.state.selectedConversation.attributes.chat_conversation_id : this.state.selectedConversation.attributes.twilio_conversation_id,
            "body": this.state.newMessage,
          },
        };

        const headerData = {
          "Content-Type": configJSON.newMessageContentType,
          token: localStorage.getItem("authToken"),
        };
        let requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.newMessageApiId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headerData)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          this.state.selectedConversation.type === "ChatContact" ? configJSON.sendChatMessageEndPoint : configJSON.sendMessageEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(this.state.selectedConversation.type === "ChatContact" ? data.message : data)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        const newChatObject = {
          body: this.state.newMessage,
          created_at: new Date().toISOString(), 
          account: {data: this.state.selectedConversation}, 
        };

        this.setState({
          chatSelectedContact: [...this.state.chatSelectedContact, newChatObject],
          newMessage: ""
        });

        return requestMessage.messageId;
      }
    }
  }

  handleMessageCategory(chatType: string, chatCircle: any) {
    this.topNavigationToDashboard(chatType);
    this.setState({
      isLeftSideVisibleForMobileview: false, 
      isCenterSideVisibleForMobileView: true,
      isRightSideVisibleForMobileView: false,
      addNewMessageMobile: false,
      createNewContactMobile: false
    })
    this.setState({ chatCircle: chatCircle, chatType: chatType });
    if (chatType === "Text") {
      this.filterMessages("sms_contacts");
    }
    if (chatType === "Chat") {
      this.filterMessages("chat_contacts");
    }
    if (chatType === "Direct Post") {
      this.filterMessages("direct_post_contacts");
    }
    if (chatType === "All Messages") {
      this.getUserChats();
    }
  }

  filterMessages = (chatType: string) => {
    this.setState({ chatTypeAPI: chatType, isLoaderShow: true });
    const body = {
      type: chatType,
    };
    const headerData = {
      "Content-Type": configJSON.commentsContentType,
      Token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filterMessagesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userChatsEndPoint + "/filters"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getConversation(value: any) {
    this.changeTopNavigation(this.state.chatType);
    this.setState({ 
      newOrOldUserSelected: false, 
      isNewMessage: false, 
      selectedConversation: value, 
      isLoading: value !== this.state.selectedConversation,
      isRightSideVisibleForMobileView: true,
      isCenterSideVisibleForMobileView: false,
    });

    const headerData = {
      "Content-Type": configJSON.newMessageContentType,
      token: localStorage.getItem("authToken"),
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getConversationUserId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      value.type === "ChatContact" ? `${configJSON.getChatMessageEndPoint}?conversation_id=${value.attributes.chat_conversation_id}` : `${configJSON.sendMessageEndPoint}?conversation_id=${value.attributes.twilio_conversation_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  closeNewMssage = () => {
    this.setState({isNewMessage:false})
  }

  hanldeUserContact(contact: any) {
    this.setState({ showContcatCard: false, selectedContact: contact, addNewMessageMobile: false });
    this.checkIfContactIsPresent(contact);
  }

  handleAutomationClick(e: any) {
    this.setState({ automationModal: e.currentTarget });
  }

  checkIfContactIsPresent(contact: any) {
    this.setState({tempAddingNewContact: contact});
    let matchedContact = this.state.conversationDataMain.find((item: any) => item.id === contact.attributes.id);
    
    if(contact.attributes.twilio_conversation_id || contact.attributes.chat_conversation_id){
      this.getConversation(contact)
      this.setState({
        selectedConversation: contact,
        isNewMessage: false,
        newOrOldUserSelected: true
      });
    } else if(matchedContact){
      this.getConversation(matchedContact)
      this.setState({
        selectedConversation: matchedContact,
        isNewMessage: false,
        newOrOldUserSelected: true
      });
    } else {
      this.addContacts({
        first_name: contact.attributes.first_name,
        last_name: contact.attributes.last_name,
        email: contact.attributes.email,
        phone: contact.attributes.full_phone_number,
      })
    }
  }

  handleNewMessage() {
    this.changeTopNavigation("New message")
    this.setState({ isNewMessage: true, selectedContact: {}, addNewMessageMobile: true, isCenterSideVisibleForMobileView: false});
  }

  handleFilterSelect(name: string){
    this.setState({selectedFilterName: name, filterAnchorEl: null})
  }
}
// Customizable Area End
