// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Typography, Button } from "@material-ui/core";
import { AfterSignupModalSteps } from "./enums";

const configJSON = require("./config.js");

type Props = {
  classes: any;
  setStep: (step: AfterSignupModalSteps) => void;
};

export const AfterSignupWelcome = ({ classes, setStep }: Props) => {
  const handleNextStep = () => {
    setStep(AfterSignupModalSteps.NUMBER_ACCOUNT);
  };

  return (
    <Box className={classes.container} id="welcomeScreen">
      <Typography className={classes.title}>
        A personal message to you from our founder.
      </Typography>
      <Box className={classes.welcomeContent}>
        <Box className={classes.messageContainer}>
          <Typography style={{ fontWeight: 700 }}>
            {configJSON.welcomeMessageBold}
          </Typography>
          <Typography>{configJSON.welcomeMessageSimple_1}</Typography>
          <Typography>{configJSON.welcomeMessageSimple_2}</Typography>
          <Typography>{configJSON.welcomeMessageSimple_3}</Typography>
          <Typography>{configJSON.welcomeMessageSimple_4}</Typography>
        </Box>
        <Typography style={{ fontWeight: 700 }}>{configJSON.sign}</Typography>
      </Box>

      <Button
        id="goToPolicyButton"
        className={classes.nextButton}
        onClick={handleNextStep}
      >
        Next
      </Button>
    </Box>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "40px",
      color: "#0F172A",
      overflow: "auto",
      [theme.breakpoints.down(768)]: {
        gap: "12px"
      },
    },

    title: {
      fontSize: "24px",
      fontWeight: 700,
      [theme.breakpoints.down(768)]: {
        fontSize: "20px",
      },
    },

    welcomeContent: {
      display: "flex",
      flexDirection: "column",
      flexShrink: 1,
      paddingRight: "23px",
      gap: "20px",
      overflowY: "auto",

      "&::-webkit-scrollbar": {
        width: "6px",
        borderRadius: "39px",
      },

      "&::-webkit-scrollbar-track": {
        visibility: "hidden",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#D9D9D9",
      },
    },

    messageContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",

      "& *": {
        fontSize: "16px",
      },
    },

    nextButton: {
      alignSelf: "flex-end",
      width: "253px",
      height: "56px",
      borderRadius: "8px",
      textTransform: "none",
      backgroundColor: "#FFC629",
      fontWeight: 700,
      fontSize: "16px",
      "&:hover" : {
        backgroundColor: "#0F172A",
        color: "#fff"
      },
      [theme.breakpoints.down(520)]: {
        width: "100%"
      },
    },
  });

export default withStyles(styles)(AfterSignupWelcome);
// Customizable Area End
