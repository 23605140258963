// Customizable Area Start
import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { everybrandLogo, everybrandIcon, womanPicture } from "./assets";
import { Formik } from "formik";
import * as yup from "yup";
import clsx from "clsx";
import StyledCheckbox from "../../../components/src/StyledCheckbox.web";
import LoginController, { Props } from "./LoginController.web";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Email is required."),
  password: yup.string().required("Password is required."),
});

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
    },
    container: {
      flex: 1,
      maxWidth: "440px",
    },
    loginFormCotainer:{
      padding: "40px",
      [theme.breakpoints.down(768)]: {
        padding: "0px 23px"
      },
    },
    logo: {
      marginBottom: theme.spacing(5),
      "& img": {
        width: "225px",
        height: "75px",
      },
    },
    fontWeightBold: {
      fontWeight: 700,
    },
    formControlLabel: {
      display: "block",
      color: "#334155",
      fontFamily: '"Roboto", "Inter", "Arial", sans-serif',
      fontWeight: 700,
      marginBottom: theme.spacing(0.5),
    },
    input: {
      "& .MuiOutlinedInput-root":{
        borderRadius: "8px"
      },
      "& > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        { borderColor: "#FFC629" },
    },
    textButton: {
      padding: 0,
      textTransform: "none",
      fontSize: "14px",
      fontWeight: 700,
      color: "#0F172A",
    },
    loginButton: {
      color: "#fff",
      backgroundColor: "#0F172A",
      fontWeight: 700,
      borderRadius: theme.spacing(1),
      textTransform: "none",
      padding: theme.spacing(2, 0),
      "&:hover": {
        backgroundColor: "#0F172A",
      },
      [theme.breakpoints.down(768)]: {
        backgroundColor: "#FFC629",
        color: "#0F172A",
        "&:hover": {
          backgroundColor: "#FFC629",
          color: "#0F172A"
        },
      },
    },
    fullHeight: {
      height: "100vh",
    },
    sidebar: {
      gap: theme.spacing(5),
      backgroundColor: "#FFC629",
      borderBottomLeftRadius: theme.spacing(8),
      [theme.breakpoints.down(768)]: {
        display: "none"
      },
    },
    heading: {
      fontSize: theme.spacing(4.5),
      fontWeight: 700,
      lineHeight: "43px",
      textAlign: "center",
      padding: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    subHeading: {
      fontSize: theme.spacing(2.25),
      fontWeight: 600,
      lineHeight: "25px",
      color: "#FFFFFF",
      textAlign: "center",
      padding: theme.spacing(1),
    },
    signUpBtnBlock: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      [theme.breakpoints.down(768)]: {
        justifyContent: "center"
      },
    },
    formErrorBlock: {
      padding: theme.spacing(1.5, 2),
      margin: theme.spacing(5, 0),
      borderLeft: "5px solid #DC2626",
      borderRadius: theme.spacing(0.5),
      backgroundColor: "#FEE2E2",
      "& p": {
        fontSize: "12px",
        color: "#DC2626",
      },
    },
    passwordVisibilityIcon: {
      color: "#94A3B8",
    },
    checkboxLabel: {
      fontSize: "14px",
      color: "#0F172A",
    },
    fontSizeNormal: {
      fontSize: "16px",
    },
  });

export class Login extends LoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { showPassword, loginError, rememberMe } = this.state;    

    return (
      <Container maxWidth="xl" className={classes.root}>
        <Grid container className={classes.fullHeight}>
          <Grid
            item
            container
            md={6}
            justifyContent="center"
            alignItems="center"
          >
            <div className={classes.container}>
              <Typography align="center" className={classes.logo}>
                <img src={everybrandLogo} alt="everybrand logo" />
              </Typography>
              <Box className={classes.loginFormCotainer}>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  onSubmit={(values, actions) => {
                    this.doEmailLogIn(values);
                    actions.setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb={5}>
                        <Typography
                          variant="h5"
                          className={classes.fontWeightBold}
                        >
                          Log in
                        </Typography>
                      </Box>
                      {loginError && (
                        <div
                          className={classes.formErrorBlock}
                          data-test-id="form-error-block"
                        >
                          <Typography>{loginError}</Typography>
                        </div>
                      )}
                      <Box mb={2}>
                        <label
                          className={classes.formControlLabel}
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <TextField
                          id="email"
                          name="email"
                          variant="outlined"
                          className={classes.input}
                          placeholder="Your email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          fullWidth
                        />
                      </Box>
                      <Box mb={1}>
                        <label
                          className={classes.formControlLabel}
                          htmlFor="password"
                        >
                          Password
                        </label>
                        <TextField
                          id="password"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          className={classes.input}
                          variant="outlined"
                          placeholder="Your password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.password && Boolean(errors.password)}
                          helperText={touched.password && errors.password}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={this.togglePasswordVisibility}
                                >
                                  {showPassword ? (
                                    <VisibilityOutlinedIcon
                                      className={classes.passwordVisibilityIcon}
                                    />
                                  ) : (
                                    <VisibilityOffOutlinedIcon
                                      className={classes.passwordVisibilityIcon}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <FormControlLabel
                          control={<StyledCheckbox id="rememberMeCheckbox" onClick={this.handleRememberMe} checked={rememberMe} />}
                          label="Remember me"
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                        <Button
                          className={classes.textButton}
                          type="button"
                          data-test-id="forgot-password-button"
                          onClick={() => this.navigate("ForgotPassword")}
                        >
                          Forgot password?
                        </Button>
                      </Box>
                      <Box my={5}>
                        <Button
                          variant="contained"
                          className={classes.loginButton}
                          fullWidth
                          disableElevation
                          type="submit"
                        >
                          Log in
                        </Button>
                      </Box>
                      <div className={classes.signUpBtnBlock}>
                        <Typography component="span">
                          Don't have an account?
                        </Typography>
                        <Button
                          className={clsx(
                            classes.textButton,
                            classes.fontSizeNormal
                          )}
                          type="button"
                          data-test-id="signup-button"
                          onClick={() => this.navigate("EmailAccountRegistration")}
                        >
                          Sign up
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </Box>
            </div>
          </Grid>
          <Grid
            item
            container
            md={6}
            direction="column"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.sidebar}
          >
            <Grid item>
              <Typography align="center">
                <img src={everybrandIcon} alt="everybrand icon" />
              </Typography>
              <Typography variant="h4" className={classes.heading}>
                Unlock your world with <br /> seamless access.
              </Typography>
              <Typography className={classes.subHeading}>
                Unleash your potential with us. <br />
                Together, we achieve greatness.
              </Typography>
            </Grid>
            <Grid item>
              <img src={womanPicture} alt="woman picture" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(Login);
// Customizable Area End
