// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  IconButton,
  withStyles,
  Checkbox,
  styled,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EverybrandLibraryController from "./EverybrandLibraryController.web";
import { getMediafileType } from "../../utilities/src/GetMediafileType";

const styles = (theme: Theme) =>
  createStyles({
  });

const LibraryModalContainer = styled(Box)(({theme}) => ({

  position: "relative",
  display: "flex",
  height: "100%",
  flexBasis: "1171px",
  flexShrink: 0,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "64px 44px 37px",
  gap: "22px",
  borderRadius: "8px 8px 32px 8px",
  background: "#FFF",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    minHeight: "100%",
    padding: "10px",
  },

  "& .closeButton":{
    position: "absolute",
    top: "21px",
    right: "12px",
    [theme.breakpoints.down("sm")]: {
      top: "0px"
    },
  },

  "& .libraryModalTitle": {
    alignSelf: "flex-start",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: "-0.13px",
    [theme.breakpoints.down(950)]: {
      fontSize: "24px",
    },
    [theme.breakpoints.down(650)]: {
      fontSize: "20px"
    },
  },

  "& .filesContainer": {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gridAutoRows: "300px",
    columnGap: "20px",
    rowGap: "20px",
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",

    "&::-webkit-scrollbar": {
      width: "10px",
    },

    "&::-webkit-scrollbar-track": {
      visibility: "hidden",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
    },
  },

  "& .filePreviewContainer": {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    borderRadius: "8px",
    "& > img": {
      objectFit: "cover",
      width: "100%",
      height: "100%",
    },

    "& > video": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },

  "& .filePreviewOverlay": {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  "& .fileCheckbox": {
    position: "absolute",
    top: "13px",
    right: "13px",
  },

  "& .selectedFileNumber": {
    fontSize: "70px",
    fontWeight: 700,
    color: "#FFC629",
  },

  "& .buttonsContainer": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "15px",
  },

  "& .button": {
    width: "132px",
    height: "44px",
    textTransform: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
  },

}))

const filesContainer = styled(Box)(({theme}) => ({
  
}));
export class EverybrandLibrary extends EverybrandLibraryController {
  render() {
    const { classes, goBack } = this.props;
    const { filesFromLibrary, selectedFiles } = this.state;

    return (
      <LibraryModalContainer
        id="everybrandLibraryModal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <IconButton
          id="closeEveryreelModalButton"
          className="closeButton"
          onClick={goBack}
        >
          <CloseIcon />
        </IconButton>
        <Typography className="libraryModalTitle">
          Everybrand Library
        </Typography>
        <Box className="filesContainer">
          {filesFromLibrary.map((file, i) => (
            <Box
              id={`file-${i}`}
              className="filePreviewContainer"
              onClick={() => this.selectFile(i)}
            >
              {getMediafileType(file.attributes.files) === "image" ? (
                <img
                  src={file.attributes.files}
                  alt="file from library"
                  className="filePreview"
                />
              ) : (
                <video controls={false}>
                  <source src={file.attributes.files} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}

              <Box
                className="filePreviewOverlay"
                style={{
                  backgroundColor: "#000",
                  opacity: Boolean(
                    selectedFiles.find(
                      (selectedFile) => selectedFile.id === file.id
                    )
                  )
                    ? 0.5
                    : 0,
                }}
              >
                <Checkbox
                  className="fileCheckbox"
                  checked={Boolean(
                    selectedFiles.find(
                      (selectedFile) => selectedFile.id === file.id
                    )
                  )}
                  disabled={selectedFiles.length >= 10}
                />
                {selectedFiles.includes(file) && (
                  <Typography className="selectedFileNumber">
                    {selectedFiles.indexOf(file) + 1}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        <Box className="buttonsContainer">
          <Button
            id="discardLibraryButton"
            className="button"
            style={{
              backgroundColor: "#000",
              border: "1px solid #FFC629",
              color: "#FFF",
            }}
            onClick={goBack}
          >
            Discard
          </Button>
          <Button
            id="confirmLibraryFilesButton"
            className="button"
            style={{ backgroundColor: "#FFC629", color: "#000" }}
            onClick={this.acceptFiles}
            disabled={!selectedFiles.length}
          >
            Accept
          </Button>
        </Box>
      </LibraryModalContainer>
    );
  }
}

export default withStyles(styles)(EverybrandLibrary);

// Customizable Area End
