import React, { useEffect, useRef, useState } from 'react';
import { Theme, createStyles, makeStyles, styled } from "@material-ui/core/styles";
import { AppBar, Toolbar, Button, Box, Paper, Typography, IconButton, Modal} from '@material-ui/core';
import { NavLink, Link } from "react-router-dom";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import clsx from "clsx";
import { routes } from './constansts';
import { HomeIcon, ContactsIcon, SettingsIcon, addIcon, logoEverybrand,imageVector } from '../../blocks/dashboard/src/assets';
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { CloseIcon } from '../../blocks/settings2/src/assets';

SwiperCore.use([Navigation]);

const navigationMenus = [
  { name: "home", icon: HomeIcon, path: routes.eudashboard },
  { name: "trending",icon: imageVector, path: routes.eutrending },
  { name: "create", icon: addIcon, path: routes.eucreate },
  { name: "profile", icon: ContactsIcon, path: routes.euprofile },
  { name: "notifications", path: routes.eunotification },
];

const ResetModalTitle = styled(Typography)({
  paddingBottom: "21px",
  marginBottom: "38px",
  display: "flex",
  justifyContent: "center",
  position: "relative",
  textAlign: "center",
  color: "#0F172A",
  fontFamily: "Inter",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "32px",
  letterSpacing: "-0.12px",
  '@media(max-width: 500px)': {
    fontSize: "13px !important",
    marginBottom: "10px !important",
  }
});

const CloseImageIcon = styled("img")({
  right: "-71px",
  position: "absolute",
  cursor: "pointer",
  height: "26px",
  bottom: "81px",
  padding: "4px",
  width: "24px",
  borderRadius: "80px",
  justifyContent: "space-between",
  alignItems: "center",
  border: " 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
  background: "#FFF",
  boxShadow:
    " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  "@media (max-width: 480px)": {
    top: "-2rem",
    right: "-2rem",
    height: "16px",
    width: "16px",
  },
  "@media (min-width: 480px) and (max-width: 950px)": {
    right: "0rem",
    top:"0rem"
  },
});

const DeleteModalFooter = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
});
const CancelModalButton = styled("button")({
  padding: "16px 0",
  backgroundColor: "#ffca29",
  textTransform: "capitalize",
  color: "black",
  display: "block",
  border: "none",
  borderRadius: "8px",
  width: "100%",
  fontSize: "16px",
  fontWeight: 700,
  cursor: "pointer",
  height: "56px",
  "@media (max-width: 480px)": {
    height: "40px",
    padding: "0px"
  },
});

const DeleteModalButton = styled("button")({
  backgroundColor: "black",
  borderRadius: "8px",
  padding: "16px 0",
  textTransform: "capitalize",
  width: "100%",
  color: "white",
  border: "none",
  fontSize: "16px",
  fontWeight: 700,
  cursor: "pointer",
  height: "56px",
  "@media (max-width: 480px)": {
    height: "40px",
    padding: "0px"
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      // backgroundColor: theme.palette.background.paper,
      zIndex: 20,
      backgroundColor: "#FFFFFF",
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down(768)]: {
        boxShadow: "none"
      },
    },

    logOutButton: {
      display: "flex",
      justifyContent:"end",
      fontFamily: "Inter",
      fontSize: "13px",
      fontWeight: 400,
      padding:"8px 25px 8px 10px",
      marginLeft: "100px",
      color: "#0F172A",
      "@media (max-width: 370px)": {
        padding: "8px 0px",
      },
      "@media (min-width: 960px)and (max-width: 1025px)": {
        flexDirection: "row-reverse", 
      },
      "@media (max-width: 1024px)": {
        padding: "8px 25px",
        marginLeft: "0px !important"
      },
    },
    settingsIcon: {
      color: "#0F172A",
      width: "24px",
      height: "24px",
      transform: "rotate(180deg)",
    },
    textCapitalize: {
      textTransform: "capitalize",
    },
    toolbar: {
      display: "flex",
      position: "relative",
      width: "100%",
      justifyContent: "end",
      alignItems: "center",
      padding: "0 2px", 
      overflow: "hidden",
      "@media (max-width: 1025px)": {
        flexDirection: "row-reverse !important",
      },
    },

    logoutContainer :{
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "end",
    },
    
    navigationContainer: {
      display: "flex",
      gap: "30px",
      flexGrow: 1, 
      justifyContent: "center", 
      '@media (max-width: 1200px)': {
        gap: 12
      },
      '@media (max-width: 960px)': {
        display: 'none' 
      },
    },
    
    navButton: {
      display: "flex", 
      alignItems: "center",
      fontWeight: 700,
      padding: "10px",
      fontSize: "16px",
      fontFamily: "Inter",
      color: "#000000",
    },
    

    logo: {
      position: "absolute",
      top: "3px",
      left: "15px",
      height: 55,
      '@media (max-width: 960px)': {
        left: '50%',
        transform: 'translateX(-50%)'
      },
    },
    active: {
      "& .MuiButton-label": {
        borderBottom: '3px solid  #FFC629'
      },

    },
    activeUsersContainer: {
      maxWidth: "1244px",
      position: "relative",
      padding: "0px 10px",
      background: "#F1F5F9",
      margin: "10px auto",
      [theme.breakpoints.down(1244)]: {
        margin: "10px 24px"
      },
      [theme.breakpoints.down(768)]: {
        padding: "0 0 0 12px",
        margin: "0px",
        background: "#fff",
      },
    },
    shadowBox:{
      position: "absolute",
      zIndex: 10,
      top: "0",
      right: "0",
      width: "80px",
      height: "100%",
      background: "linear-gradient(to left, #D9D9D9, #D9D9D900)",
      [theme.breakpoints.up(768)]: {
        display: "none"
      },
    },
    heading: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
    },
    brandsUlContainer: {
      display: "flex",
      listStyle: "none",
      alignItems: "center",
      margin: "0px",
      padding: "10px 10px",
    },
    swiper:{
      margin: '0 44px',
      [theme.breakpoints.down(768)]: {
        margin: "0px"
      },
    },
    swiperSlide:{
      width: "120px",
      [theme.breakpoints.down(768)]: {
        width: "80px"
      },
    },
    brandsLiContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      justifyContent: 'space-between',
      alignItems: "center",
      margin: "15px",
      width: "120px",
      [theme.breakpoints.down(768)]: {
        width: "80px"
      },
    },
    brandsImageContainer: {
      width: "84px",
      height: "84px",
      background: "#FFC629",
      border: "2px solid #FFC629",
      borderRadius: "50%",
      [theme.breakpoints.down(768)]: {
        width: "52px",
        height: "52px",
      },
    },
    name:{
      fontWeight: 600,
      fontSize: '12px',
      textAlign: 'center',
      marginTop: '5px',
      [theme.breakpoints.down(768)]: {
        fontSize: "10px"
      },
    },
    arrowIconContainer: {
      width: "24px",
      textAlign: "center",
      borderRadius: "50%",
      padding: "5px",
      border: "1px solid #000",
      marginBottom: "15px",
    },
    swiperContainer: {
      position: "relative",
      "& .swiper-wrapper": {
        height: "auto"
      },
    },

    EndWaitlistmodalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "85px 106px 31px 72px",
      width: "100%",
      maxWidth: "636px",
      borderRadius: "0px 0px 20px 0",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
      [theme.breakpoints.down(950)]: {
        padding: "24px"
      },
      [theme.breakpoints.down(500)]: {
        width: "58% !important",
        padding: "40px 40px",
      },
    },
    swiperButton: {
      position: "absolute",
      top: "32%",
      transform: "translateY(-50%)",
      zIndex: 10,
      borderRadius: "50%",
      width: "24px",
      height: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      [theme.breakpoints.down(768)]: {
        display: "none"
      },
      "& .swiper-button-next ": {
        right: '-10px',
        border: "2px solid",
        padding: "18px",
      },
      "& .swiper-button-next::after": {
        fontSize:"18px",
        fontWeight: "600"
      },
      "& .swiper-button-prev": {
         left : "-12px !important",
         border: "2px solid",
         top: '20px !important' ,
         padding: "18px !important",

      },
      "& .swiper-button-prev::after": {
        fontSize:"18px",
      fontWeight: "600"
      },
      "& .swiper-button-prev,swiper-button-next": {
       position: 'absolute',
       top: '50%',
       transform: "translateY(-50%)",
       zIndex: '10',
       borderRadius:  '50%',
       padding: '10px',
      },
      '@media (max-width: 320px)':{
          "& .swiper-button-prev": {
        marginLeft: '-12px'

      },
      },
      '@media (max-width: 768px)': {
         "& .swiper-button-prev,swiper-button-next": {
       width: "30px",
       height: "30px",
       padding: "5px",
       top: '50%'
      },
      "& .swiper-button-prev": {
         left : "-10px",
      },
      "& .swiper-button-next": {
        right: '-15px',
      },
      }
    },
  })
);

interface BrandAttributes {
  activated: boolean;
  country_code: string | null;
  email: string;
  full_name: string | null;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string | null;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  pin: number;
  sign_up_step: number;
  business_website: string;
  zip_code: string;
  social_phone_number: string;
  is_first_login: boolean;
  profile_pic: {
    url : string
  } | null;
  cover_photo: {
    url : string
  } | null;
  follows: {
      followers: string;
      followings: string;
  };
  subscription_paused: boolean;
}
interface Brand {
  id: string;
  type: string;
  attributes: BrandAttributes;
}

interface BrandsIFollowProps {
	brands?: Brand[] | null
}

const NavigationMenuEu: React.FC<BrandsIFollowProps> = ({brands = null}) => {
  const classes = useStyles();
  const [open,setOpen] = useState(false);

  const  handleLogout = () => {
      localStorage.removeItem("EndUserAuthToken"); 
      localStorage.removeItem("EUAuthToken");  
      window.location.href = "/EndUserSignIn";
    };

    const handleModelOpen = () => {
      setOpen(true);
    }

    const handleModelClose = () => {
      setOpen(false);
    }

  return (
    <>
<AppBar position="sticky" className={classes.appBar} style={{ marginBottom: brands?.length === 0 ? "50px" : "auto" }}>
        <Toolbar className={classes.toolbar}>
          <img src={logoEverybrand} alt="Everybrand logo" className={classes.logo} />
          <div className={classes.logoutContainer}>
          <Button
          startIcon={
            <ExitToAppOutlinedIcon className={classes.settingsIcon} />
          }
          className={clsx(classes.logOutButton, classes.textCapitalize)}
          onClick={handleModelOpen}
          data-test-id="logOutBtn"
        >
          Log out
        </Button>
          <div className={classes.navigationContainer}>
            {navigationMenus && navigationMenus.length > 0
              ? navigationMenus.map((menu) => (
          <NavLink key={menu.name} to={menu.path} activeClassName={classes.active}>
            <Button className={classes.navButton}>
              {menu.name === "notifications" ? 
                <NotificationsOutlinedIcon style={{ marginRight: "10px" }} /> : 
                <img src={menu.icon} alt="" style={{ marginRight: "10px" }} />
                    }
                    {menu.name}
                  </Button>
                </NavLink>
              ))
              : null}
          </div>
          </div>   
   <Modal
               open={open}
               onClose={handleModelClose}
               aria-labelledby="simple-modal-title"
               aria-describedby="simple-modal-description"
               data-test-id="templateEditModal"
             >
               <Box className={classes.EndWaitlistmodalBox}>
                 <div>
                   <ResetModalTitle>
                     Are you sure you want to log out?
                     <CloseImageIcon
                       data-test-id="closeEndModal"
                       src={CloseIcon}
                       alt=""
                       onClick={handleModelClose}
                     />
                   </ResetModalTitle>
                 </div>
                 <DeleteModalFooter>
                   <DeleteModalButton
                     data-test-id="resetButtonCall"
                     type="button"
                     onClick={handleModelClose}
                   >
                     Cancel
                   </DeleteModalButton>
                   <CancelModalButton onClick={handleLogout}>
                     Confirm
                   </CancelModalButton>
                 </DeleteModalFooter>
               </Box>
             </Modal>
        </Toolbar>
      </AppBar>
      {(brands && brands?.length > 0) &&  <Paper square elevation={0} className={classes.activeUsersContainer}>
      <Typography   style={{ textAlign: "left", fontSize: "14px" }} gutterBottom variant="subtitle2" className={classes.heading}>
        Brands I Follow
      </Typography>
      <Box className={classes.swiperContainer}  style={{ display: "flex", alignItems: "center" }}>
        <div className={classes.swiperButton} style={{ left: "0px", zIndex: 10 }}>
          <IconButton  className={`swiper-button-prev`} id="swiper-button-prev"></IconButton>
        </div>
        <Swiper
          className={classes.swiper}
          spaceBetween={8}
          slidesPerView={'auto'}
          navigation={{
            prevEl: '#swiper-button-prev',
            nextEl: '#swiper-button-next',
          }}>
          {brands &&
            brands.map((brand, index) => (
              <SwiperSlide key={index} className={classes.swiperSlide}>
                <Box className={classes.brandsLiContainer}>
                  <Link to={`/profile/${brand.id}`}>
                    <Box className={classes.brandsImageContainer}>
                      <img
                        src={
                          brand.attributes.profile_pic?.url ||
                          `https://avatar.iran.liara.run/public/${index + 1}`
                        }
                        alt={brand.attributes.first_name || ""}
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  </Link>
                  <Typography className={classes.name}>
                    {`${brand.attributes.first_name} ${brand.attributes.last_name}`}
                  </Typography>
                </Box>
              </SwiperSlide>
            ))}
        </Swiper>

        <div className={classes.swiperButton}  style={{ right: "0px", zIndex: 10,}}>
          <IconButton id="swiper-button-next" className={`swiper-button-next`}></IconButton>
        </div>
      </Box>
      <Box className={classes.shadowBox} />
    </Paper>}
    </>
  );
};

export default NavigationMenuEu;