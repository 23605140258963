import React from 'react';
import NavigationMenuEUWeb from '../../../components/src/NavigationMenuEU.web';
import { Box, Button, Dialog, Grid, IconButton, Snackbar, styled, Typography } from '@material-ui/core';
import SuggestedBrands from '../../../components/src/SuggestedBrands';
import NotificationsController from './NotificationsController';
import MobileNavEndUser from '../../dashboard/src/MobileNavEndUser.web';
import { likeIcon, commentIcon, selectedCommentIcon, selectedLikeIcon, pinIcon, priorityIcon } from './assets';
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import { CheckOutlined, MoreVertRounded } from "@material-ui/icons";
import { IconAvtar } from "../../contactlist/src/assets";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import MuiAlert from "@material-ui/lab/Alert";
import TopActiveUsersEU from '../../dashboard/src/TopActiveUsersEu.web';
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import { logoEverybrand, nextSliderArrow } from "../../dashboard/src/assets";
import NotificationOptionPopover from './components/NotificationOptionPopover';

export default class EuNotifications extends NotificationsController {

  renderFilterOptoins = () => {
    return <FilterOptions>
      {this.state.EuFilters.map((notify: any) => {
        return <>
            <NotifyButton style={{...(this.state.selectedFilterNameEU === notify.name && {background: "#000", color: "#fff"})}}
              data-test-id="notificationcall"
              onClick={() => this.EUnotificationApiCall(notify.block, notify.name)}>
              {notify.name === "Like" && (
                <img
                  src={ this.state.selectedFilterNameEU === "Like" ? selectedLikeIcon : likeIcon}
                  alt="priorityIcon"
                  style={{
                    width: "21.23px",
                    height: "18px",
                  }}
                />
              )}
              {notify.name === "Comments" && (
                <img
                  src={ this.state.selectedFilterNameEU === "Comments" ? selectedCommentIcon : commentIcon }
                  alt="priorityIcon"
                  style={{
                    width: "21.23px",
                    height: "18px",
                  }}
                />
              )}
              {notify.name === "Followers" && (
                <NotificationsOutlinedIcon />
              )}
              {notify.name === "All" && (
                <StarBorderOutlinedIcon data-test-id="allFilter" />
              )}
              <Typography>
                {notify.name}
              </Typography>
            </NotifyButton>
          </>
        })}
        <MarkReadButton data-test-id="markasreadapi" onClick={() => this.EUmarkAllAsReadApiCall()} style={{marginLeft: "auto", textTransform: "capitalize"}}>
          <Typography style={{fontWeight: "bold", marginRight: "12px"}}>Mark all as read</Typography>
          <CheckOutlined />
        </MarkReadButton>
      </FilterOptions>
  }

  render() {
    const { navigation } = this.props

    return (
      <div style={webStyle.root}>
        <MobileHeader>
          <img src={logoEverybrand} alt="Everybrand logo" />
        </MobileHeader>
        <FollowBrandContainer>
          <NavigationMenuEUWeb brands={this.state.brandsIFollowNotifiacation}/>
        </FollowBrandContainer>
        <Snackbar
          open={this.state.euisShowAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert elevation={6} variant="filled" severity={"success"}>
            {this.state.eushowSuccess}
          </MuiAlert>
        </Snackbar>

        <MainContainer>
          <Box style={webStyle.contentContainer}>
            <NotificationContainer>
              <NotificationSelectHeaderMobile>
                <Box className='blackContainer' onClick={() => {this.setState({isMobileNotificationFilterModalOpen: true})}} data-test-id="notificationTypeSelector">
                  <Typography className='mobileHeaderText'>
                    {this.state.selectedFilterNameEU.includes("All") ? "All Notifictions" : this.state.selectedFilterNameEU}
                  </Typography>
                  <img src={nextSliderArrow} className='openDrawerIcon'/>
                </Box>
                <Button data-test-id="markasreadapi" onClick={() => this.EUmarkAllAsReadApiCall()} style={{textTransform: "capitalize", margin: "12px 12px 0 0"}}>
                  <Typography style={{fontWeight: "bold"}}>Mark all as read</Typography>
                  <CheckOutlined />
                </Button>
              </NotificationSelectHeaderMobile>
              <NotificationSelectHeaderDesktop>
                { this.renderFilterOptoins() }
              </NotificationSelectHeaderDesktop>
              <SelectNotificationTypeDialog open={this.state.isMobileNotificationFilterModalOpen} onClose={() => this.setState({isMobileNotificationFilterModalOpen: false})} data-test-id="notificationModal">
                <Box className="filtersContainerMobile">
                  {this.renderFilterOptoins()}
                </Box>
              </SelectNotificationTypeDialog>
              <Box>
                {this.state.EunotificationData && this.state.EunotificationData.map((e: any, index) => {
                  const { attributes: { pin_top, is_priority }} = e
                  return (
                  <NotificationItemContainer>
                    <Box style={{ display: "flex", gap: "24px" }}>
                      <img
                        src={
                          e.attributes.created_by?.data?.attributes?.profile_pic?.url || IconAvtar
                        }
                        alt=""
                        style={{
                          borderRadius: "70px",
                          border: "1px solid"
                        }}
                        width={"44px"}
                        height={"44px"} />
                      <Box>
                        <Box style={{ display: "flex", gap: "6px" }}>
                          <Typography style={webStyle.notifheading}>
                            {e.attributes.created_by?.data?.attributes?.first_name}{" "}{e.attributes.created_by?.data?.attributes?.last_name}
                          </Typography>
                          <Box>
                            {e.attributes.is_read &&
                              <FiberManualRecordIcon
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "10px",
                                  fontSize: "12px",
                                  margin: "6px 5px 0 0",
                                  color: "#000",
                                  background: "#0F172A",

                                }} />}
                          </Box>
                        </Box>

                        <Typography
                          style={webStyle.notifdescription}>
                          {e.attributes.contents}
                        </Typography>
                      </Box>

                    </Box>
                    <Box className='tagContainer'>
                        {pin_top && (
                          <img
                            src={pinIcon}
                            alt="pinIcon"
                            style={{ height: '18px', width: '18px' }}
                          />
                        )}
                        {is_priority && (
                          <Box className="priorityBox">
                            <img
                              src={priorityIcon}
                              alt="priorityIcon"
                              style={{ height: '22px', width: '22px' }}
                            />
                            <Typography className="priorityTxt">
                              Priority
                            </Typography>
                          </Box>
                        )}
                    </Box>
                    <MoreBox>
                    <IconButton
                      size="small"
                      data-test-id="openPopover"
                      onClick={(event) => this.handleClick(event, e)}
                    >
                      <MoreVertRounded />
                    </IconButton>
                      <Typography style={webStyle.timeText}>
                        {this.timeSince(e.attributes.created_at)}
                      </Typography>
                    </MoreBox>
                  </NotificationItemContainer>)
                })}
              </Box>
            </NotificationContainer>
            <Grid className='gridContainer' item md={4} sm={4} xs={12}>
              <div>
                <TopActiveUsersEU
                  navigation={navigation}
                  top6={this.state.top6}
                />
              </div>
              <div>
                <SuggestedBrands
                  brands={this.state.suggestedBrandsNotifiacation}
                  followBrand={this.followBrandNotificationApi}
                />
              </div>
            </Grid>
          </Box>
        </MainContainer>
        <NotificationOptionPopover 
            deleteNotifications={this.deleteNotifications.bind(this)} 
            handleNotificationReplyClick={this.handleNotificationReplyClick.bind(this)}
            markAsPriorityCall={this.markAsPriorityCall.bind(this)} 
            isGettingCommentDetails={this.state.isGettingCommentDetails} 
            markAsReadByPerticularNotifictionApiCall={this.markAsReadByPerticularNotifictionApiCall.bind(this)} 
            handleClose={this.handleClose.bind(this)} 
            commentType={this.state.commentType} 
            getPriorityText={this.getPriorityText.bind(this)} 
            pinToTopApiCall={this.pinToTopApiCall.bind(this)} 
            notificationId={this.state.notificationId} 
            anchorEl={this.state.anchorEl} 
            getPinUnpinText={this.getPinUnpinText.bind(this)} 
            isNotificationAlreadyRead={this.isNotificationAlreadyRead.bind(this)} 
          />
        <div>
          <MobileNavEndUser navigation={navigation} />
      </div>
      </div>
    );
  }
}

export const MobileHeader = styled(Box)(({theme}) => ({
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.up(768)]: {
    display: "none"
  },
}))

export const NotificationSelectHeaderDesktop = styled(Box)(({theme}) => ({
  borderBottom: "1px solid rgb(226, 232, 240)",
  paddingBottom: "16px",
  [theme.breakpoints.down(950)]: {
    display: "none"
  },
}))

export const MainContainer = styled(Box)(({theme}) => ({
  width: "100vw",
  maxWidth: "1240px",
  margin: "15px auto",
  backgroundColor: "#f1f4f9",
  [theme.breakpoints.down(768)]: {
    margin: "auto"
  },
  "& .gridContainer":{
    [theme.breakpoints.down(768)]: {
      display: "none"
    },
  }
}))

export const NotificationSelectHeaderMobile = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
  [theme.breakpoints.up(950)]: {
    display: "none"
  },
  "& .blackContainer":{
    display: "flex",
    alignSelf: "stretch",
    gap: "18px",
    justifyContent: "flex-end",
    padding: "20px",
    background: "#000",
    color: "#FFF",
    cursor: "pointer",
    "& .mobileHeaderText": {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    "& .openDrawerIcon":{
      filter: "invert(1) brightness(2)",
      transform: "rotate(90deg)",
      width: "16px",
      height: "16px" 
    },
  }
}));

export const MoreBox = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  marginRight: "12px",
  justifyContent: "space-between",
  [theme.breakpoints.down(768)]: {
    flexDirection: "column-reverse",
    marginRight: "0px"
  },
}))

export const SelectNotificationTypeDialog = styled(Dialog)(({theme}) => ({
  "& .MuiDialog-paper": {
      margin: "0px",
      top: "120px",
      left: "-20px",
      borderRadius: "8px",
      padding: "8px",
      [theme.breakpoints.up(768)]: {
        top: "306px",
        left: "-300px"
      },
      [theme.breakpoints.up(850)]: {
        left: "-320px"
      },
    },
    "& .MuiDialog-container": {
      justifyContent: "flex-end",
      alignItems: "start"
    },
    "& .filtersContainerMobile": {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      width: "100%",
      gap: "8px",
      [theme.breakpoints.down(950)]: {
        justifyContent: "start"
      },
    },
}))

export const FilterOptions = styled(Box)(({theme}) => ({
  display:"flex",
  alignItems: "start",
  [theme.breakpoints.down(950)]: {
    display:"flex",
    flexDirection: "column",
    alignItems: "start"
  },
}))

export const NotificationContainer = styled(Box)(({theme}) => ({
  width: "70%",
  backgroundColor: "#FFFFFF",
  overflowy: "auto",
  padding: "32px 17px",
  [theme.breakpoints.down(950)]: {
    padding: "0px",
    width: "60%"
  },
  [theme.breakpoints.down(768)]: {
    width: "100%"
  },
}))

export const NotificationItemContainer = styled(Box)(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 0 32px 8px",
  borderBottom: "1px solid rgb(226, 232, 240)",
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F8FC",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#FFFFFF",
  },
  [theme.breakpoints.down(950)]: {
    padding: "16px"
  },
  [theme.breakpoints.down(768)]: {
    padding: "24px"
  },
  [theme.breakpoints.down(450)]: {
    padding: "24px 8px"
  },
  "& .tagContainer":{
    marginLeft: "auto",
    marginRight: "24px",
    [theme.breakpoints.down(950)]: {
      marginRight: "12px"
    },
    [theme.breakpoints.down(768)]: {
      marginRight: "4px"
    },
  },
  "& .priorityBox":{
      display:"flex",
      alignItems: "center",
      gap: "10px"
  },
  "& .priorityTxt":{
    fontWeight:600,
    fontSize:"14px",
  },
}))

export const NotifyButton = styled(Button)(({theme}) => ({
  height: "32px",
  borderRadius: "26px",
  padding: "6px 12px 6px 8px",
  fontFamily: "Inter",
  fontSize: "14px",
  lineHeight: "22px",
  textTransform: "capitalize",
  backgroundColor: "#F8FAFC",
  margin: "5px",
  "&:hover":{
    background: "#000",
    color: "#fff",
    "& img":{
      filter: "invert(1) brightness(2)",
    }
  },
  [theme.breakpoints.down(950)]: {
    width: "100%",
    borderRadius: "8px",
    justifyContent: "start",
    margin: "4px 0px"
  },
}))

export const MarkReadButton = styled(Button)(({theme}) => ({
  marginLeft: "auto",
  textTransform: "uppercase",
  [theme.breakpoints.down(950)]: {
    display: "none"
  },
}))

export const FollowBrandContainer = styled(Box)(({theme}) => ({
  [theme.breakpoints.down(768)]: {
    display: "none"
  },
}))

const webStyle = {
  root: {
    backgroundColor: "#f1f4f9",
  },
  contentContainer: {
    display: "flex",
    gap: "15px",
    justifyContent: "center"
  },
  sideContainer: {
    width: "30%",
    backgroundColor: "#FFFFFF",
    maxHeight: "700px"
  },
  timeText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#64748B",
  },
  notifheading: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    marginBottom: "4px",
  },
  notifdescription: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  }

}
