// Customizable Area Start
import React from "react";
import { createStyles, Theme } from "@material-ui/core/styles";
import { withStyles, Box, Modal, Dialog, styled } from "@material-ui/core";
import BaseModalController from "./BaseModalController.web";

export class BaseModal extends BaseModalController {
  render() {
    const { classes, children, closeModal } = this.props;

    return (
      <CustomDiloag open className={classes.outerContainer}>
        <Box className={classes.modalContainer}>
          {children}
        </Box>
      </CustomDiloag>
    );
  }
}

const CustomDiloag = styled(Dialog)(({theme}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  zIndex: 99999,
  transform: "translate(-0%, -0%)",
  top: 0,
  left: 0,
  right: 0,
  overflowY: "auto",
  background: "rgba(0, 0, 0, 0.5)",
  
  "& .MuiPaper-root": {
    width: "auto",
    maxWidth: "90%",
    borderBottomRightRadius: "24px",
    [theme.breakpoints.down(650)]: {
      width: "100%",
      maxWidth: "100%",
      borderRadius: "0px"

    },
  },
  [theme.breakpoints.down(650)]: {
    "& .MuiDialog-paper": {
      margin: "0px",
      top: 0
    },
    "& .MuiDialog-scrollPaper": {
      alignItems: "start" 
    },
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: "calc(100% - 58px)",
    }
  }
}))

const styles = (theme: Theme) =>
  createStyles({
    outerContainer: {
      "@media (max-width: 650px)": {
        zIndex: "1201 !important",
      }
    },
    modalContainer: {
      background: "#E2E8F0",
      [theme.breakpoints.down(650)]: {
        width: "100vw"
      }
    }
  });

export default withStyles(styles)(BaseModal);

// Customizable Area End
