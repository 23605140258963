// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBrandDetails } from "./interfaces";
import { getStorageData } from "../../../framework/src/Utilities";

interface Props {
  navigation: any;
  top6: IBrandDetails[];
}

interface S {
  trendingProfiles: IBrandDetails[];
  trendingProfilesBU: IBrandDetails[];
}

interface SS {
  id: any;
}

export default class TopActiveUsersController extends BlockComponent<
  Props,
  S,
  SS
> {
  trendingProfileEU: string = "";
  trendingProfileBUApiCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.ConnectTOSidebar),
      getName(MessageEnum.DashboardRedirection)
    ];

    this.state = {
      trendingProfiles: [],
      trendingProfilesBU: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getTopTrendingProfilesEU();
    this.getTopTrendingProfilesBU();
  }

  async receive(_from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    if(apiRequestCallId === this.trendingProfileEU){
      this.setState({trendingProfiles: responseJson.trending_users.data})
    } else if(apiRequestCallId === this.trendingProfileBUApiCallId){
      this.setState({trendingProfilesBU: responseJson.trending_users.data})
    }
  
  }

  getTopTrendingProfilesEU = async () => {
    const EuToken = await getStorageData("EUAuthToken")
    const header = {
      "Content-Type": "application/json",
      token: EuToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.trendingProfileEU = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_analytics/trending_users_eu");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTopTrendingProfilesBU = async () => {
    const BuToken = await getStorageData("authToken")
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.trendingProfileBUApiCallId = requestMessage.messageId;

    const header = {
      "Content-Type": "application/json",
      token: BuToken
    };
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_analytics/trending_users_bu");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleProfileRedirect = (profileId: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "ProfileView");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), profileId);
    this.send(msg);
  }
}
// Customizable Area End
