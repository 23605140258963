// Customizable Area Start
import { Box, styled, useTheme} from "@material-ui/core";
import React, { CSSProperties } from "react";
import { firststepimg1 ,payment_1,payment_start} from "./assets";

const useStyles = () => {
  const theme = useTheme();

  return {
    titleText: {
      fontWeight: 700,
      fontSize: "36px",
    },

    logoImg: {
      width: "56px",
      height: "56px",
      marginRight: "28px",
    },

    progressBarBlock: {
      marginLeft: "auto",
    },

    progressBarImg: {
      width: "340px",
      height: "72px",
      [theme.breakpoints.down("sm")]: {
        width: "200px",
      },
    },
  };
};

const CustomBox = styled(Box)(({theme}) => ({
  display: "flex",
  alignItems: "center",
  height: "100px",
  backgroundColor: "#FFC629",
  borderRadius: "0px 0px 0px 64px",
  padding: "30px 127px 10px 87px",
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
    padding: "12px",
    height: "auto",
  },
  [theme.breakpoints.down(768)]: {
    display: "none"
  },
}));


const HeaderStepInfo = () => {
  const webStyles = useStyles(); 
  
  return (
    <CustomBox>
      <Box style={webStyles.logoImg}>
        <img src={firststepimg1} alt="" />
      </Box>
      <Box>
       <img src={payment_1} alt=""/>
      </Box>
      <Box style={webStyles.progressBarBlock}>
          <img
            style={webStyles.progressBarImg}
            src={payment_start}
            alt=""
            width="40%"
          />
        {/* )} */}
      </Box>
    </CustomBox>
  );
};

export default HeaderStepInfo;
// Customizable Area End
