import React, { useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/Comment';
import { CommentIconWhite } from '../../blocks/dashboard/src/assets';

interface CommentButtonProps {
  amount?: number;
  Id: string;
  handleClick: (Id: string) => void;
}

const CommentButton: React.FC<CommentButtonProps> = ({ amount = 0, Id, handleClick }) => {
  const [commentCount, setCommentCount] = useState(amount);

  return (
    <div style={styles.button} onClick={() => handleClick(Id)}>
      <IconButton style={{ color: '#FFFFFF', paddingBottom: "6px" }}>
        <img
          data-test-id="commentIconImg"
          src={CommentIconWhite}
          alt="Comment"
          style={styles.commentImg}
        />
      </IconButton>
      <Typography variant="body2" style={{ color: '#FFFFFF', fontFamily: 'Inter' }}>{commentCount}</Typography>
    </div>
  );
};

const styles = {
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  } as React.CSSProperties,

  commentImg: {
    width: "24px",
    height: "24px"
  },
};

export default CommentButton;


