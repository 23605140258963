import React from 'react';
import { Box, CircularProgress, createStyles, ListItemIcon, ListItemText, MenuItem, Popover, styled, Theme, withStyles } from "@material-ui/core";
import { deleteIcon, messageIcon, pinIcon, priorityIcon, resendIcon } from "../assets";

interface Props{
  anchorEl: any
  commentType: string
  notificationId: any
  isGettingCommentDetails: boolean
  handleClose: () => void
  handleNotificationReplyClick: () => void
  isNotificationAlreadyRead: () => boolean
  markAsReadByPerticularNotifictionApiCall: (id: number) => void
  deleteNotifications: (id: number) => void
  pinToTopApiCall: (id: number) => void
  getPinUnpinText: () => string
  markAsPriorityCall: (id: number) => void
  getPriorityText: () => string
}

const NotificationOptionPopover = ({ 
  anchorEl,
  notificationId,
  commentType,
  isGettingCommentDetails,
  handleClose,
  handleNotificationReplyClick,
  isNotificationAlreadyRead,
  markAsReadByPerticularNotifictionApiCall,
  deleteNotifications,
  pinToTopApiCall,
  getPinUnpinText,
  markAsPriorityCall,
  getPriorityText
 }: Props) => {

  const commonFunctionForCondition = (condition: any, param1: any, param2: any) => {
    return condition ? param1 : param2;
  };

  return <CustomPopover
    open={Boolean(anchorEl)}
    id="simple-popover"
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: "center",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "center",
      horizontal: "right",
    }}
    // classes={{
    //   paper: this.props.classes.popOverPaper,
    // }}
  >
    {commentType === "BxBlockComments::Comment" && (
      <StyledMenuItem
        data-test-id="reply"
        disabled={isGettingCommentDetails}
        onClick={handleNotificationReplyClick}>
        <ListItemIcon>
          <img
            src={resendIcon}
            alt="priorityIcon"
            className="mediumImg"
          />
        </ListItemIcon>
        <ListItemText primary="Reply" />
        {commonFunctionForCondition(isGettingCommentDetails, <ListItemIcon>
          <Box className="replyLoader">
            <CircularProgress size={16} />
          </Box>
        </ListItemIcon>, null)}
      </StyledMenuItem>
    )}

    <StyledMenuItem
      data-test-id="MarkAsRead"
      disabled={isNotificationAlreadyRead()}
      onClick={() => markAsReadByPerticularNotifictionApiCall(notificationId.id)}
    >
      <ListItemIcon>
        <img
          src={messageIcon}
          alt="priorityIcon"
          className="mediumImg"
        />
      </ListItemIcon>
      <ListItemText primary="Mark as read" />
    </StyledMenuItem>
    <StyledMenuItem
      data-test-id="deleteNotification"
      onClick={() => deleteNotifications(notificationId.id)}
    >
      <ListItemIcon>
        <img
          src={deleteIcon}
          alt="priorityIcon"
          className="mediumImg"
        />
      </ListItemIcon>
      <ListItemText style={{ color: "red" }} primary="Delete" />
    </StyledMenuItem>
    <StyledMenuItem
      data-test-id="pinToTop"
      onClick={() => pinToTopApiCall(notificationId.id)}
    >
      <ListItemIcon>
        <img
          src={pinIcon}
          alt="priorityIcon"
          className="mediumImg"
        />
      </ListItemIcon>
      <ListItemText primary={getPinUnpinText()} />
    </StyledMenuItem>
    <StyledMenuItem
      data-test-id="markAsPriority"
      onClick={() => markAsPriorityCall(notificationId.id)}
    >
      <ListItemIcon>
        <img
          src={priorityIcon}
          alt="priorityIcon"
          className="priorityIcon"
        />
      </ListItemIcon>
      <ListItemText primary={getPriorityText()} />
    </StyledMenuItem>
  </CustomPopover>
}

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#FFC629",
      borderRadius: "8px",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#0F172A",
      },
    },
  },
}))(MenuItem);

export const CustomPopover = styled(Popover)(({theme}) => ({
  "& .popOverPaper": {
    padding: "4px 4px 2px 4px",
    borderRadius: "8px",
    border: "1px solid #E2E8F0",
    minWidth: "192px",
    background: "#FFF",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  },
  "& .replyLoader": {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    "& svg": {
      color: "#000"
    }
  },
  "& .priorityIcon": {
    height: "30px",
    width: "30px"
  },
  "& .mediumImg": {
    height: "24px",
    width: "24px"
  },
}))

export default NotificationOptionPopover