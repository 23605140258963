// Customizable Area Start
import React, { useEffect, useRef, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {
  HeartSmallIcon,
  uploadIcon,
  sendMedssageIcon,
  redHeart,
  CommentIcon,
  CommentIconWhite,
  logoEverybrand
} from "./assets";
import clsx from "clsx";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Comment, DashboardAccount, PostType } from "./interfaces";
import {
  Box,
  Divider,
  Grid,
  ListItemText,
  MenuItem,
  Modal,
  Popover,
  Snackbar,
  styled,
  TextField,
  withStyles,
  Paper,
  Avatar,
  IconButton,
  Typography,
} from "@material-ui/core";
import { CloseIcon } from "../../settings2/src/assets";
import MuiAlert, { Color } from "@material-ui/lab/Alert";
import { useInView } from "react-intersection-observer";
import Select from "react-dropdown-select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import ReactPlayer from 'react-player'
import { IconAvtar } from "../../contactlist/src/assets";
import { handleOrConditions } from "../../../components/src/CommonFunctions";
import CommentImage from "../../../components/src/CommentImage.web";
import CustomConditionalRender from "../../../components/src/CustomConditionalRender.web";
import CustomLoader from "../../../components/src/CustomLoader.web";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import SwiperCore from 'swiper';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import Poll from "./Poll.web";
import useWindowSize from "../../../components/src/useWindowsHook";
const configJSON = require("./config.js")

enum CommentsOrderEnum {
  ASC = "asc",
  DESC = "desc"
}

interface Props {
  postInfo: PostType;
  hidePostApicall: Function;
  showAlert: boolean;
  showSuccess: string;
  banUserApiCall: Function;
  errorAlertMessage: boolean;
  postCommentApiCall: Function;
  isSortingComments: boolean
  userAccount?: DashboardAccount
  deleteCommentApiCall: Function;
  archiveCommentApiCall: Function;
  revertArchiveCommentApiCall: Function;
  achiveId: number;
  getPostCommentsApiCall: Function;
  handleLikePostAPICall: Function;
  handleSortingCommentsChange: (isSorting: boolean) => void
  handleUndoLikeAPICall: (likeableId: string, likeType: string, postId?: string) => void
  highlighted: boolean;
  handlePostProfileClick: Function;
  handleVote: (optionId : number, postId: string) => void
  handleDeleteVote: (optionId : number, postId: string) => void
}

function Post({
  postInfo,
  archiveCommentApiCall,
  revertArchiveCommentApiCall,
  deleteCommentApiCall,
  hidePostApicall,
  showAlert,
  showSuccess,
  userAccount,
  banUserApiCall,
  errorAlertMessage,
  isSortingComments,
  postCommentApiCall,
  getPostCommentsApiCall,
  handleLikePostAPICall,
  handleUndoLikeAPICall,
  highlighted,
  handlePostProfileClick,
  handleSortingCommentsChange,
  handleVote,
  handleDeleteVote
}: Props) {
  const classes = useStyles();
  const imageInputRef = useRef<HTMLInputElement | null>(null)
  const [commentImage, setCommentImage] = useState<File | null>(null)
  const [toastType, setToastType] = useState<Color | undefined>(undefined)
  const [toastMessage, setToastMessage] = useState<string>("")
  const [anchorEll, setAnchorEll] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [endWaitlistModalS, setendWaitlistModalS] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [postComment, setPostComment] = useState(false);
  const [postPreviewComment, setPostPreviewComment] = useState<boolean>(false);
  const [viewReplies, setViewReplies] = useState(null);
  const [
    hidePostConfirmationModalS,
    sethidePostConfirmationModalS,
  ] = React.useState(false);
  const [selectedReasonS, setSelectedReasonS] = useState("");
  const [comment, setComment] = useState("");
  const [replyingToID, setReplyingToID] = useState<string | null>(null)
  const [replyingToName, setReplyingToName] = useState<string>("")
  const commentRef = useRef<HTMLInputElement>(null);
  const [isEmptyReason, setIsEmptyReason] = useState(false)
  const playerRef = useRef<ReactPlayer>(null);
  const elementRef = useRef<HTMLDivElement>(null)
  const [commentsOrder, setCommentsOrder] = useState<CommentsOrderEnum>(CommentsOrderEnum.ASC)
  const [scrollDirection, setScrollDirection] = useState<string | null>(null);
  const [lastScrollY, setLastScrollY] = useState(0)
  const [ref, inView ] = useInView({
    threshold: 0.1,
  })
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [previewModalId, setPreviewModalId] = useState<string | null>(null)
  const [mediaFiles, setMediaFiles] = useState<{link:string}[] | null>(null);
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const [swiperInstanceHome, setSwiperInstanceHome] = useState<SwiperCore | null>(null);

  const videoRefs = useRef<HTMLVideoElement[]>([]);

  const {wWidth, wHeight} = useWindowSize();

  useEffect(() => {
    videoRefs.current.forEach((video: HTMLVideoElement | null, index: number) => {
      if (video) {
        handlePlayPause(video, index)
      }
    });
  }, [currentSlide]);

  const handlePlayPause = (video: HTMLVideoElement, index: number) => {
    if (index === currentSlide) {
      video.play();
    } else {
      video.pause();
    }
  }

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      setScrollDirection('down');
    } else if (currentScrollY < lastScrollY) {
      setScrollDirection('up');
    }

    setLastScrollY(currentScrollY);
  }

  const isImage = (link: string) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(link);
  };

  const isVideo = (link: string) => {
    return /\.(mp4|webm|ogg)$/i.test(link);
  };

  useEffect(() => {
    if(!inView && postComment) {
      setPostComment(false)
      if(scrollDirection === 'down' && elementRef.current) {
        const elementHeight = elementRef.current.offsetHeight;
        window.scrollTo({
          top: window.scrollY - elementHeight,
        })
      }
    }
  }, [inView, scrollDirection])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY])

  const options = [
    { value: "Fake Profile", label: "Fake Profile" },
    { value: "Mean behavior", label: "Mean behavior" },
    {
      value: "Broke community guidelines",
      label: "Broke community guidelines",
    },
    { value: "Inappropriate content", label: "Inappropriate content" },
    { value: "Fraud or spamming", label: "Fraud or spamming" },
    { value: "Something else", label: "Something else" },
  ];

  const [archivedStatus, setArchivedStatus] = useState<{
    [key: string]: boolean;
  }>(() => {
    const storedArchivedStatus = localStorage.getItem("archivedStatus");
    return storedArchivedStatus ? JSON.parse(storedArchivedStatus) : {};
  });
  const [banUserOfselectedCommentId, setBanUserOfSelectedCommentId] = useState<
    string | null
  >(null); // Store the ID of the selected comment

  const [selectedCommentId, setSelectedCommentId] = useState<string | null>(
    null
  ); // Store the ID of the selected comment
  const [selectedComment, setSelectedComment] = useState<Comment | null>(null)

  const handleArchiveComment = () => {
    if (selectedCommentId !== null) {
      const isArchived = archivedStatus[selectedCommentId]; // Check if the comment is already archived
      setArchivedStatus((prevState) => ({
        ...prevState,
        [selectedCommentId]: !isArchived,
      })); // Toggle the archive status
      // Add your archive/unarchive comment API call here
    }
  };

  const ratings = (data: any) => {
    if (data.length) {
      const rating =
        data
          .map((x: any) => x.rating)
          .reduce((acc: any, curVal: any) => acc + curVal) / data.length;
      return rating;
    } else {
      return 0;
    }
  };

  const handleRevertArchive = () => {
    if (selectedCommentId !== null) {
      setArchivedStatus((prevState) => ({
        ...prevState,
        [selectedCommentId]: false,
      })); // Set archive status to false
      // Add your revert archive API call here
    }
  };


  useEffect(() => {
    localStorage.setItem("archivedStatus", JSON.stringify(archivedStatus));
  }, [archivedStatus]);
  // Function to handle the click on a comment
  const handleCommentClick = (commentId: any) => {
    setBanUserOfSelectedCommentId(commentId);
  };

  const handleSendClick = (
    item: any,
    replyComment: any,
    setViewReplies: any
  ) => {
    const formData = new FormData();

    // Append comment fields
    const reply = handleOrConditions(replyComment, replyComment, "");
    formData.append("comment[commentable_id]", item?.attributes?.id || replyingToID);
    formData.append("comment[comment]", reply);
    formData.append("comment[commentable_type]", "BxBlockComments::Comment");
    commentImage && formData.append("comment[files][]", commentImage);

    postCommentApiCall(formData, postInfo.id);
    setComment("");
    setViewReplies(item && item.id || replyingToID);
    setReplyingToID(null);
    setReplyingToName("");
  };

  const handleReasonChangeS = (selectedReason: any) => {
    if (selectedReason && selectedReason.length > 0) {
      setSelectedReasonS(selectedReason[0].value);
      setIsEmptyReason(false)
    } else {
      setSelectedReasonS("");
    }
  };

  const openHideConfirmationModalS = (event: React.MouseEvent) => {
    event.stopPropagation()
    sethidePostConfirmationModalS(!hidePostConfirmationModalS);
  };

  const handleOpenSnackbar = (type: Color, message: string) => {
    setToastType(type)
    setToastMessage(message)
  }

  const isSelfComment = () => {
    if(selectedComment && userAccount) {
      return String(selectedComment.attributes.account_id) === userAccount.id
    }
    return false
  }

  const commonFunctionForCondition = (
    condition: any,
    param1: any,
    param2: any
  ) => {
    return condition ? param1 : param2;
  };
  const openEndWaitlistModalS = (event: React.MouseEvent) => {
    event.stopPropagation()
    setendWaitlistModalS(!endWaitlistModalS);
  };

  const closeCommentSection = () => {
    !anchorEll && handleCommentClose()
  }

  const handleCommentClose = () => {
    setPostComment(false)
    setPostPreviewComment(false)
  }

  const postCommentFlag = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, postInfo: PostType) => {
    event.stopPropagation()
    if(postInfo.attributes.allow_comment || postInfo.attributes.allow_comment == null) {
      !postComment && getPostCommentsApiCall(postInfo.id);
      setPostComment(!postComment);
    }
  };

  const postPreviewCommentFlag = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, postInfo: PostType) => {
    event.stopPropagation()
    if(postInfo.attributes.allow_comment == null || postInfo.attributes.allow_comment){
      !postPreviewComment && getPostCommentsApiCall(postInfo.id);
      setPostPreviewComment(!postPreviewComment);
    }
  };

  const closeConfirmModalS = () => {
    setConfirmModal(false);
  };

  const openConfirmModalS = () => {
    setConfirmModal(true);
  };

  const handleClickPopOver = (event: any) => {
    event.stopPropagation()
    setAnchorEll(event.currentTarget);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCommentToPost = () => {
    const formData = new FormData();

    // Append comment fields
    formData.append("comment[commentable_id]", postInfo.id);
    formData.append("comment[comment]", comment);
    commentImage && formData.append("comment[files][]", commentImage);
    formData.append(
      "comment[commentable_type]",
      "BxBlockPosts::Post"
    );

    postCommentApiCall(formData);
    setComment("");
    handleImageCommentRemove()
  }

  const handleClosePopOver = () => {
    setAnchorEll(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedComment(null)
  };

  const handleReplyClick = (commentId: string | null, replyingTo: string) => {
    setReplyingToID(commentId);
    setReplyingToName(replyingTo)
    if (commentRef.current) {
      commentRef.current.focus();
    }
  }

  const handleReplyLike = (replyDetails: Comment, commentId?: string) => {
    const { id, attributes: { already_liked } } = replyDetails
    if(already_liked) {
      handleUndoLikeAPICall(id, "Comment", postInfo.id)
    } else {
      handleLikePostAPICall(replyDetails, "Comment", postInfo.id)
    }
  }

  const handleImageCommentClick = () => {
    if(imageInputRef.current) {
      imageInputRef.current.click()
    }
  }

  const handleImageCommentRemove = () => {
    setCommentImage(null)
  }

  const isSelfPost = () => {
    if(postInfo && userAccount) {
      return String(postInfo.attributes.account_id) === userAccount.id
    }
    return false
  }

  const handleCloseSnackbar = () => {
    setToastType(undefined)
    setToastMessage("")
  }

  const checkAndCondition = (condition1: string | boolean, condition2: string | boolean) => {
    return condition1 && condition2
  }

  const handleImageCommentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files: imageFiles } = event.currentTarget;
    if (imageFiles && imageFiles.length > 0) {
      const currentFile = imageFiles[0];
      
      const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      
      if (validImageTypes.includes(currentFile.type)) {
        setCommentImage(currentFile);
      } else {
        handleOpenSnackbar("error", "Invalid file type. Please upload a jpeg, jpg, or png image.")
      }
    }
  }

  const handleCommentOnBlur = () => {
    if (comment === "" && !anchorEl) {
      setReplyingToID(null)
      setReplyingToName("")
    }
  }

  const getValue = (value: number | string | null) => {
    return value || 0
  }

  const ternaryCondition = <T, F>(
    condition: string | boolean | null,
    trueValue: T,
    falseValue: F
  ): T | F => {
    return condition ? trueValue : falseValue;
  }

  const toggleCommentsSorting = () => {
    const updatedOrder =
      commentsOrder === CommentsOrderEnum.ASC
        ? CommentsOrderEnum.DESC
        : CommentsOrderEnum.ASC;
    setCommentsOrder(updatedOrder);
    handleSortingCommentsChange(true);
    getPostCommentsApiCall(postInfo.id, updatedOrder);
  }
  const handlePreviewModalOpen = (files : {link: string}[]) => {
    setPreviewModalId(postInfo.id)
    setMediaFiles(files)
  }

  useEffect(() => {
    setMediaFiles(files)
  }, [])

  const handlePreviewModalClose = () => {
    swiperInstance?.destroy(true, true);
    swiperInstanceHome?.destroy(true, true);
    setSwiperInstance(null);
    setSwiperInstanceHome(null);
    setPreviewModalId(null)
    setMediaFiles(null)
  }

  const getCommentHeaderUI = (item : any) => {
    return (
      <div className={classes.commentscardHeader}>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
          id="commentAvtarBox"
        >
          <Avatar
            className={classes.avatar}
            data-test-id="commentAvtarBox"
          >
            <img
              src={commonFunctionForCondition(
                item.attributes?.account.data?.attributes
                  ?.profile_pic?.url,
                item.attributes?.account.data?.attributes
                  ?.profile_pic?.url,
                IconAvtar
              )}
              className={`${classes.fallbackAvatar} ${
                item.attributes?.archived == null
                  ? classes.cardHeaderContentOfResponsiveContainer
                  : ""
              }`}
            />
          </Avatar>
        </Box>
        <div
          className={`${classes.cardHeaderContent} ${
            item.attributes?.archived == null
              ? classes.cardHeaderContentOfResponsiveContainer
              : ""
          }`}
          id="commentcardHeader"
        >
          <ReceiverMessageContainer id="commentContainer">
            <MessageBubble data-test-id="commentBuble">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="commenterNAme">
                  <Typography
                    className={classes.commnetSectionUser}
                  >
                    {commonFunctionForCondition(
                      item.attributes?.account.data.attributes
                        .first_name !== null,
                      item.attributes?.account.data.attributes
                        .first_name,
                      ""
                    ) +
                      " " +
                      commonFunctionForCondition(
                        item.attributes?.account.data.attributes
                          .last_name !== null,
                        item.attributes?.account.data.attributes
                          .last_name,
                        ""
                      )}
                  </Typography>
                </div>
                <div className="commenterTime">
                  <Typography className={classes.commentTime}>
                    {moment(
                      item.attributes?.created_at
                    ).fromNow()}
                  </Typography>
                </div>
              </div>
              <Typography className={classes.commentFonts}>
                {item.attributes.comment}
              </Typography>
              <CommentImage files={item.attributes.files} />
            </MessageBubble>
          </ReceiverMessageContainer>
        </div>

        <div className={classes.commentCardHeaderAction} data-test-id="postSettingsIcon">
          <IconButton
            size="small"
            onClick={(event: any) => {
              handleClick(event);
              handleCommentClick(item.attributes?.account_id);
              setReplyingToName(
                commonFunctionForCondition(
                  item.attributes?.account.data.attributes
                    .first_name !== null,
                  item.attributes?.account.data.attributes
                    .first_name,
                  ""
                ) +
                  " " +
                  commonFunctionForCondition(
                    item.attributes?.account.data.attributes
                      .last_name !== null,
                    item.attributes?.account.data.attributes
                      .last_name,
                    ""
                  )
              );
              setSelectedCommentId(item.attributes?.id);
              setSelectedComment(item);
            }}
            aria-label="post-settings"
            className={classes.postSettingsBtn}
            data-test-id="threeDotIconPopOver"
          >
            <MoreVertIcon data-test-id="commentMore" />
          </IconButton>
        </div>
      </div>
    )
  }

  const getCommentActionsUI = (item : any) => {
    return (
      <div
        className={`${classes.commentCardActions} ${
          item.attributes?.archived == null
            ? classes.cardHeaderContentOfResponsiveContainer
            : ""
        }`}
        id="cardActions"
      >
        <div
          className={classes.reactionBtn}
          data-test-id="reactionButtons"
        >
          {item.attributes?.already_liked ? (
            <IconButton data-test-id="undo-like-comment" className="icons" aria-label="post-likes" onClick={() => handleUndoLikeAPICall(item.id, "Comment", postInfo.id)}>
              <img src={redHeart} alt="heart" className="likes" />
            </IconButton>
          ) : (
            <IconButton
              onClick={() =>
                handleLikePostAPICall(item, "Comment")
              }
              className="icons"
              aria-label="post-likes"
            >
              <img
                src={HeartSmallIcon}
                alt="heart"
                className="likes"
              />
            </IconButton>
          )}
          <Typography
            variant="body2"
            data-test-id="likesCountss"
            className={classes.likesComments}
            component="span"
          >
            {item.attributes?.likes_count}
          </Typography>
        </div>
        <div
          className={classes.reactionBtn}
          data-test-id="replyOuter"
          onClick={() =>
            handleReplyClick(
              item.id,
              commonFunctionForCondition(
                item.attributes?.account.data.attributes
                  .first_name !== null,
                item.attributes?.account.data.attributes
                  .first_name,
                ""
              ) +
                " " +
                commonFunctionForCondition(
                  item.attributes?.account.data.attributes
                    .last_name !== null,
                  item.attributes?.account.data.attributes
                    .last_name,
                  ""
                )
            )
          }
          id="cardChatButtons"
        >
          <Typography
            variant="body2"
            className={classes.likesComments}
            style={{ cursor: "pointer" }}
            component="span"
            id="chatBubbleCounts"
          >
            Reply
          </Typography>
        </div>
        {handleOrConditions(
          item.attributes?.replies?.data.length,
          <div
            className={classes.reactionBtn}
            data-test-id="openRepliesButton"
            onClick={() => {
              if (viewReplies === item.id) {
                setViewReplies(null);
              } else {
                setViewReplies(item.id);
              }
            }}
          >
            <Typography
              component="span"
              variant="body2"
              className={classes.viewComments}
            >
              View {item.attributes?.replies.data.length}{" "}
              {handleOrConditions(
                item.attributes?.replies.data.length > 1,
                "replies",
                "reply"
              )} 
            </Typography>

            <IconButton size="small" className={clsx(classes.replyExpandIcon, {
              [classes.replyExpandedIcon]: viewReplies === item.id
            })} aria-label="post-likes">
              <ExpandMoreIcon />
            </IconButton>
          </div>,
          null
        )}
      </div>
    )
  }

  const getCommentsListUI = () => {
    return (
      <Box>
        {comments?.data.map((item: any) => {
          const commentDetails = item
          return (
            <div key={item.id} id={`comment-${item.id}`}>
              {getCommentHeaderUI(item)}
              {getCommentActionsUI(item)}

              {viewReplies == item.id &&
                item.attributes?.replies?.data.map(
                  (item: Comment) => {
                    const { attributes, attributes: { account, files } } = item
                    const { data: {
                      attributes: {
                        first_name,
                        last_name,
                      }
                    } } = account

                    return (
                      <>
                        <ReplayToComment className={classes.commentscardHeader}>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Avatar className={classes.avatar}>
                              <img
                                src={commonFunctionForCondition(
                                  item.attributes?.account.data.attributes
                                    ?.profile_pic?.url,
                                  item.attributes?.account.data.attributes
                                    ?.profile_pic?.url,
                                  IconAvtar
                                )}
                                className={classes.fallbackAvatar}
                              />
                            </Avatar>
                          </Box>
                          <div
                            className={`${classes.cardHeaderContent} ${
                              item.attributes?.archived == null
                                ? classes.cardHeaderContentOfResponsiveContainer
                                : ""
                            }`}
                          >
                            <ReceiverMessageContainer>
                              <MessageBubble>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ marginRight: "10px" }}>
                                    <Typography
                                      className={
                                        classes.commnetSectionUser
                                      }
                                    >
                                      {item.attributes?.account.data
                                        .attributes?.first_name +
                                        " " +
                                        item.attributes?.account.data
                                          ?.attributes?.last_name}
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography className="replyToCommentTime" >
                                      {moment(
                                        item.attributes?.created_at
                                      ).fromNow()}
                                    </Typography>
                                  </div>
                                </div>
                                <Typography
                                  className={classes.commentFonts}
                                >
                                  {item.attributes?.comment}
                                </Typography>
                                <CommentImage files={files} />
                              </MessageBubble>
                            </ReceiverMessageContainer>
                          </div>

                          <div
                            className={classes.commentCardHeaderAction}
                            data-test-id="replyCommentDivv"
                          >
                            <IconButton
                              size="small"
                              onClick={(event: any) => {
                                handleClick(event);
                                setSelectedComment(item);
                                handleCommentClick(
                                  item.attributes?.account_id
                                );
                                setReplyingToName(`${first_name} ${last_name}`)
                                setSelectedCommentId(item.id);
                              }}
                              aria-label="post-settings"
                              className={classes.postSettingsBtn}
                              data-test-id="replyCommenthreeDotPopOver"
                            >
                              <MoreVertIcon data-test-id="commentMore" />
                            </IconButton>
                          </div>
                        </ReplayToComment>
                        <div
                          data-test-id="replyCOmmentActions"
                          className={`${classes.commentReplyCardActions} ${
                            item.attributes?.archived == null
                              ? classes.cardHeaderContentOfResponsiveContainer
                              : ""
                          }`}
                        >
                          <div
                            className={classes.reactionBtn}
                            data-test-id="reactonButtonsOfReply"
                          >
                            <IconButton aria-label="post-likes" onClick={() => handleReplyLike(item, commentDetails.id)}>
                              <img src={attributes.already_liked ? redHeart :HeartSmallIcon} />
                            </IconButton>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.likesComments}
                            >
                              {attributes.likes_count}
                            </Typography>
                          </div>
                          <div className={classes.reactionBtn}>
                            <div
                              className={classes.reactionBtn}
                              data-test-id="replyOuter"
                              onClick={() =>
                                handleReplyClick(
                                  commentDetails.id,
                                  `${first_name} ${last_name}`
                                )
                              }
                              id="cardChatButtons"
                            >
                              <Typography
                                variant="body2"
                                className={classes.likesComments}
                                style={{ cursor: "pointer" }}
                                component="span"
                                id="chatBubbleCounts"
                              >
                                Reply
                              </Typography>
                          </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
            </div>
          );
        })}
        </Box>
    )
  }

  const returnPreviewBodyUI = (body : string) => {
    return (
      <PostSummary>
        <Typography className="userName">
          {`${first_name} ${last_name}`}
        </Typography>
        <Typography
          variant="body2"
          className={classes.postDescripation}>
          {body?.length > 45 ? `${body.substring(0, 40)}` : body}{' '}
        </Typography>
          {body?.length > 50 && <Typography
            component="span"
            variant="body2"
            style={{ cursor: "pointer", fontWeight: 'bold' }}
          >
            more...
          </Typography>}
      </PostSummary  >
    )
  }

  const returnProfileLikeCommentBodyUI = () => {
    return <Box className={classes.previewBottomWrapper}>
        {returnPreviewBodyUI(body)}

      <Box style={{marginTop: '15px'}} className={classes.reactionContainer}>
        <Avatar
          className={classes.avatar}
          style={{height: "35px", width: "35px", marginRight: "0px",borderRadius:'40px', marginBottom: '0px'}}
          data-test-id="preview-post-profile-wrapper"
          onClick={(event) => { event.stopPropagation(); handlePostProfileClick(account_id) }}
        >
          <img
            src={account_profile?.url || IconAvtar}
            className={classes.fallbackAvatar}
          />
        </Avatar>
      {is_liked ? (
          <IconButton style={{ color: 'white' }} onClick={(event) => { event.stopPropagation(); handleUndoLikeAPICall(postInfo.id, "Post")}}>
            <FavoriteIcon style={{ color: 'red', fontSize: "30px" }} />
          </IconButton>
        ) : (
          <IconButton
            aria-label="post-likes"
            data-test-id="preview-post-like-box"
            style={{ color: 'white' }}
            onClick={(event) => { event.stopPropagation(); handleLikePostAPICall(postInfo, "Post")}}
          >
            <FavoriteBorderOutlinedIcon style={{fontSize: "30px"}}/>
          </IconButton>
        )}
      <Typography variant="body2" style={{ marginLeft: '0px' }}>
        {getValue(likes_count)}
      </Typography>

      <div
        data-test-id="preview-openCommentButton"
        className={classes.reactionBtn}
        onClick={(event) => {
          postPreviewCommentFlag(event, postInfo);
        }}
      >
        <IconButton aria-label="post-comments">
          <img
            src={CommentIconWhite}
            alt="Comment"
            className={classes.commentIconWhite}
          />
        </IconButton>
      </div>
      <Typography variant="body2" style={{ marginLeft: '0px' }}>
        {getValue(comments_count)}
      </Typography>

      <IconButton
        size="small"
        onClick={handleClickPopOver}
        data-test-id="preview-threeDotIcon"
        aria-label="post-settings"
        className={classes.postSettingsBtn}
      >
        <MoreHorizRoundedIcon style={{ color: 'white', fontSize: "28px" }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEll}
        onClose={handleClosePopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.popOverPaper,
        }}
      >
        <StyledMenuItem
          data-test-id="preview-hidePost"
          onClick={openHideConfirmationModalS}
        >
          <ListItemText primary={configJSON.hidePostText} />
        </StyledMenuItem>
        {!isSelfPost() && <StyledMenuItem
          data-test-id="preview-banUser"
          onClick={openEndWaitlistModalS}
        >
          <ListItemText primary="Ban User" />
        </StyledMenuItem>}
      </Popover>
    </Box>
  </Box>
  }

  const getCommentsUI = () => {
    return (
      <div className={classes.commentSectionContainer}>
        <CustomLoader
              isLoading={isSortingComments}
              wrapperHeight="150px"
              loaderSize={20}
            >
            {(comments && comments.data.length > 0 || poll) && (
              <Box>
                <Divider />
                <Box className={classes.commentCloseIconWrapper}>
                  <IconButton onClick={handleCommentClose}>
                    <CloseRoundedIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Box className={classes.pollCommentsWrapper}>
                {poll && <Poll poll={poll} poll_options={poll_options} accountProfile={account_profile?.url} handleVote={(optionId : number) => handleVote(optionId, postInfo.id)} handleDeleteVote={(optionId : number) => handleDeleteVote(optionId, postInfo.id)}/>}
                {getCommentsListUI()}
                </Box>
                <Popover
                  id={commentId}
                  open={openCommentPopover}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  classes={{
                    paper: classes.popOverPaper,
                  }}
                >
                  <StyledMenuItem
                    data-test-id="reply"
                    onClick={() => {
                      handleClose();
                      handleReplyClick(selectedCommentId, replyingToName);
                    }}
                  >
                    <ListItemText primary="Reply" />
                  </StyledMenuItem>
                  <CustomConditionalRender isRender={isSelfPost()}>
                    <>
                    <StyledMenuItem
                      data-test-id="archive"
                      disabled={
                        selectedCommentId === null ||
                        archivedStatus[selectedCommentId]
                      }
                      onClick={() => {
                        const values = {
                          archives: {
                            archiveable_id: selectedCommentId,
                            archiveable_type: "BxBlockComments::Comment",
                          },
                        };
                        archiveCommentApiCall(values);
                        handleArchiveComment();
                        handleClose();
                      }}
                    >
                      <ListItemText primary="Archive" />
                    </StyledMenuItem>
                    <StyledMenuItem
                      data-test-id="revertArchive"
                      disabled={
                        Boolean(selectedCommentId &&
                        !archivedStatus[selectedCommentId])
                      }
                      onClick={() => {
                        const values = {
                          archives: {
                            archiveable_id: selectedCommentId,
                            archiveable_type: "BxBlockComments::Comment",
                          },
                        };
                        revertArchiveCommentApiCall(values);
                        handleRevertArchive();
                        handleClose();
                      }}
                    >
                      <ListItemText primary="Revert archive" />
                    </StyledMenuItem>
                    </>
                  </CustomConditionalRender>
                  <CustomConditionalRender isRender={isSelfComment()}>
                    <StyledMenuItem
                      data-test-id="delete"
                      onClick={() => {
                        deleteCommentApiCall(selectedCommentId, postInfo.id);
                        handleClose();
                      }}
                    >
                      <ListItemText primary="Delete" />
                    </StyledMenuItem>
                  </CustomConditionalRender>
                  <CustomConditionalRender isRender={!isSelfComment()}>
                    <StyledMenuItem
                      data-test-id="banUserOfComment"
                      onClick={(event) => {
                        openEndWaitlistModalS(event);
                        handleClose();
                      }}
                    >
                      <ListItemText primary="Ban user" />
                    </StyledMenuItem>
                  </CustomConditionalRender>
                </Popover>
              </Box>
            )}
        </CustomLoader>
        <div>
          {commentImage && (
            <Box paddingX={1}>
              <Box className={classes.commentImagePreviewWrapper}>
                <IconButton
                  size="small"
                  data-test-id="image-comment-remove"
                  onClick={handleImageCommentRemove}
                >
                  <CloseRoundedIcon fontSize="small" />
                </IconButton>
                <img
                  src={URL.createObjectURL(commentImage)}
                  alt="Image"
                  data-test-id="comment-image-selected"
                  className={classes.commentImage}
                />
              </Box>
            </Box>
          )}
          <Box
            alignItems="center"
            className={classes.postCommentRoot}
          >
            <Box className={classes.commentInputBox}>
              <Box>
                <TextField
                  data-test-id="commentField"
                  placeholder={ternaryCondition(replyingToID, `Replying to ${replyingToName}`, "Add a Comment")}
                  inputRef={commentRef}
                  onBlur={handleCommentOnBlur}
                  variant="outlined"
                  value={comment}
                  fullWidth
                  multiline
                  maxRows={6}
                  name="comment"
                  onChange={(e) => setComment(e.target.value)}
                  InputProps={{
                    classes: {
                      root: classes.postCommentTextField,
                      input: classes.postCommentinput,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.placeholder,
                    },
                  }}
                />
              </Box>
            </Box>
            <Box className={classes.uploadFileBox}>
              <IconButton
                style={{paddingLeft: "8px"}}
                data-test-id="image-comment-upload"
                aria-label="add emoji"
                onClick={handleImageCommentClick}
              >
                <img
                  src={uploadIcon}
                  alt="uploadIcon"
                />
                <input
                  ref={imageInputRef}
                  accept=".jpeg, .jpg, .png"
                  type="file"
                  hidden
                  data-test-id="image-comment-file"
                  onChange={handleImageCommentUpload}
                />
              </IconButton>
            </Box>
            <Box className={clsx("iconStyle", classes.postCommentBox)}>
              <IconButton
                size="small"
                disabled={Boolean(checkAndCondition(!comment, !commentImage))}
                data-test-id="postCommentApiCall"
                onClick={() => replyingToID ? handleSendClick(
                    false,
                    comment,
                    setViewReplies
                  ) : handleCommentToPost()}
                >
                <img
                  src={sendMedssageIcon}
                  alt="sendIcon"
                />
              </IconButton>
            </Box>
          </Box>
        </div>
      </div>)
  }

  const handleDotClick = (index: number) => {
    swiperInstance?.slideTo(index);
    swiperInstanceHome?.slideTo(index);
    setCurrentSlide(index);
  };
  
  const videoRefMobile = useRef<HTMLVideoElement | null>(null);
  const [progress, setProgress] = useState(0);

  const togglePlayPause = () => {
    if (videoRefMobile.current) {
      if (videoRefMobile.current.paused) {
        videoRefMobile.current.play();
      } else {
        videoRefMobile.current.pause();
      }
    }
  };

  const handleProgressClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (videoRefMobile.current) {
      const progressBar = e.currentTarget;
      const rect = progressBar.getBoundingClientRect();
      const clickPosition = e.clientX - rect.left;
      const newTime = (clickPosition / rect.width) * videoRefMobile.current.duration;
      videoRefMobile.current.currentTime = newTime;
    }
  };

  const handleTimeUpdate = () => {
    if (videoRefMobile.current) {
      const currentTime = videoRefMobile.current.currentTime;
      const duration = videoRefMobile.current.duration;
      setProgress((currentTime / duration) * 100);
    }
  };

  const renderVideoPregressBar = () => {
    return (
        <div className="progressBarOuterContainer">
          <div className="progressBar" onClick={handleProgressClick}>
            <div className="playedPart" style={{width: `${progress}%`}}></div>
            <div className="unplayedPart" style={{left: `calc(${progress}% - 4px)`}}></div>
          </div>
        </div>
    )
  }

  const open = Boolean(anchorEll);
  const id = commonFunctionForCondition(open, "simple-popover", "")

  const openCommentPopover = Boolean(anchorEl);
  const commentId = commonFunctionForCondition(openCommentPopover, "simple-popover", "")
  const {
    attributes: {
      account,
      account_id,
      account_profile,
      body,
      created_at,
      comments,
      comments_count,
      files,
      is_liked,
      likes_count,
      model_name,
      reviews,
      poll,
      poll_options
    }
  } = postInfo
  const {
    first_name,
    last_name,
    followers
  } = account


  return (
    <div ref={ref}>
      <MobileViewPostViewRender>
        <Box className="videoImageContainer">
          <Box className="imageVideoContainer">
            <Swiper
              key={previewModalId ? previewModalId : 0}
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              initialSlide={currentSlide}
              slidesPerView={1}
              spaceBetween={0}
              onSlideChange={(swiper) => {
                setCurrentSlide(swiper.activeIndex);
              }}
              grabCursor={true}
              watchSlidesProgress={true}
              observer={true}
              observeParents={true}
              style={{ width: "100%", overflow: "hidden" }}
            >
              {files?.map((file, index) => (
                <SwiperSlide key={index} style={{ width: "100%" }}>
                  {isImage(file.link) ? (
                    <img
                      src={file.link}
                      alt={`Slide ${index}`}
                      style={{
                        border: 'none',
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        outline: 'none',
                        boxShadow: 'none',
                        background: 'transparent',
                      }}
                    />
                  ) : (
                    <Box data-test-id="videoPreview" className="mediaContainer">
                    <video
                      ref={videoRefMobile}
                      loop
                      controls={false}
                      onClick={(event) => {
                        event.stopPropagation();
                        togglePlayPause()
                      }}
                      onTimeUpdate={handleTimeUpdate}
                      style={{
                        border: 'none',
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        outline: 'none',
                        boxShadow: 'none',
                        background: 'transparent',
                      }}>
                      <source src={file.link} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {renderVideoPregressBar()}
                    </Box>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            <Box className={classes.paginateBox} style={{ cursor: 'pointer' }}>
              {mediaFiles?.map((_, index) => (
                <Box
                  key={index}
                  data-test-id={`swiper-pagination-${index + 1}-mobile`}
                  onClick={() => handleDotClick(index)}
                  className={classes.paginateDots}
                  style={{ background: currentSlide === index ? 'white' : 'gray' }} />
              ))}
            </Box>
          </Box>
          <Box className="postInfoContainer">
            {returnProfileLikeCommentBodyUI()}
            <div className="tradmard">
              <p>Powered by</p>
              <img src={logoEverybrand} alt="" />
            </div>
          </Box>
        </Box>
        <Box className="commentSection">
          {postPreviewComment && getCommentsUI()}
        </Box>
      </MobileViewPostViewRender>

      <DesktopViewPostRender>
        <Paper
          square
          elevation={0}
          data-test-id="post-section"
          className={classes.post}
          onClick={closeCommentSection}
          style={{
            backgroundColor: handleOrConditions(highlighted, "#fef3c7", "none"),
          }}
        >
          <Snackbar
            open={Boolean(toastType)}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MuiAlert onClose={handleCloseSnackbar} severity={toastType}>
              {toastMessage}
            </MuiAlert>
          </Snackbar>
          <Snackbar
            open={showAlert}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity={handleOrConditions(errorAlertMessage, "error", "success")}
            >
              {showSuccess}
            </MuiAlert>
          </Snackbar>
          <Box className={classes.postDataContainer}>
            <div className={classes.cardHeader}>
              <Avatar
                className={classes.avatar}
                data-test-id="post-profile-wrapper"
                onClick={(event) => { event.stopPropagation(); handlePostProfileClick(account_id) }}
              >
                {console.log("RESUlt----->>>", account_id)}
                <img
                  src={account_profile?.url || IconAvtar}
                  className={classes.fallbackAvatar}
                />
              </Avatar>
              <div className={classes.cardHeaderContent}>
                <div className={classes.cardTitleWrapper}>
                  <Typography
                    component="span"
                    data-test-id="post-user-name"
                    className={clsx(classes.fontBold, classes.darkText)}
                  >
                    {`${first_name} ${last_name}`}
                  </Typography>
                  <Typography
                    component="span"
                    className={clsx(classes.fontBold, classes.lightText)}
                  >
                    @everybrand
                  </Typography>
                  {commonFunctionForCondition(
                    created_at,
                    <div>
                      <FiberManualRecordIcon className={classes.bullet} />
                      <Typography
                        component="span"
                        className={clsx(classes.fontBold, (wWidth >= 500 ?  classes.lightText : ""))}
                      >
                        {created_at}
                      </Typography>
                    </div>,
                    null
                  )}
                </div>
                <Typography variant="subtitle2" className={classes.followersText}>
                  {`${getValue(followers)} Followers`}
                </Typography>
              </div>
            </div>
            <div className={classes.cardContent}>
              <Typography className={classes.content}>{body}</Typography>
            </div>
            <div className={classes.cardActions}>
              <div className={classes.reactionBtn}>
                {is_liked ? (
                  <IconButton onClick={(event) => { event.stopPropagation(); handleUndoLikeAPICall(postInfo.id, "Post")}}>
                    <img alt="liked" src={redHeart} />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="post-likes"
                    data-test-id="post-like-box"
                    onClick={(event) => { event.stopPropagation(); handleLikePostAPICall(postInfo, "Post")}}
                  >
                    <img src={HeartSmallIcon} alt="like" />
                  </IconButton>
                )}
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.likesComments}
                >
                  {getValue(likes_count)}
                </Typography>
              </div>
              <div
                data-test-id="openCommentButton"
                className={classes.reactionBtn}
                onClick={(event) => {
                  postCommentFlag(event, postInfo);
                }}
              >
                <IconButton aria-label="post-comments">
                  <img
                    src={CommentIcon}
                    alt="Comment"
                    className={classes.commentIcon}
                  />
                </IconButton>
                <Typography
                  component="span"
                  variant="body2"
                  data-test-id="commentCount"
                  className={classes.likesComments}
                >
                  {getValue(comments_count)}
                </Typography>
              </div>
            </div>
          </Box>
          <Box style={wWidth <= 500 ? { width: "20px" } : { position: "relative", width: "170px", height: "auto" }}>
            <Swiper
              key={previewModalId ? previewModalId : 0}
              onSwiper={(swiper) => setSwiperInstanceHome(swiper)}
              initialSlide={currentSlide}
              slidesPerView={1}
              spaceBetween={10}
              onSlideChange={(swiper) => {
                setCurrentSlide(swiper.activeIndex);
              }}
              style={{ height: '100%' }}
              grabCursor={true}
              watchSlidesProgress={true}
              observer={true}
              observeParents={true}
            >
              {files?.map((file , index) => (
                <SwiperSlide key={index}>
                  {
                    file.link_content_type.includes("video") && (
                      <Box
                        data-test-id="videoPreview"
                        className={classes.postMediaContainer}
                        onClick={() => handlePreviewModalOpen(files)}
                      >
                        <img src={file.gif_link} alt="" />
                      </Box>
                    )}
                  {
                    file.link_content_type.includes("image") && (
                      <Box
                        data-test-id="imagePreview"
                        className={classes.postMediaContainer}
                        onClick={() => handlePreviewModalOpen(files)}
                      >
                        <img src={file?.link} alt="post mediafile preview" />
                      </Box>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            {files?.length > 1 &&  <Box
              className={classes.paginateBox}
              style={{
                cursor: 'pointer',
                bottom: 20,
                paddingLeft: "8px"
              }}
            >
              {files?.map((_, index: number) => (
                <Box
                  data-test-id={`swiper-pagination-home-${index + 1}`}
                  style={{
                    background: currentSlide === index ? 'white' : 'gray',
                    width: "6px",
                    height: "6px"
                  }}
                  onClick={() => handleDotClick(index)}
                  className={classes.paginateDots}
                  key={index}
                />
              ))}
            </Box>}
          </Box>
          <Box className={classes.optionsAndRatings}>
            {ratings(reviews) ? (
              <Box className={classes.ratingsBox}>
                <Rating
                  name="read-only"
                  value={ratings(reviews)}
                  readOnly
                  precision={0.1}
                />
                <Typography className={classes.ratingText}>
                  {ratings(reviews).toFixed(1)}
                </Typography>
              </Box>
            ) : null}
            <div className={classes.cardHeaderAction}>
              <IconButton
                size="small"
                onClick={handleClickPopOver}
                data-test-id="threeDotIcon"
                aria-label="post-settings"
                className={classes.postSettingsBtn}
              >
                <MoreVertIcon />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEll}
                onClose={handleClosePopOver}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                classes={{
                  paper: classes.popOverPaper,
                }}
              >
                <StyledMenuItem
                  data-test-id="hidePost"
                  onClick={openHideConfirmationModalS}
                >
                  <ListItemText primary={configJSON.hidePostText} />
                </StyledMenuItem>
                {!isSelfPost() && <StyledMenuItem
                  data-test-id="banUser"
                  onClick={openEndWaitlistModalS}
                >
                  <ListItemText primary="Ban User" />
                </StyledMenuItem>}
              </Popover>
            </div>
          </Box>
        </Paper>
      </DesktopViewPostRender>


      <Modal
        open={endWaitlistModalS}
        onClose={openEndWaitlistModalS}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test-id="templateEditModal"
      >
        <Box className={classes.EndWaitlistmodalBox}>
          <ResetModalTitle>
            Please select a reason
            <ClosePostConfirmButton
              data-test-id="closeEndModal"
              src={CloseIcon}
              alt=""
              onClick={openEndWaitlistModalS}
            />
          </ResetModalTitle>
          <Typography
            style={{
              fontSize: "16px",
              fontWeight: 700,
              fontFamily: "Inter",
              fontStyle: "normal",
            }}
          >
            Select a reason
          </Typography>

          <Box mb={4}>
            <Select
              options={options}
              data-test-id="selectReason"
              onChange={handleReasonChangeS}
              placeholder="Select a reason"
              dropdownGap={5} // Adds some gap between the dropdown and the selected option
              dropdownPosition="auto" // Positioning of the dropdown
              className={classes.reasonDropDown} // Custom class for styling the dropdown
              contentRenderer={({ props: _props, state }) => {
                const isSelected =
                  Array.isArray(state.values) && state.values.length > 0;

                return (
                  <div
                    className={
                      isSelected
                        ? classes.dropdownFonts
                        : classes.placeholderFonts
                    }
                  >
                    {isSelected ? state.values[0].label : "Select a reason"}
                  </div>
                );
              }}
              values={ternaryCondition(selectedReasonS, [{ value: selectedReasonS, label: selectedReasonS }], [])}
              wrapperClassName=""
              itemRenderer={({ item, itemIndex, props, methods }) => (
                <div
                  key={item.value}
                  className={classes.selectedOption}
                  onClick={() => methods.addItem(item)}
                >
                  {item.label}
                </div>
              )}
            />
            <CustomConditionalRender isRender={isEmptyReason}>
              <Typography className={classes.errorMessage}>
                Please select a reason
              </Typography>
            </CustomConditionalRender>
          </Box>
          <DeleteFooter>
            <DeletePostConfirmButton
              data-test-id="resetButtonCall"
              type="button"
              onClick={openEndWaitlistModalS}
            >
              Cancel
            </DeletePostConfirmButton>
            <CancelPostConfirmButton
              data-test-id="apiCallButton"
              onClick={(event) => {
                if (!selectedReasonS) {
                  setIsEmptyReason(true);
                  return;
                }

                let hideableId = null;

                // Check if either postInfo.attributes?.account_id or banUserOfselectedCommentId exists

                if (banUserOfselectedCommentId) {
                  hideableId = banUserOfselectedCommentId;
                } else {
                  hideableId = account_id;
                }

                const values = {
                  hides: {
                    hideable_id: hideableId,
                    reason: selectedReasonS,
                    hideable_type: "AccountBlock::Account",
                  },
                };

                banUserApiCall(values);
                setTimeout(() => {
                  openEndWaitlistModalS(event);
                  openConfirmModalS();

                  handleClosePopOver();
                  handlePreviewModalClose()
                }, 700);
              }}
            >
              Confirm
            </CancelPostConfirmButton>
          </DeleteFooter>
        </Box>
      </Modal>

      <Modal
        open={hidePostConfirmationModalS}
        onClose={openHideConfirmationModalS}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test-id="templateEditModal"
      >
        <Box className={classes.hidePostConfirmationModal}>
          <div>
            <HidePostModalTitle>
              Are you sure you want to hide post?
              <HideCloseImageIcon
                data-test-id="closeEndModal"
                src={CloseIcon}
                alt=""
                onClick={openHideConfirmationModalS}
              />
            </HidePostModalTitle>
          </div>
          <DeleteHidePostModalFooter>
            <DeleteHidePostModalButton
              data-test-id="resetButtonCall"
              type="button"
              onClick={openHideConfirmationModalS}
            >
              Cancel
            </DeleteHidePostModalButton>
            <CancelHidePostModalButton
              data-test-id="hidePostApiCall"
              onClick={(event) => {
                const values = {
                  hides: {
                    hideable_id: postInfo.id,

                    hideable_type: commonFunctionForCondition(
                      model_name,
                      model_name,
                      ""
                    ),
                  },
                };

                hidePostApicall(values);
                openHideConfirmationModalS(event);
                handleClosePopOver();
                handlePreviewModalClose();
              }}
            >
              Confirm
            </CancelHidePostModalButton>
          </DeleteHidePostModalFooter>
        </Box>
      </Modal>

      <Modal
        open={confirmModal}
        onClose={closeConfirmModalS}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test-id="templateEditModal"
      >
        <Box className={classes.confirmModalBox}>
          <ConfirmResetModalTitle>
            {showSuccess}
            <ClosePostImageIcon
              data-test-id="closeEndModal"
              src={CloseIcon}
              alt=""
              onClick={closeConfirmModalS}
            />
          </ConfirmResetModalTitle>

          <Footer>
            <CancelButton
              data-test-id="doneButton"
              onClick={closeConfirmModalS}
            >
              Done
            </CancelButton>
          </Footer>
        </Box>
      </Modal>

      {postComment && (
        <Paper ref={elementRef} square elevation={0} className={classes.root}>
          {getCommentsUI()}
        </Paper>
      )}

    <Modal open={postInfo.id === previewModalId} onClose={handlePreviewModalClose} className={classes.previewModal}>
      <Box
        className={classes.postViewContainer}
      >
        <IconButton
          data-test-id="previewCloseBtn"
          onClick={handlePreviewModalClose}
          className={classes.previewCloseBtn}
        >
          <CloseRoundedIcon fontSize="small" />
        </IconButton>

        <Swiper
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          onSlideChange={(swiper) => {
            setCurrentSlide(swiper.activeIndex);
          }}
          grabCursor={true}
          style={{ height: '100%' }}
          slidesPerView={1}
          spaceBetween={10}
          initialSlide={currentSlide}
        >
          {mediaFiles?.map((file, index) => (
            <SwiperSlide className="custom-slider" key={index}>
              {isImage(file.link) ? (
                <img
                  src={file.link}
                  alt={`Slide ${index}`}
                  style={{
                    border: 'none',
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    outline: 'none',
                    boxShadow: 'none',
                    background: 'transparent',
                  }}
                />
              ) : (
                <video
                  autoPlay={index === currentSlide}
                  ref={(el) => (videoRefs.current[index] = el!)}
                  loop
                  controls={false}
                  style={{
                    border: 'none',
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    outline: 'none',
                    boxShadow: 'none',
                    background: 'transparent',
                  }}
                >
                  <source src={file.link} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </SwiperSlide>
          ))}
        </Swiper>

        {returnProfileLikeCommentBodyUI()}

        <Box
          className={classes.paginateBox}
          style={{
            cursor: 'pointer',
          }}
        >
          {mediaFiles?.map((_, index) => (
            <Box
              key={index}
              data-test-id={`swiper-pagination-${index+1}`}
              onClick={() => handleDotClick(index)} 
              className={classes.paginateDots}
              style={{
                background: currentSlide === index ? 'white' : 'gray',
              }}
            />
          ))}
        </Box>
        <Modal
          data-test-id="postPreviewCommentModal"
          open={postPreviewComment}
          onClose={() => setPostPreviewComment(!postPreviewComment)}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box className={classes.postPreviewComments}>
            {getCommentsUI()}
          </Box>
        </Modal>
      </Box>
    </Modal>
    </div>
  );
}

export default Post;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    previewModal: {
      background: "rgba(255, 255, 255, 0.2)", 
      backdropFilter: "blur(5px)",
      [theme.breakpoints.down('xs')]: {
        background: "rgba(255, 255, 255, 0)", 
        backdropFilter: "none",
        zIndex: "1 !important",
      }
    },
    postViewContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
      height: '85%',
      maxHeight: "100%",
      maxWidth: "450px",
      color: 'white',
      borderRadius: '40px',
      overflow: 'hidden',
      boxShadow: "24",
      background: "#000",
      [theme.breakpoints.down(1100)]: {
        width: "40%"
      },
      [theme.breakpoints.down(800)]: {
        width: "50%"
      },
    },
    postPreviewComments: {
      position: 'absolute',
      top: '60%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
      maxHeight: "410px",
      height: "100%",
      maxWidth: "606px",
      background: 'white',
      borderRadius: '20px 0px 40px 40px',
      boxShadow: '24',
      padding: "0px 10px",
      [theme.breakpoints.down(800)]: {
        width: "50%"
      },
      [theme.breakpoints.up(1100)]: {
        width: "30%"
      }
    },
    previewBottomWrapper: {
      position: 'absolute',
      bottom: 50,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      padding: '10px 20px',
      zIndex: 10
    },
    postDescripation: {
      marginTop: '5px',
      fontFamily: "inter",
      fontSize: "14px",
      lineHeight: "34px",
      [theme.breakpoints.down('xs')]: {
        fontSize: "14px"
      },
    },
    paginateDots: {
      zIndex: 999,
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      margin: '0 5px',
      cursor: 'pointer',
    },
    paginateBox: {
      position: 'absolute',
      bottom: 161,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 0',
    },
    previewCloseBtn: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      zIndex: 2,
      color: '#8e8e8e',
      background: "#fff",
      padding: "3px",
      cursor: "pointer",
      "&:hover": {
        color: '#9b9b9b',
        background: "#fff",
      },
    },
    previewPostIcons: {
      height: "25px",
      width: "25px",
      fontSize: "26px",
      color: "#fff"
    },
    commentCloseIconWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "10px 0px",
      "& > button": {
        padding: "0px",
        border: "1px solid #E2E8F0"
      }
    },
    pollCommentsWrapper: {
      maxHeight: "300px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "8px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#c1c1c1",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#a8a8a8"
      },
    },
    imageSetWidth:{

    },
    placeholder: {
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 400,
      color: "#64748B",
      border: "1px red"
    },
    ratingText: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      marginLeft: "4px",
    },
    ratingsBox: {
      display: "flex",
      flexDirection: "row",
      margin: "5px",
    },
    optionsAndRatings: {
      marginLeft: "6%",
      display: "flex",
      flexDirection: "row",
    },
    selectedOption: {
      backgroundColor: "#fff",
      color: "#333",
      padding: "8px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#ffca29",
      }
    },
    hoveredOption: {
      backgroundColor: "black",
      color: "white",
    },
    root: {
      padding: theme.spacing(2, 3, 2),
      height: "470px",
    },

    post: {
      display: "flex",
      flexWrap: "nowrap",
      padding: "20px 24px",
    },

    postDataContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },

    postMediaContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#000",
      width: "162px",
      minWidth: "162px",
      height: "162px",
      borderRadius: "10px",
      marginLeft: 8,
      "& > img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "10px"
      },

      "& > video": {
        width: "100%",
        height: "100%",
      },
    },

    cardHeader: {
      display: "flex",
      alignItems: "center",
    },
    commentscardHeader: {
      display: "flex",
    },
    cardHeaderContent: {
      flex: 1,
    },
    cardTitleWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "3px",
      flexWrap: "wrap",
    },
    cardHeaderContentOfResponsiveContainer: {
      flex: 1,
      opacity: 0.5,
      pointerEvents: "none",
    },
    cardHeaderAction: {
      marginTop: theme.spacing(-1),
      marginRight: theme.spacing(-1),
      "& button": {
        transform: "rotate(90deg)",
      },
    },
    cardContent: {
      margin: theme.spacing(2, 0),
      "& p": {
        color: "#000000",
      },
    },
    cardActions: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(3),
      "& button": {
        padding: theme.spacing(0),
      },
    },
    commentCardActions: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(3),
      paddingLeft: "84px",
      marginBottom: "10px",
      "& button": {
        padding: "0px"
      },
      [theme.breakpoints.down(950)]: { 
        paddingLeft: "48px",
      }
    },
    commentReplyCardActions: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      paddingLeft: "150px",
      marginBottom: "10px",
      "& button": {
        padding: "0px"
      }
    },
    bullet: {
      fontSize: "10px",
      color: "#0F172A",
    },
    avatar: {
      width: "44px",
      height: "44px",
      marginRight: theme.spacing(2),
      backgroundColor: "#FFF",
      border: "2px solid #F0E5FF",
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down(950)]: { 
        width: "30px",
        height: "30px",
        marginRight: "8px",
        "& img": {
          height: "30px",
          width: "30px"
        }
      } 
    },
    reactionContainer: { 
      display: 'flex', 
      alignItems: 'center', 
      flexDirection: "column", 
      gap: "3px",
      marginTop: '15px'
    },
    fontBold: {
      color: "#0F172A",
      fontFamily: "Inter, 'sans-serif'",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
    },
    darkText: {
      color: "#0F172A",
    },
    lightText: {
      color: "#64748B",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
    },
    followersText: {
      color: "#64748B",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
    },
    reactionBtn: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    replyExpandIcon: {
      transition: "transform 0.3s"
    },
    replyExpandedIcon: {
      transform: "rotate(180deg)",
    },
    fallbackAvatar: {
      width: "56px",
      height: "56px",
      objectFit: "cover",
    },
    postSettingsBtn: {
      color: "#0F172A",
    },
    content: {
      color: "#000",

      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    commentCardHeaderAction: {
      paddingTop: "6px",
      paddingRight: "42px",
      "& button": {
        transform: "rotate(90deg)",
      },
      [theme.breakpoints.down(1100)]: {
        paddingRight: "8px"
      },
      [theme.breakpoints.down(480)]: { 
        paddingRight: "0px"
      } 
    },
    commentIcon: {
      height: "21px",
      width: "21px",
    },
    commentIconWhite: {
      height: "30px",
      width: "30px",
    },
    likesComments: {
      color: "#64748B",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      [theme.breakpoints.down(950)]: {
        fontSize: "12px"
      }
    },

    viewComments: {
      color: "#64748B",
      cursor: "pointer",
      fontFamily: "Inter",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "22px",
    },
    popOverPaper: {
      padding: "4px 4px 2px 4px",
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      minWidth: "192px",
      background: "#FFF",

      boxShadow:
        "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    EndWaitlistmodalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "22px 22px 22px 22px",
      width: "100%",
      maxWidth: "556px",
      borderRadius: "0px 0px 40px 0",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
      height: "100%",
      maxHeight: "415px",
    },
    confirmModalBox: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "85px 98px 31px 93px",
      width: "100%",
      maxWidth: "556px",
      borderRadius: "0px 0px 40px 0",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
      height: "272px",
    },
    selectField: {
      width: "100%",
      height: "56px",

      border: "1px solid #CBD5E1",

      background: "#FFF",
      color: "#000",
      borderRadius: "8px",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      marginBottom: "41px",
      marginTop: "4px",

      "& .react-dropdown-select-item-selected": {
        backgroundColor: "black",
        color: "white",
      },
    },
    optionFonts: {
      "&:hover": {
        backgroundColor: "black", // Change background color when hovered
      },
    },
    iconButton: {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
    },
    hidePostConfirmationModal: {
      position: "relative",
      backgroundColor: "#fff",
      padding: "85px 106px 31px 72px",
      width: "100%",
      maxWidth: "636px",
      borderRadius: "0px 0px 40px 0",
      top: "50%",
      transform: "translate(-50%, -50%)",
      left: "50%",
    },
    iconStyle:{
      "@media (max-width: 350-450px)": {
       marginLeft: '15px',
       paddingLeft: '10px'
      },
    },
    menu: {
      marginTop: theme.spacing(1),
    },
    placeholderFonts: {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,

      color: "#64748B",
    },
    dropdownFonts: {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,

      color: "#000",
    },
    postCommentRoot: {
      // padding: "13px 0px 27px 0px",
      display: "flex"
    },
    commentInputBox: {
      width: "70%"
    },
    uploadFileBox: {
      width: "15%",
    },
    postCommentBox: {
      width: "15%"
    },
    commentSection: {
      padding: "4px 47px 40px 24px",
    },
    postCommentAvatar: {
      backgroundColor: "#CCC",
      height: theme.spacing(7),
      width: theme.spacing(7),
    },
    postCommentButtton: {
      fontWeight: 700,
      color: "#FFF",
      backgroundColor: "#0F172A",
      textTransform: "capitalize",
      fontFamily: "Inter",
      borderRadius: theme.spacing(2),
      fontStyle: "normal",

      fontSize: "16px",
      "&:hover": {
        backgroundColor: "#0F172A",
      },
    },
    commentImagePreviewWrapper: {
      position: "relative",
      margin: "5px 0px",
      height: "50px",
      width: "50px",
      "& > button": {
        position: "absolute",
        top: "-9px",
        right: "-8px",
        backgroundColor: "#ffca29",
        padding: "1px",
        "&:hover": {
          backgroundColor: "#ffca29"
        },
        "& svg": {
          fontSize: "1.1rem",
          color: "#000"
        }
      }
    },
    commentImage: {
      height: "100%",
      width: "100%",
      borderRadius: "8px",
      marginTop: "3px",
      cursor: "pointer"
    },
    postCommentTextField: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#E2E8F0",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #000"
      },
      borderRadius: "0px 16px 16px 16px",
      backgroundColor: "#F8FAFC",
      borderColor: "#F0F3F8",
    },

    postReplyField: {
      padding: "12px 16px 12px 16px",
      height: "88px",
      borderRadius: "0px 16px 16px 16px",
      background: "#F1F5F9",
    },
    postReplyInput: {
      "&::placeholder": {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        fontStyle: "normal",
        color: "#64748B",
      },
    },
    postCommentinput: {
      "&::placeholder": {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        fontStyle: "normal",
        color: "#64748B",
      },
    },
    postCommentFallbackAvatar: {
      width: "70%",
      height: "70%",
    },
    commnetSectionUser: {
      color: "#0F172A",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      fontFamily: "Inter",
      [theme.breakpoints.down(950)]: { 
        fontSize: "12px" 
      } 
    },
    commentTime: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Inter",
      fontStyle: "normal",
      color: "#64748B",
      marginLeft: "6px",
      [theme.breakpoints.down(950)]: { 
        fontSize: "12px" 
      } 
    },
    commentFonts: {
      color: "#0F172A",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      fontFamily: "Inter",
      wordBreak: "break-word",
      whiteSpace: "pre-line",
      [theme.breakpoints.down(950)]: {
        fontSize: "14px"
      }
    },
    reasonDropDown: {
      height: "56px",
      border: "1px solid #CBD5E1 !important",
      background: "#FFF",
      color: "#000",
      borderRadius: "8px !important",
      marginTop: "8px",
      padding: "2px 12px !important",
      "& .react-dropdown-select-dropdown": {
        borderRadius: "8px"
      }
    },
    errorMessage: {
      fontSize: "15px",
      color: "#FF0000",
      marginTop: "2px"
    },
    commentSectionContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%", 
      maxHeight: "450px",
      [theme.breakpoints.down(950)]: { 
        height: "auto",
        padding: "0 12px"
      } 
    }
  })
);

const MobileViewPostViewRender = styled(Box)({
  "& .videoImageContainer": {
    position: "relative",
    "& .imageVideoContainer": {
      position: "relative",
      width: "100%",
      height: "600px",
      overflow: "hidden",
      "& .mediaContainer": {
        backgroundColor: "#000",
        width: "100%",
        height: "600px",
        "& >img": {
          width: "100%",
          height: "100%",
          objectFit: "fill",
        },
        "& >video": {
          width: "100%",
          height: "100%",
          objectFit: "contain"
        },
      },
      "& .progressBarOuterContainer":{

        position: "absolute",
        bottom: "2px",
        zIndex: 2,
        width: "100%",

        "& .progressBar": {
          position: "relative",
          height: "3px",
          backgroundColor: "#fff",
          borderRadius: "5px",
          cursor: "pointer",
          marginTop: "10px",

          "& .playedPart": {
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            backgroundColor: "#FFC629",
            borderRadius: "5px",
            transition: "width 0.1s linear",
          },

          "& .unplayedPart": {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: "8px",
            height: "8px",
            backgroundColor: "#FFC629",
            borderRadius: "50%",
            transition: "left 0.1s linear",
          }
        },
      },
    },
    "& .postInfoContainer":{
      position: "absolute",
      width: "100%",
      bottom: "-5%",
      zIndex: 1,
      color: "#fff",

      "& .tradmard": {
        position: "absolute",
        bottom: "36px",
        left: "36%",
        display: "flex",
        alignItems: "center",
        "& p":{
          fontSize: "8px",
          fontWeight: "bold",
          color: "#86898D",
        },
        "& img": {
          height: '22px',
          // filter: "brightness(0) invert(1)",
        }
      }
    },
    "& .commentSection":{

    }
  },
  
  "@media (min-width: 650px)": {
    display: "none"
  }
})

const DesktopViewPostRender = styled(Box)({
  "@media (max-width: 650px)": {
    display: "none"
  }
})

const PostSummary = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: "0px",
  color: "#fff",
  "& .userName": {
    fontFamily: "inter",
    fontWeight: 'bold', 
    fontSize: "16px", 
    lineHeight: "24px", 
    color: "#fff"
  }
})

const ReceiverMessageContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  padding: "10px",
  width: "94%",
  marginRight: "auto",
  "@media (max-width: 480px)": {
    padding: "0 0 10px 0"
  },
  "& .commenterTime": {
    fontSize: "12px"
  }
});
const MessageBubble = styled(Paper)({
  padding: "12px 16px 12px 16px",
  borderRadius: "0px 16px 16px 16px",
  background: "#e8effd",
  boxShadow: "none",
  "@media (max-width: 950px)": {
    padding: "12px"
  },
  "& .replyToCommentTime": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Inter",
    fontStyle: "normal",
    color: "#64748B",
    "@media (max-width: 950px)": {
      fontSize: "12px"
    }
  }
});

const ReplayToComment = styled(Box)({
  display: "flex",
  paddingLeft: "67px",
  "@media (max-width: 950px)": {
    paddingLeft: "48px"
  }
})

const HidePostModalTitle = styled(Typography)({
  paddingBottom: "21px",
  fontStyle: "normal",
  justifyContent: "center",
  position: "relative",
  textAlign: "center",
  color: "#0F172A",
  letterSpacing: "-0.12px",
  marginBottom: "38px",
  fontFamily: "Inter",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "32px" /* 133.333% */,
  display: "flex",
});

const DeleteHidePostModalFooter = styled(Box)({
  alignItems: "center",
  gap: "20px",
  justifyContent: "space-between",
  display: "flex",
});
const CancelHidePostModalButton = styled("button")({
  color: "black",
  padding: "16px 0",
  backgroundColor: "#ffca29",
  textTransform: "capitalize",
  display: "block",
  border: "none",
  fontWeight: 700,
  borderRadius: "12px",
  fontSize: "16px",
  cursor: "pointer",
  width: "100%",
});

const DeleteHidePostModalButton = styled("button")({
  border: "none",
  backgroundColor: "black",
  borderRadius: "12px",
  color: "white",
  width: "100%",
  padding: "16px 0",
  textTransform: "capitalize",
  fontSize: "16px",
  fontWeight: 700,
  cursor: "pointer",
});
const HideCloseImageIcon = styled("img")({
  position: "absolute",
  cursor: "pointer",
  right: "-71px",
  height: "26px",
  bottom: "81px",
  padding: "4px",
  width: "24px",
  borderRadius: "80px",
  justifyContent: "space-between",
  alignItems: "center",
  border: " 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
  background: "#FFF",
  boxShadow:
    " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
});
const ResetModalTitle = styled(Typography)({
  fontSize: "20px",
  position: "relative",
  marginBottom: "38px",
  display: "flex",
  justifyContent: "center",
  fontWeight: 700,
  fontFamily: "Inter",
  fontStyle: "normal",
  textAlign: "center",
  marginTop: "41px",
});

const DeleteFooter = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
});
const CancelPostConfirmButton = styled("button")({
  textTransform: "capitalize",
  backgroundColor: "#ffca29",
  padding: "16px 0",
  display: "block",
  width: "100%",
  border: "none",
  borderRadius: "12px",
  fontSize: "16px",
  color: "black",
  fontWeight: 700,
  cursor: "pointer",
});

const DeletePostConfirmButton = styled("button")({
  backgroundColor: "black",
  padding: "16px 0",
  color: "white",
  fontSize: "16px",
  textTransform: "capitalize",
  width: "100%",
  border: "none",
  fontWeight: 700,
  cursor: "pointer",
  borderRadius: "12px",
});
const ClosePostConfirmButton = styled("img")({
  height: "26px",
  width: "24px",
  position: "absolute",
  padding: "4px",
  borderRadius: "80px",
  boxShadow:
    " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  bottom: "56px",
  cursor: "pointer",
  right: "-10px",
  alignItems: "center",
  border: " 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
  justifyContent: "space-between",
  background: "#FFF",
});

const ConfirmResetModalTitle = styled(Typography)({
  fontSize: "20px",
  position: "relative",
  marginBottom: "66px",
  display: "flex",
  justifyContent: "center",
  fontWeight: 700,
  fontStyle: "normal",
  fontFamily: "Inter",
  textAlign: "center",
});
const Footer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  paddingRight: "36px",
  paddingLeft: "62px",
});
const CancelButton = styled("button")({
  backgroundColor: "#ffca29",
  padding: "16px 0",
  color: "black",
  textTransform: "capitalize",
  display: "block",
  width: "100%",
  border: "none",
  borderRadius: "12px",
  fontSize: "16px",
  fontWeight: 700,
  cursor: "pointer",
  maxWidth: "232px",
});

const ClosePostImageIcon = styled("img")({
  position: "absolute",
  bottom: "83px",
  border: " 1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
  cursor: "pointer",
  width: "24px",
  padding: "4px",
  borderRadius: "80px",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#FFF",
  right: "-89px",
  boxShadow:
    " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  height: "26px",
});

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#FFC629",
      borderRadius: "8px",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#0F172A",
      },
    },
  },
}))(MenuItem);
// Customizable Area End
