// Customizable Area Start
import React, { CSSProperties } from "react";

import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import * as Yup from "yup";

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";
import { imgLogoEverybrand } from "./assets";
import { StepType } from "./enums";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFC629",
      contrastText: "#0F172A"
    },
    background: {
      default: "#FFC629"
    }
  }
});

const webStyles: Record<string, CSSProperties> = {
  everybrandLogo: {
    height: "75px",
    marginBottom: "48px"
  },
  paper: {
    backgroundColor: "#FFFFFF",
    width: "440px",
    borderRadius: "0 0 24px 0"
  },
  forgotPasswordPaper: {
    padding: "76px 40px"
  },
  recoveryPasswordPaper: {
    padding: "40px"
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    marginBottom: "8px"
  },
  regularText: {
    marginBottom: "40px"
  },
  label: {
    fontWeight: 700,
    color: "#334155"
  },
  baseButton: {
    color: "#FFFFFF",
    backgroundColor: "#0F172A",
    borderRadius: "8px",
    textTransform: "none",
    height: "52px",
    fontWeight: 700
  },
  submitButton: {
    marginBottom: "40px"
  },
  inputWrapper: {
    marginTop: "4px",
    marginBottom: "40px"
  },
  input: {
    borderRadius: "8px",
    marginBottom: "5px"
  },
  textButton: {
    textTransform: "none"
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  dialogWindow: {
    borderRadius: "8px 8px 32px 8px"
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 0
  },
  closeButton: {
    padding: "12px",
    margin: "8px",
    color: "#334155"
  },
  dialogContentWrapper: {
    padding: "12px 40px 24px 40px"
  },
  dialogContentTitle: {
    fontWeight: 700,
    fontSize: "24px",
    marginBottom: "16px"
  },
  dialogContentRegularText: {
    fontSize: "18px"
  },
  dialogActionsWrapper: {
    borderTop: "1px solid #E2E8F0",
    padding: "24px"
  }
};

const MainContainer = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "20px",
  height: "100%",
  [theme.breakpoints.down(768)]: {
    background: "#fff",
    justifyContent: "start"
  },
  "& .logoImage":{
    height: "75px",
    marginBottom: "48px",
    [theme.breakpoints.down(768)]: {
      margin: "24px 0 0 0",
      height: "60px",
    },
  },
  "& .recoveryButton":{
    color: "#FFFFFF",
    backgroundColor: "#0F172A",
    borderRadius: "8px",
    textTransform: "none",
    height: "52px",
    fontWeight: 700,
    marginBottom: "40px",
    [theme.breakpoints.down(768)]: {
      backgroundColor: "#FFC629",
      color: "#0F172A"
    },
  },
  "& .backToLogin":{
    [theme.breakpoints.down(768)]: {
      display: "flex",
      margin: "auto"
    },
  },
  "& .title":{
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
    marginBottom: "40px",
    [theme.breakpoints.down(768)]: {
      display: "none"
    },
  },
  "& .paper":{
    [theme.breakpoints.down(768)]: {
      textAlign: "center",
      marginTop: "100px"
    },
  },
  "& .buttonsWrapper":{
    [theme.breakpoints.down(768)]: {
      flexDirection: "column",
      gap: "16px"
    },
  }
}))

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <MainContainer style={webStyles.container}>
          <img
            src={imgLogoEverybrand}
            alt={this.labelEverybrand}
            className="logoImage"
          />
          {this.state.stepType === StepType.FORGOT_PASSWORD && (
            <Formik
              data-test-id="forgotForm"
              initialValues={{ email: this.state.email }}
              validationSchema={Yup.object().shape(this.emailSchema)}
              onSubmit={(values, actions) => {
                actions.setSubmitting(false);
                this.handleSubmitForgotPasswordForm();
              }}
            >
              {({ handleChange, handleSubmit, errors }) => (
                <Box
                  style={{
                    ...webStyles.paper,
                    ...webStyles.forgotPasswordPaper
                  }}
                >
                  <Typography style={webStyles.subTitle}>
                    {this.labelForgotPassword}
                  </Typography>
                  <Typography style={webStyles.regularText}>
                    {this.labelNoWorries}
                  </Typography>
                  <Box>
                    <Typography style={webStyles.label}>
                      {this.labelEmail}
                    </Typography>
                    <TextField
                      id="email"
                      data-test-id="email"
                      variant="outlined"
                      placeholder={this.labelEmailPlaceholder}
                      fullWidth
                      style={webStyles.inputWrapper}
                      InputProps={{
                        style: webStyles.input
                      }}
                      error={!!errors.email}
                      helperText={errors.email}
                      onChange={e => {
                        handleChange(e);
                        this.setEmail(e.target.value);
                      }}
                    />
                  </Box>
                  <Button
                    data-test-id="submitForgotForm"
                    fullWidth
                    variant="contained"
                    className="recoveryButton"
                    disableElevation
                    onClick={() => handleSubmit()}
                  >
                    {this.labelSendRecoveryLink}
                  </Button>
                  <Button
                    data-test-id="forgot_goToLogin"
                    style={webStyles.textButton}
                    className="backToLogin"
                    variant="text"
                    onClick={() => this.goToLogin()}
                  >
                    <Typography>
                      {this.labelBackTo} <b>{this.labelLogIn}</b>
                    </Typography>
                  </Button>
                </Box>
              )}
            </Formik>
          )}
          {this.state.stepType === StepType.PASSWORD_RECOVERY && (
            <Box
              data-test-id="passwordRecovery"
              style={{
                ...webStyles.paper,
                ...webStyles.recoveryPasswordPaper
              }}
              className="paper"
            >
              <Typography className="title">
                {this.labelPasswordRecovery}
              </Typography>
              <Typography style={webStyles.subTitle}>
                {this.labelCheckYourEmail}
              </Typography>
              <Typography style={webStyles.regularText}>
                {this.labelResetLink} {this.state.email}
              </Typography>
              <Box style={webStyles.buttonsWrapper} className="buttonsWrapper">
                <Button
                  data-test-id="openResendMessageDialog"
                  style={webStyles.textButton}
                  variant="text"
                  onClick={() => this.openResendMessageDialog()}
                >
                  <Typography>
                    <b>{this.labelResendEmail}</b>
                  </Typography>
                </Button>
                <Button
                  data-test-id="recovery_goToLogin"
                  style={webStyles.textButton}
                  variant="text"
                  onClick={() => this.goToLogin()}
                >
                  <Typography>
                    {this.labelBackTo} <b>{this.labelLogIn}</b>
                  </Typography>
                </Button>
              </Box>
            </Box>
          )}
          <Dialog
            onClose={() => this.closeResendMessageDialog()}
            open={this.state.isOpenResendMessage}
            PaperProps={{ style: webStyles.dialogWindow }}
          >
            <DialogTitle style={webStyles.dialogTitle} disableTypography>
              <IconButton
                style={webStyles.closeButton}
                onClick={() => this.closeResendMessageDialog()}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={webStyles.dialogContentWrapper}>
              <Typography style={webStyles.dialogContentTitle}>
                {this.labelSuccessResend}
              </Typography>
              <Typography style={webStyles.dialogContentRegularText}>
                {this.labelConfirmEmailText}
              </Typography>
            </DialogContent>
            <DialogActions style={webStyles.dialogActionsWrapper}>
              <Button
                style={webStyles.baseButton}
                variant="contained"
                onClick={() => this.handleOpenMail()}
              >
                {this.labelOpenMail}
              </Button>
            </DialogActions>
          </Dialog>
        </MainContainer>
      </ThemeProvider>
    );
  }
}
// Customizable Area End
